export const personFiltersAvailable = [
    {
        id: "name",
        label: "name",
        type: "string",
    },
    {
        id: "description",
        label: "description",
        type: "string",
    },

];