import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";

export async function getDictionaryEntriesByLabel(dictionaryId, label) {

    let url = BACKEND_URL + 'dictionary/' + dictionaryId + '/entries'

    if (label != null && label !== "") {
        url += '/' + label + '/' + 50;
    }


    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    const data = await response.json();

    return data.map((entry) => {
        return {
            label: entry.labels && entry.labels.pl ? entry.labels.pl : entry.id,
            value: entry.labels && entry.labels.pl ? entry.labels.pl : entry.id,
            dictionaryEntry: entry
        }
    });
}

export async function getDictionaryEntries(dictionaryId) {

    const url = BACKEND_URL + 'dictionary/' + dictionaryId + '/entries';

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function createDictionaryEntryREST(dictionaryId, label) {

    const url = `${BACKEND_URL}dictionary-entries/${dictionaryId}/create`;

    const baseRequestConfig = getBaseRequestConfig();

    var request = {
        label: typeof label === 'object' ? label.label : label
    };

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function searchDictionaryEntriesFromREST(request) {

    const url = BACKEND_URL + 'dictionary-entries/search/';

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
