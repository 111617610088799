import React from 'react'
import {connect} from "react-redux";
import {Field, Form} from "react-final-form";
import {runAction} from "../actions/engine.actions";
import FormStateToRedux from "../form/FormStateToRedux";
import {getComponentState} from "../selectors";

const mapStateToProps = (state, ownProps) => {

    let config = null;
    if (ownProps.config) {
        config = ownProps.config;
    }
    else {
        config = state.engine.configsMap[ownProps.configId];
    }

    return getComponentState(state, ownProps.configId) ? {
        config: config,
        data: getComponentState(state, ownProps.configId).data,
    } : {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config) => dispatch(runAction(config))
    }
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
    await sleep(300)
    window.alert(JSON.stringify(values, 0, 2))
}

const required = value => (value ? undefined : 'Required')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value =>
    isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)


class FormComponent extends React.Component {
    render() {
        let fields = this.props.config.fields.map(def => {

            //todo build validators list with def.validators iteration
            let validators = [];
            if (def.validators) {
                if (def.validators.includes("required")) {
                    validators.push(required);
                }
                if (def.validators.includes("numeric")) {
                    validators.push(mustBeNumber);
                }
            }



            return (
                <Field
                    key={def.name}
                    name={def.name}
                    component="input"
                    type={def.type}
                    placeholder={def.placeholder ? def.placeholder : def.label ? def.label : null}
                    validate={composeValidators(validators)}
                >
                    {({input, meta}) => {

                        let inputObject = (<div>

                            <input {...input} type={def.type} className="regular-input"
                                   placeholder={def.placeholder ? def.placeholder : def.label ? def.label : null}/>
                            {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </div>);


                        if (this.props.config.layout && this.props.config.layout.direction == "vertical") {
                            return (
                                <div className="col">
                                    <span className="form-label">{def.label && def.label}</span>
                                    {inputObject}
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="col-md-2">
                                    <span className="form-label">{def.label && def.label}</span>
                                    {inputObject}
                                </div>
                            )
                        }
                    }}
                </Field>
            )
        });


        let initialValues = {};
        if (this.props.data) {
            for (let def of this.props.config.fields) {
                initialValues[def.name] = this.props.data[def.name];
            }
        }


        let submit = (submitting, pristine) => {
            if (this.props.config.submit) {
                return (
                    <button type="submit" className="btn btn-primary" disabled={submitting}>
                            {this.props.config.submit.label}
                        </button>
                );
            }
        };

        let reset = (form, submitting, pristine) => {
            if (this.props.config.reset) {
                return (

                        <button
                            type="button"
                            onClick={form.reset}
                            disabled={submitting || pristine}
                        >
                            Reset
                        </button>

                );
            }
        };


        return (

            <Form

                onSubmit={() => this.props.runAction(this.props.config.submitAction)}
                initialValues={initialValues}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                    <form onSubmit={handleSubmit}

                    >
                        <div className="row form-line">
                            <FormStateToRedux form={this.props.config.id}/>


                            {fields}

                            <div className="col-md-2">
                                <span className="form-label">&nbsp; &nbsp;</span>
                                <div>
                                    {submit(submitting, pristine)}
                                </div>
                            </div>

                            {reset(form, submitting, pristine)}

                        </div>
                    </form>
                )}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent)

