import React from 'react'
import {connect} from "react-redux";
import * as actions from "../../actions/browseActions";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    return {
        content: state["browse"][ownProps.browseType].results.content,
        totalElements: state["browse"][ownProps.browseType].results.totalElements,
        size: state["browse"][ownProps.browseType].results.size,
        number: state["browse"] [ownProps.browseType].results.number,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPage: (page) => dispatch(actions.setPageAndSearch(page, ownProps.browseType)),
        goPrevItem: (history) => dispatch(actions.navigateToPrevItem(ownProps.recordId, ownProps.browseType, history)),
        goNextItem: (history) => dispatch(actions.navigateToNextItem(ownProps.recordId, ownProps.browseType, history))
    }
};

class NavigateInSearchResultsComponent extends React.Component {
    getPosition() {
        if (this.props.content && Array.isArray(this.props.content)) {
            let pos = this.props.content.findIndex(el => el.id == this.props.recordId)

            if (pos < 0) {
                return 0
            }

            return pos + 1 + (this.props.size * this.props.number)
        }

        return 0
    }

    goPrev() {
        this.props.goPrevItem(this.props.history)
    }

    goNext() {
        this.props.goNextItem(this.props.history)
    }

    render() {

        let positionOnList = this.getPosition();

        let visible = positionOnList > 0 && this.props.totalElements > 0

        if (!visible) {
            return null
        }

        let isFirst = positionOnList == 1;
        let isLast = positionOnList == this.props.totalElements;

        let counter = <span style={{color: "#6c757d"}}>{positionOnList}/{this.props.totalElements} &nbsp;</span>

        return (
            <React.Fragment>
                {counter}
                <button type="button" className="btn btn-outline-primary" disabled={isFirst}
                        onClick={() => this.goPrev()}><i
                    className="fas fa-angle-left"></i></button>
                <button type="button" className="btn btn-outline-primary" disabled={isLast}
                        onClick={() => this.goNext()}><i
                    className="fas fa-angle-right"></i></button>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigateInSearchResultsComponent))

