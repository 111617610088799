// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
const UPDATE_FORM_STATE = 'final-form-redux-example/finalForm/UPDATE_FORM_STATE'
export const ENGINE_SET_FORM_VALUE = "ENGINE_SET_FORM_VALUE";

// Reducer
export default function finalFormReducer(state = {}, action = {}) {
    switch (action.type) {
        case UPDATE_FORM_STATE:
            return {
                ...state,
                [action.form]: action.payload
            }

        case ENGINE_SET_FORM_VALUE:
            let {formId, name, value} = action.payload;
            return updateFormValue(state, formId, name, value);
        default:
            return state
    }
}

const updateFormValue = (state, form, name, value) => {
    let newState = {...state};

    if (!newState[form]) {
        newState[form] = {
            values: {}
        };
    };

    newState[form].values[name] = value;

    return newState;
};

// Action Creators
export const updateFormState = (form, state) => ({
    type: UPDATE_FORM_STATE,
    form,
    payload: state
})

// Selectors
