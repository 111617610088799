export const FOLDERS_GET_DATA = 'FOLDERS_GET_DATA';
export const FOLDERS_GET_DATA_REQUESTED = 'FOLDERS_GET_DATA_REQUESTED';
export const FOLDERS_GET_DATA_DONE = 'FOLDERS_GET_DATA_DONE';
export const FOLDERS_GET_DATA_FAILED = 'FOLDERS_GET_DATA_FAILED';

export function getFolders() {
    return {
        type: FOLDERS_GET_DATA
    };
}

