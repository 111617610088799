import {call, put, select} from "redux-saga/effects";
import {ARTWORKS} from "../components/browse/BrowseType";
import {getSearchParams} from "../selectors/searchParams.selector";
import {artworkFilterAvailable} from "../components/browse/filtersConfig";
import {getArtworksIdsFromREST} from "../api/browse.api";

export const TOGGLE_ITEM_SELECTION = "TOGGLE_ITEM_SELECTION"
export const SELECT_ALL_ITEMS = "SELECT_ALL_ITEMS"
export const SELECT_NONE = "SELECT_NONE"
export const TOGGLE_ALL_ITEMS_SELECTION = "TOGGLE_ALL_ITEMS_SELECTION"

export const SELECTION_STATE = {
    ALL: "ALL",
    NONE: "NONE",
    SOME: "SOME",
}


let initialState = {
    selectedItems: [],
    allSelected: SELECTION_STATE.NONE,
}

export default function tableSelectionReducer(state = initialState, action = {}) {
    switch (action.type) {
        case TOGGLE_ITEM_SELECTION:
            return toggleItemReduce(state, action.payload)
        case SELECT_ALL_ITEMS:
            return selectAllItems(state, action.payload)
        case SELECT_NONE:
            return clearSelection(state, action.payload)
        default:
            return state
    }
}


export const toggleItem = (id) => ({
    type: TOGGLE_ITEM_SELECTION,
    payload: {id}
})


export const toggleAllItemsSelection = () => ({
    type: TOGGLE_ALL_ITEMS_SELECTION,
})


function toggleItemReduce(state, payload) {
    let selected = [...state.selectedItems]
    let id = payload.id

    if (selected.includes(id)) {
        selected = selected.filter(item => item != id)
    } else {
        selected.push(id)
    }

    let selectionState = selected.length > 0 ? SELECTION_STATE.SOME : SELECTION_STATE.NONE

    return {...state, selectedItems: selected, allSelected: selectionState}
}

function selectAllItems(state, payload) {
    return {...state, selectedItems: payload.selected, allSelected: SELECTION_STATE.ALL}
}

function clearSelection(state) {
    return {...state, selectedItems: [], allSelected: SELECTION_STATE.NONE}
}

export const getSelectedItems = (state) => state.tableSelection ? state.tableSelection.selectedItems : []
export const getAllSelected = (state) => state.tableSelection ? state.tableSelection.allSelected : undefined

export function* toggleAllItemsSelectionSaga(action) {
    let allSelectedState = yield select(state => getAllSelected(state))

    if (SELECTION_STATE.NONE == allSelectedState) {

        //todo parametrize this actions, all tables should be supported
        let searchParams = yield select(getSearchParams, ARTWORKS, artworkFilterAvailable);
        let responseBody = yield call(getArtworksIdsFromREST, searchParams);
        let ids = responseBody.map(record => record)

        //select all
        yield put({
            type: SELECT_ALL_ITEMS,
            payload: {
                selected: ids
            }
        });
    } else {
        //select none
        yield put({
            type: SELECT_NONE,
        });
    }
}