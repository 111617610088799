export const mainMenuConfig = {
    type: "navbar",
    items: [
        {
            url: "members",
            label: "Członkowie",
            requiredRole: "VIEWER",
        },
        // {
        //     url: "persons",
        //     label: "osoba",
        //     requiredRole: "ADMIN",
        // },
        // {
        //     url: "content",
        //     label: "treść",
        //     requiredPermission: ["modules", "content", "READ"],
        // },
        // {
        //     url: "news",
        //     label: "aktualności",
        //     requiredPermission: ["modules", "news", "READ"],
        // },
        {
            url: "dictionary-entry",
            label: "słowniki",
            requiredRole: "EDITOR",
        },
        {
            url: "administration",
            label: "administracja",
            requiredRole: "ADMIN",
        },
    ]
};