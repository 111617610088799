import jwtDecode from 'jwt-decode';

export function  getBaseRequestConfig (method) {

    let m = "GET";
    if (method) {
        m = method;
    }

    let request = {
        method: m,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (window.sessionStorage.accessToken !== undefined
        && window.sessionStorage.accessToken !== null
        && window.sessionStorage.accessToken !== "null") {

        try {
            let decoded = jwtDecode(window.sessionStorage.accessToken);
            // console.log("attaching auth header", window.sessionStorage.accessToken, decoded, new Date(decoded.exp * 1000));
        }
        catch (e) {}

        request.headers.Authorization = window.sessionStorage.accessToken;
    }

    return request;
};
