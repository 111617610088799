// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";
import asyncFetch from "../api/async-fetch";


let emptyNews = {
    title: {},
    content: {},
    datePublished: {
        from: {}
    }
};

let initialState = {
    list: [],
    news: null,

}

export const NEWS_LIST_LOAD = "NEWS_LIST_LOAD"
export const NEWS_LIST_SET = "NEWS_LIST_SET"
export const NEWS_LOAD = "NEWS_LOAD"
export const NEWS_SET = "NEWS_SET"
export const NEWS_SAVE = "NEWS_SAVE"
export const NEWS_SET_LANGUAGE_VERSION = "NEWS_SET_LANGUAGE_VERSION"
export const NEWS_SET_TITLE = "NEWS_SET_TITLE"
export const NEWS_SET_CONTENT = "NEWS_SET_CONTENT"
export const NEWS_SET_PUBLICATION_DATE = "NEWS_SET_PUBLICATION_DATE"


export const getNewsList = () => ({
    type: NEWS_LIST_LOAD,
})

export const setLangVersion = (dictionaryEntry) => ({
    type: NEWS_SET_LANGUAGE_VERSION,
    payload: dictionaryEntry
})

export const getNews = (id) => ({
    type: NEWS_LOAD,
    payload: {id}
})

export const setTitle = (lang, value) => ({
    type: NEWS_SET_TITLE,
    payload: {lang, value}
})

export const setContent = (lang, value) => ({
    type: NEWS_SET_CONTENT,
    payload: {lang, value}
})


export const saveNews = () => ({
    type: NEWS_SAVE,
})

export const setPublicationDate = (date) => ({
    type: NEWS_SET_PUBLICATION_DATE,
    payload: date
})


// Reducer
export default function newsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case NEWS_SET:
            return {...state, news: action.payload}
        case NEWS_LIST_SET:
            return {...state, list: action.payload}
        case NEWS_SET_LANGUAGE_VERSION:
            return reduceSetLanguageVersion(state, action.payload)
        case NEWS_SET_TITLE:
            return reduceSetTitle(state, action.payload)
        case NEWS_SET_CONTENT:
            return reduceSetContent(state, action.payload)
        case NEWS_SET_PUBLICATION_DATE:
            return reduceSetPublicationDate(state, action.payload)
        default:
            return state
    }
}

function reduceSetLanguageVersion(state, payload) {
    let news = {...state.news, _langVersion: payload}

    return {...state, news: news}
}

function reduceSetTitle(state, payload) {
    let news = {...state.news}
    news.title[payload.lang] = payload.value

    return {...state, news: news}
}

function reduceSetContent(state, payload) {
    let news = {...state.news}
    news.content[payload.lang] = payload.value

    return {...state, news: news}
}

function reduceSetPublicationDate(state, payload) {
    let datePublished = {
        from: payload
    }

    let news = {...state.news, datePublished: datePublished}

    return {...state, news: news}
}

export function* getNewsListSaga() {
    try {
        let news = yield call(getNewsListREST);

        yield put({
            type: NEWS_LIST_SET,
            payload: news
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania listy aktualności");
    } finally {

    }
}

export function* getNewsSaga(action) {
    try {

        let {id} = action.payload

        let news = null;

        if (id) {
            news = yield call(getNewsREST, action.payload.id);
        } else {
            news = {...emptyNews}
        }

        yield put({
            type: NEWS_SET,
            payload: news
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania aktualności");
    } finally {

    }
}

export function* saveNewsSaga(action) {
    try {

        let news = yield select(state => state.news.news)

        let redirect = !news.id

        news = yield call(saveNewsREST, news);

        yield put({
            type: NEWS_SET,
            payload: news
        });

        if (redirect) {
            window.location.href = "/news/edit/" + news.id;
        }

        toast.success("Zapisano zmiany");
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisywania aktualności");
    } finally {

    }
}

export async function saveNewsREST(news) {

    const url = `${BACKEND_URL}news/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(news)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function getNewsListREST() {

    const url = `${BACKEND_URL}news/`;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}

export async function getNewsREST(id) {

    const url = `${BACKEND_URL}news/` + id;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}
