import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";


export async function cropAssetREST(assetId, artworkId, crop) {

    const url = `${BACKEND_URL}crop/` + assetId + "," + artworkId;

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(crop)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}