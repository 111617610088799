import React from 'react'
import {connect} from "react-redux";
import {
    addToAttribute,
    ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
    removeFromAttribute,
    setAttributeSecondLevel
} from "../../actions/artworkActions";
import {viewToDbMapper} from "../../components/item/OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "../../components/item/ToggleEditable";
import SelectizeEditableFieldInline from "./SelectizeEditableFieldInline";
import DateByPartsEditableField from "./DateByPartsEditableField";
import SimpleEditableFieldInline from "./SimpleEditableFieldInline";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "roles"),
        country: getOptions(state, "country"),
        city: getOptions(state, "city"),
        unknown: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.unknown : false,
        comment: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.comment : null,
        roles: state.artworks.artwork.roles ? state.artworks.artwork.roles : []
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        addNewWorkplace: () => dispatch(addToAttribute("roles", {
            role: null,
            from: {},
            to: {},
        })),
        deleteItem: (idx) => dispatch(removeFromAttribute("roles", idx)),
        setPlaceOfCreation: (type, entry) => dispatch(setAttributeSecondLevel("placeOfCreation", type, entry)),
        openCreateDictionaryEntryModal: (dictionaryType, label) => dispatch(openCreateDictionaryEntryModal(dictionaryType, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "placeOfCreation",
                subattr: dictionaryEntryTypeToFieldType(dictionaryType)
            }
        })),
    }
};


const getOptions = (state, type) => state.artworks.artwork.placeOfCreation && Array.isArray(state.artworks.artwork.placeOfCreation[type]) ?
    state.artworks.artwork.placeOfCreation[type].map((dictionaryEntry) => {
        return dictionaryEntry ? toSelectizeDictionaryEntry(dictionaryEntry) : null
    }) : [];


const fieldTypeToDictionaryEntryType = (type) => {
    switch (type) {
        case "city":
            return "cities";
        case "country":
            return "countries";
        default:
            break;
    }
};

const dictionaryEntryTypeToFieldType = (type) => {
    switch (type) {
        case "cities":
            return "city";
        case "countries":
            return "country";
        default:
            break;
    }
};


class ContactEditableField extends React.Component {

    handleChange = (type, inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length - 1].label;
                this.props.openCreateDictionaryEntryModal(fieldTypeToDictionaryEntryType(type), label);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setPlaceOfCreation(type, viewToDbMapper(inputValue));
            default:
                break;
        }

    };


    render() {

        let items = this.props.roles.map((w, i) => {
            return (
                <div className="component-content content-padding">
                    <div className="component content-padding hide-delete-button">
                        <div className={"row"}>
                            <div className={"col"}>
                                <SelectizeEditableFieldInline path={`roles.${i}.role`} label="role"
                                                              dictionaryId="members_role"
                                                              enabledOverride={this.props.enabled}/>
                                <DateByPartsEditableField path={`roles.${i}.from`} label="from" type="date"
                                                          enabled={this.props.enabled}/>
                                <DateByPartsEditableField path={`roles.${i}.to`} label="to" type="date"
                                                          enabled={this.props.enabled}/>
                            </div>
                            <div className={"col-sm-1"}>
                                {this.props.enabled &&
                                    <button type="button" className="btn btn-outline-danger delete-button "
                                            disabled={!this.props.enabled}
                                            onClick={() => this.props.deleteItem(i)}>x
                                    </button>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("memberRoles")}</span>
                        <ToggleEditable fieldId={"roles"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        {items}
                        <SimpleEditableFieldInline path={`rolesComment`} type="text" label="Komentarze"
                                                   enabledOverride={this.props.enabled}/>
                        {this.props.enabled &&
                            <div className={"component-content content-padding"}>

                                <div className="row">
                                    <button type="button" className="btn btn-primary col"
                                            onClick={() => this.props.addNewWorkplace()}>{t("+")}

                                    </button>
                                </div>
                            </div>

                        }

                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditableField)

