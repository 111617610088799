import {call, put, select} from 'redux-saga/effects';
import * as actions from "../actions/engine.actions";
import {getREST, postREST} from "../../api/common.api";
import {buildUrl} from "./buildUrl";
import {getComponentState, selectComponentData, selectFormValues} from "../selectors";
import {ENGINE_SET_FORM_VALUE} from "../form/finalFormDuck";
import {USER_SET_TOKEN} from "../../user/userDuck";

export function *prepareConfig(config) {
    yield put({
        type: actions.ENGINE_PREPARE_SET_MAIN_CONFIG,
        payload: config
    });

    yield prepareRecursive(config.payload);
}


function *prepareRecursive(config) {
    yield put({
        type: actions.ENGINE_PREPARE_COMPONENT,
        payload: config
    });

    if (config.children) {
        for (let i = 0; i < config.children.length; i++) {
            yield prepareRecursive(config.children[i]);
        }

    }
}

export function *runAction(action) {
    yield runActonRecursive(action.payload.actionConfig, action.payload.record);
}


function *runActonRecursive(actionConfig,record) {
    console.log(actionConfig)
    if (actionConfig.type) {
        if (actionConfig.type == "show-component") {

            yield put({
                type: actions.ENGINE_RUN_ACTION_SHOW_COMPONENT,
                payload: actionConfig.componentId
            });
        }
        else if (actionConfig.type == "hide-component") {

            yield put({
                type: actions.ENGINE_RUN_ACTION_HIDE_COMPONENT,
                payload: actionConfig.componentId
            });
        }
        else if (actionConfig.type == "block-component") {

            yield put({
                type: actions.ENGINE_RUN_ACTION_BLOCK_COMPONENT,
                payload: actionConfig.componentId
            });
        }
        else if (actionConfig.type == "unblock-component") {

            yield put({
                type: actions.ENGINE_RUN_ACTION_UNBLOCK_COMPONENT,
                payload: actionConfig.componentId
            });
        }
        else if (actionConfig.type == "clean-data-in-component") {

            yield put({
                type: actions.ENGINE_SET_DATA_TO_COMPONENT,
                payload: {
                    componentId: actionConfig.componentId,
                    response: null
                }
            });
        }
        else if (actionConfig.type == "call-rest") {

            try {
                let url = buildUrl(actionConfig, record);
                const responseBody = yield call(getREST, url);


                if (actionConfig.setResponseToComponent) {
                    yield put({
                        type: actions.ENGINE_SET_DATA_TO_COMPONENT,
                        payload: {
                            componentId: actionConfig.setResponseToComponent,
                            response: responseBody
                        }
                    });
                }
            } catch (e) {
                console.log(e);
                console.log("actionConfig.errorAction", actionConfig);
                if (actionConfig.errorAction) {
                    yield runActonRecursive(actionConfig.errorAction);
                }
            }

            yield runActonRecursive("actionConfig.successAction",actionConfig);
            if (actionConfig.successAction) {
                yield runActonRecursive(actionConfig.successAction);
            }
        }
        else if (actionConfig.type == "call-rest-post") {
            let baseData = actionConfig.baseDateFromComponent ? yield selectComponentData() : {};

            let body = {...baseData};

            if (actionConfig.mergeDataFromForm) {

                let formValues = yield selectFormValues(actionConfig.mergeDataFromForm);

                for (let key of Object.keys(formValues)) {
                    body[key] = formValues[key];
                }
            }

            if (actionConfig.isPaged) {
                let pagination = yield select(state => getComponentState(state, actionConfig.addPagingDataFromComponent).pagination);
                body.page = pagination.pageNumber;
                body.pageSize = pagination.pageSize;
            }

            try {
                const responseBody = yield call(postREST, actionConfig.url, body);

                if (actionConfig.setResponseToComponent) {
                    yield put({
                        type: actions.ENGINE_SET_DATA_TO_COMPONENT,
                        payload: {
                            componentId: actionConfig.setResponseToComponent,
                            response: actionConfig.isPaged ? responseBody.content : responseBody
                        }
                    });

                    if (actionConfig.isPaged) {
                        yield put({
                            type: actions.ENGINE_SET_PAGING_DATA_TO_COMPONENT,
                            payload: {
                                componentId: actionConfig.setResponseToComponent,
                                pagination: {
                                    totalPages: responseBody.totalPages,
                                    totalElements: responseBody.totalElements,
                                    pageNumber: responseBody.number,
                                    pageSize: responseBody.size
                                }
                            }
                        });
                    }
                }

                if (actionConfig.setToken) {
                    yield put({
                        type: USER_SET_TOKEN,
                        payload: responseBody.token
                    });
                }

                yield runActonRecursive("actionConfig.successAction", actionConfig);
                if (actionConfig.successAction) {
                    yield runActonRecursive(actionConfig.successAction);
                }
            } catch (e) {
                console.log(e);
                yield runActonRecursive("actionConfig.errorAction",actionConfig);
                if (actionConfig.errorAction) {
                    yield runActonRecursive(actionConfig.errorAction);
                }
            }

        }


        else if (actionConfig.type == "SET_RECORD_TO_COMPONENT") {
            yield put({
                type: actions.ENGINE_SET_DATA_TO_COMPONENT,
                payload: {
                    componentId: actionConfig.componentId,
                    response: record
                }
            });
        }
        else if (actionConfig.type == "set_pagination_page") {
            yield put({
                type: actions.ENGINE_SET_PAGING_DATA_TO_COMPONENT,
                payload: {
                    componentId: actionConfig.componentId,
                    pagination: actionConfig.pagination
                }
            });
        }
        else if (actionConfig.type == "set-search-form-value") {
            yield put({
                type: ENGINE_SET_FORM_VALUE,
                payload: {
                    formId: actionConfig.componentId+"_SearchForm",
                    name: actionConfig.name,
                    value: record[actionConfig.mapping]
                }
            });
        }
        else if (actionConfig.type == "redirect" && window.location.pathname !== actionConfig.path) {
            window.location.href = actionConfig.path;
        }
    }


    if (actionConfig.children) {
        for (let i = 0; i < actionConfig.children.length; i++) {
            yield runActonRecursive(actionConfig.children[i], record);
        }

    }
}

