import React from 'react'
import {connect} from "react-redux";
import {ARTWORK_ADD_ATTRIBUTE_SECOND_LVL, setAttributeSecondLevel} from "../../actions/artworkActions";
import {viewToDbMapper} from "../../components/item/OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "../../components/item/ToggleEditable";
import SimpleEditableFieldInline from "./SimpleEditableFieldInline";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "contact"),
        country: getOptions(state, "country"),
        city: getOptions(state, "city"),
        unknown: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.unknown : false,
        comment: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.comment : null
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPlaceOfCreation: (type, entry) => dispatch(setAttributeSecondLevel("placeOfCreation", type, entry)),
        openCreateDictionaryEntryModal: (dictionaryType, label) => dispatch(openCreateDictionaryEntryModal(dictionaryType, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "placeOfCreation",
                subattr: dictionaryEntryTypeToFieldType(dictionaryType)
            }
        })),
    }
};


const getOptions = (state, type) => state.artworks.artwork.placeOfCreation && Array.isArray(state.artworks.artwork.placeOfCreation[type]) ?
    state.artworks.artwork.placeOfCreation[type].map((dictionaryEntry) => {
        return dictionaryEntry ? toSelectizeDictionaryEntry(dictionaryEntry) : null
    }) : [];


const fieldTypeToDictionaryEntryType = (type) => {
    switch (type) {
        case "city":
            return "cities";
        case "country":
            return "countries";
        default:
            break;
    }
};

const dictionaryEntryTypeToFieldType = (type) => {
    switch (type) {
        case "cities":
            return "city";
        case "countries":
            return "country";
        default:
            break;
    }
};


class ContactEditableField extends React.Component {

    handleChange = (type, inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length - 1].label;
                this.props.openCreateDictionaryEntryModal(fieldTypeToDictionaryEntryType(type), label);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setPlaceOfCreation(type, viewToDbMapper(inputValue));
            default:
                break;
        }

    };


    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("contact")}</span>
                        <ToggleEditable fieldId={"contact"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <SimpleEditableFieldInline path="phonePersonal" type="input"
                                                   enabledOverride={this.props.enabled}/>
                        <SimpleEditableFieldInline path="phoneMobile" type="input"
                                                   enabledOverride={this.props.enabled}/>
                        <SimpleEditableFieldInline path="email" type="input" enabledOverride={this.props.enabled}/>
                    </div>

                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditableField)

