export const ENGINE_OPEN_EDIT = "ENGINE_OPEN_EDIT";
export const ENGINE_CLOSE_EDIT = "ENGINE_CLOSE_EDIT";
export const ENGINE_MANAGE_TABLE_SET_COLUMNS = "ENGINE_MANAGE_TABLE_SET_COLUMNS";
export const ENGINE_MANAGE_TABLE_REMOVE_COLUMN = "ENGINE_MANAGE_TABLE_REMOVE_COLUMN";
export const ENGINE_MANAGE_TABLE_MOVE_COLUMN_UP = "ENGINE_MANAGE_TABLE_MOVE_COLUMN_UP";
export const ENGINE_MANAGE_TABLE_MOVE_COLUMN_DOWN = "ENGINE_MANAGE_TABLE_MOVE_COLUMN_DOWN";

export function openEdit(config, sampleData) {
    return {
        type: ENGINE_OPEN_EDIT,
        payload: {config, sampleData}
    }
}

export function closeEdit() {
    return {
        type: ENGINE_CLOSE_EDIT
    }
}

export function setColumns(columns) {
    return {
        type: ENGINE_MANAGE_TABLE_SET_COLUMNS,
        payload: columns
    }
}

export function removeColumn(columnId) {
    return {
        type: ENGINE_MANAGE_TABLE_REMOVE_COLUMN,
        payload: columnId
    }
}

export function moveColumnUp(columnId) {
    return {
        type: ENGINE_MANAGE_TABLE_MOVE_COLUMN_UP,
        payload: columnId
    }
}

export function moveColumnDown(columnId) {
    return {
        type: ENGINE_MANAGE_TABLE_MOVE_COLUMN_DOWN,
        payload: columnId
    }
}
