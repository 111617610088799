import React from 'react'
import {connect} from "react-redux";
import {getDictionaryEntry, saveDictionaryEntry, setDictionary} from "./dictionaryEntryDuck";
import {Translation} from "react-i18next";
import DictionaryEntryEditLanguage from "./DictionaryEntryEditLanguageComponent";
import {commonDataLoadDictionaries, selectCommonDataDictionaries} from "../../common/commonDuck";
import Select from "react-select";
import DictionaryEntryEditDates from "./DictionaryEntryEditDatesComponent";
import DictionaryEntryEditCode from "./DictionaryEntryEditCodeComponent";
import DictionaryEntryEditFinancedBy from "./DictionaryEntryEditFinancedByComponent";
import {getUserPermissions} from "../../engine/selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        dictionaryEntry: state.dictionaryEntries.dictionaryEntry,
        dictionaries: selectCommonDataDictionaries(state).map(mapDictionaryEntry),
        canCreate: getUserPermissions(state, "modules", "dictionary_entry").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDictionaryEntry: () => dispatch(getDictionaryEntry(ownProps.match.params.id)),
        loadDictionaries: () => dispatch(commonDataLoadDictionaries()),
        setDictionary: (dictionary) => dispatch(setDictionary(dictionary)),
        save: () => dispatch(saveDictionaryEntry()),
    }
};

class DictionaryEntryEdit extends React.Component {

    componentDidMount() {
        this.props.getDictionaryEntry();
        this.props.loadDictionaries();
    }

    goBack() {
        window.location.href = "/dictionary-entry/";
    }

    handleChange = (inputValue, actionMeta) => {

        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setDictionary(inputValue.value);
            default:
                break;
        }

    };

    render() {
        if (!this.props.dictionaryEntry) {
            return null
        }


        let headerName = this.props.id ? "dictionaryEntryEdit" : "dictionaryEntryCreate"

        let valid = (this.props.dictionaryEntry.labels.pl != "" || this.props.dictionaryEntry.labels.en != "")
            && this.props.dictionaryEntry.dictionary.id

        return (
            <div className="row justify-content-between">
                <main className="col-md-12 content">
                    <div className="row">
                        <Translation>{t =>
                            <h2>{t(headerName)}</h2>
                        }</Translation>
                    </div>
                    <div className="row">
                        <div className="col header-actions-menu">
                            <button type="button" className="btn btn-outline-primary" onClick={this.goBack}><i
                                className="fas fa-angle-left"></i></button>
                            <button type="button" className="btn btn-primary" disabled={!valid}
                                    onClick={event => this.props.save()}>Zapisz
                            </button>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5">

                            <div className="component">
                                <header className="component-header">
                                    <Translation>{t =>
                                        <span>{t("dictionary")}</span>
                                    }</Translation>
                                </header>
                                <div className="message">

                                </div>
                                <div className="component-content">
                                    <Select
                                        isDisabled={this.props.id}
                                        name="dictionary"
                                        value={this.props.dictionaryEntry.dictionary ? mapDictionaryEntry(this.props.dictionaryEntry.dictionary) : null}
                                        onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                                        isClearable={false}
                                        defaultOptions
                                        options={this.props.dictionaries}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5">
                            <DictionaryEntryEditDates/>
                            <DictionaryEntryEditCode/>
                            <DictionaryEntryEditFinancedBy/>
                        </div>

                    </div>


                    <div className="row">
                        <DictionaryEntryEditLanguage lang={"pl"}/>
                        <DictionaryEntryEditLanguage lang={"en"}/>
                    </div>

                </main>
            </div>

        )
    }
}

function mapDictionaryEntry(entry) {
    return {
        label: entry.labels && entry.labels.pl ? entry.labels.pl : entry.id,
        value: entry
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntryEdit)

