import React from 'react'
import {Translation} from "react-i18next";
import {getFilterValues} from "../../reducers/filteringReducer";
import {
    getSelectedQuery,
    loadQueries,
    queriesSetSearch,
    queryDelete,
    querySelect,
    selectQueries,
    selectSearch
} from "../../query/queryDuck";
import connect from "react-redux/lib/connect/connect";

const visibleItems = 5

const mapStateToProps = (state, ownProps) => {
    return {
        queries: selectQueries(state),
        selectedQuery: getSelectedQuery(state),
        search: selectSearch(state),
        filters: getFilterValues(state),
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadQueries: () => dispatch(loadQueries()),
        setSearch: (value) => dispatch(queriesSetSearch(value)),
        querySelect: (query) => dispatch(querySelect(query, ownProps.browseType)),
        queryDelete: (query) => dispatch(queryDelete(query.id, ownProps.browseType)),
    }
};


class ArtworksQueries extends React.Component {

    componentDidMount() {
        this.props.loadQueries();
    }

    render() {
        let queriesList = null

        if (this.props.queries && this.props.queries.length > 0) {
            queriesList = this.props.queries
                .filter(query => this.props.search == "" || query.name.includes(this.props.search))
                .map(query => {

                    let isActive = this.props.selectedQuery && this.props.selectedQuery.id == query.id

                    return (
                        <li className={isActive ? "active search__item" : "search__item"}>
                            <span onClick={() => this.props.querySelect(query)}>{query.name}</span>
                            <span className="hidden-query-actions">
                                <i className="far fa-trash-alt" onClick={() => this.props.queryDelete(query)}></i>
                            </span>
                        </li>
                    )
                })
        }

        let noActiveFilters = (!this.props.selectedQuery || !this.props.selectedQuery.id)
            && (!this.props.filters || Object.keys(this.props.filters).length == 0)

        return (
            <Translation>{t =>
                <div className="search">
                    <h3>{t("queries")}</h3>
                    <input type="text"
                           placeholder="Wyszukaj"
                           value={this.props.search}
                           onChange={(e) => this.props.setSearch(e.target.value)}/>
                    <ul>
                        <li className={noActiveFilters ? "active search__item" : "search__item"}>
                            <span onClick={() => this.props.querySelect(null)}>Wszystkie obiekty</span>
                        </li>
                        {queriesList}
                    </ul>

                </div>
            }</Translation>
        )
    }
}

function isSelected(options, dictionaryEntryId) {
    return options && options.filter(option => option.dictionaryEntry.id == dictionaryEntryId).length > 0
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworksQueries)

