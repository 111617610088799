// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {getDictionaryEntries} from "../api/dictionaryEntry.api";
import {fetchDictionariesREST} from "../api/dictionary.api";

let initialState = {
    dictionaryEntriesByDicId: {},
    dictionaries: []
}

export const COMMON_DATA_LOAD_DICTIONARY_ENTRIES = "COMMON_DATA_LOAD_DICTIONARY_ENTRIES"
export const COMMON_DATA_SET_DICTIONARY_ENTRIES = "COMMON_DATA_SET_DICTIONARY_ENTRIES"

export const COMMON_DATA_LOAD_DICTIONARIES = "COMMON_DATA_LOAD_DICTIONARIES"
export const COMMON_DATA_SET_DICTIONARIES = "COMMON_DATA_SET_DICTIONARIES"


export const commonDataLoadDictionaryEntries = (dictionaryId) => ({
    type: COMMON_DATA_LOAD_DICTIONARY_ENTRIES,
    payload: {dictionaryId}
})

export const commonDataLoadDictionaries = () => ({
    type: COMMON_DATA_LOAD_DICTIONARIES,
})


// Reducer
export default function commonDataReducer(state = initialState, action = {}) {
    switch (action.type) {
        case COMMON_DATA_SET_DICTIONARY_ENTRIES:
            return reduceSetDictionaryEntries(state, action.payload)
        case COMMON_DATA_SET_DICTIONARIES:
            return {...state, dictionaries: action.payload}
        default:
            return state
    }
}

function reduceSetDictionaryEntries(state, payload) {
    let dictionaryEntriesByDicId = {...state.dictionaryEntriesByDicId};
    let {dictionaryId, options} = payload;
    dictionaryEntriesByDicId[dictionaryId] = options;

    return {...state, dictionaryEntriesByDicId: dictionaryEntriesByDicId}
}


export function* commonDataLoadDictionaryEntriesSaga(action) {

    let {dictionaryId} = action.payload;

    try {
        let options = yield select(state => selectCommonDataDictionaryEntries(state, dictionaryId))

        if (options.length == 0) {
            options = yield call(getDictionaryEntries, dictionaryId);

            yield put({
                type: COMMON_DATA_SET_DICTIONARY_ENTRIES,
                payload: {dictionaryId, options}
            });
        }

    } catch (e) {
        console.error(e)
        toast.error("Wystąpił błąd podczas ładowania listy projektów");
    }

}


export function* commonDataLoadDictionariesSaga(action) {

    try {
        let dictionaries = yield select(state => selectCommonDataDictionaries(state))

        if (dictionaries.length == 0) {
            dictionaries = yield call(fetchDictionariesREST);

            yield put({
                type: COMMON_DATA_SET_DICTIONARIES,
                payload: dictionaries
            });
        }

    } catch (e) {
        console.error(e)
        toast.error("Wystąpił błąd podczas ładowania listy projektów");
    }

}


export const selectCommonDataDictionaryEntries = (state, dictionaryId) => state.commonData.dictionaryEntriesByDicId && state.commonData.dictionaryEntriesByDicId[dictionaryId] ? state.commonData.dictionaryEntriesByDicId[dictionaryId] : []
export const selectCommonDataDictionaries = (state) => state.commonData.dictionaries ? state.commonData.dictionaries : []