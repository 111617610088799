import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";

import {setLabel} from "./dictionaryEntryDuck";
import {Editor} from "@tinymce/tinymce-react";
import {tinyMceOptions} from "../item/SimpleEditableField";

const mapStateToProps = (state, ownProps) => {
    return {
        lang: ownProps.lang,
        label: state.dictionaryEntries.dictionaryEntry.labels[ownProps.lang],
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLabel: (value) => dispatch(setLabel(ownProps.lang, value)),
    }
};

class DictionaryEntryEditLanguage extends React.Component {

    handleTitleEditorChange = (content) => {
        this.props.setLabel(content)
    };


    render() {
        return (
            <div className="col-xl-5">
                <div className="component">
                    <header className="component-header">
                        <Translation>{t =>
                            <span>{t("label")} {this.props.lang}</span>
                        }</Translation>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content">

                        <Editor
                            apiKey="o1p8a6a82ye3v8z03yctvwp70y3iyo8lst7tamh2a7zeibhd"
                            initialValue={this.props.label}
                            init={{
                                height: 150,
                                menubar: false,
                                plugins: tinyMceOptions.plugins,
                                toolbar: tinyMceOptions.toolbar,
                            }}
                            onEditorChange={this.handleTitleEditorChange}
                        />
                    </div>
                </div>
            </div>

        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntryEditLanguage)

