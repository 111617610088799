export const BROWSE_GET_DATA = 'BROWSE_GET_DATA';
export const BROWSE_GET_DATA_REQUESTED = 'BROWSE_GET_DATA_REQUESTED';
export const BROWSE_GET_DATA_DONE = 'BROWSE_GET_DATA_DONE';
export const BROWSE_GET_DATA_FAILED = 'BROWSE_GET_DATA_FAILED';
export const BROWSE_SET_PAGE = 'BROWSE_SET_PAGE';
export const BROWSE_SET_SORTING = 'BROWSE_SET_SORTING';
export const BROWSE_SET_SORTING_AND_SEARCH = 'BROWSE_SET_SORTING_AND_SEARCH';
export const BROWSE_SET_PAGE_AND_SEARCH = 'BROWSE_SET_PAGE_AND_SEARCH';
export const BROWSE_SET_PAGE_SIZE = 'BROWSE_SET_PAGE_SIZE';
export const BROWSE_SET_PAGE_SIZE_AND_SEARCH = 'BROWSE_SET_PAGE_SIZE_AND_SEARCH';
export const BROWSE_REPLACE_RECORD_ON_BROWSE_TABLE = 'BROWSE_REPLACE_RECORD_ON_BROWSE_TABLE';
export const BROWSE_NAVIGATE_TO_NEXT_ITEM = 'BROWSE_NAVIGATE_TO_NEXT_ITEM';
export const BROWSE_NAVIGATE_TO_PREV_ITEM = 'BROWSE_NAVIGATE_TO_PREV_ITEM';
export const BROWSE_NAVIGATE_TO_NTH_ITEM = 'BROWSE_NAVIGATE_TO_NTH_ITEM';


export function navigateToNextItem(recordId, browseType, history) {
    return {
        type: BROWSE_NAVIGATE_TO_NEXT_ITEM,
        payload: {recordId, browseType, history}
    };
}

export function navigateToPrevItem(recordId, browseType, history) {
    return {
        type: BROWSE_NAVIGATE_TO_PREV_ITEM,
        payload: {recordId, browseType, history}
    };
}

export function navigateToNthItem(idx, browseType, history) {
    return {
        type: BROWSE_NAVIGATE_TO_NTH_ITEM,
        payload: {idx, browseType, history}
    };
}

export function setPageAndSearch(page, browseType) {
    return {
        type: BROWSE_SET_PAGE_AND_SEARCH,
        payload: {page, browseType}
    };
}

export function setPageSizeAndSearch(pageSize, browseType) {
    return {
        type: BROWSE_SET_PAGE_SIZE_AND_SEARCH,
        payload: {pageSize, browseType}
    };
}

export function setSortingAndSearch(sortBy, browseType) {
    return {
        type: BROWSE_SET_SORTING_AND_SEARCH,
        payload: {sortBy, browseType}
    };
}
