import React from 'react'
import {connect} from "react-redux";
import {setFinancedBy} from "./dictionaryEntryDuck";
import {Translation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
        dictionaryEntry: state.dictionaryEntries.dictionaryEntry,
        financedBy: state.dictionaryEntries.dictionaryEntry
        && state.dictionaryEntries.dictionaryEntry.data
        && state.dictionaryEntries.dictionaryEntry.data.financedBy ? state.dictionaryEntries.dictionaryEntry.data.financedBy : null,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setFinancedBy: (financedBy) => dispatch(setFinancedBy(financedBy)),
    }
};

class DictionaryEntryEditFinancedBy extends React.Component {


    render() {
        if (this.props.dictionaryEntry.dictionary.id != "projects") {
            return null
        }

        return (
            <div className="component">
                <header className="component-header">
                    <Translation>{t =>
                        <span>{t("financedBy")}</span>
                    }</Translation>
                </header>
                <div className="message">

                </div>
                <div className="component-content">
                        <textarea
                            className="full-width"
                            name={"financedBy"}
                            value={this.props.financedBy}
                            onChange={(e) => this.props.setFinancedBy(e.target.value)}
                        />
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntryEditFinancedBy)

