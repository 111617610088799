import {call, put} from 'redux-saga/effects';
import * as actions from "../actions/dictionaryEntriesActions";
import {createDictionaryEntryREST} from "../api/dictionaryEntry.api";

export function *createDictionaryEntryAndSetToCurrentArtwork(action) {

    try {
        yield put({
            type: actions.DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_REQUESTED,
        });

        let {dictionaryType, label, nextActionDetails} = action.payload;
        console.log("inside createDictionaryEntryAndSetToCurrentArtwork", dictionaryType, label)

        let dictionaryEntry = yield call(createDictionaryEntryREST, dictionaryType, label);

        let {actionType, payload} = nextActionDetails;

        payload.value = dictionaryEntry;

        yield put({
            type: actionType,
            payload: payload
        });

    } catch (e) {
        yield put({
            type: actions.DICTIONARY_ENTRY_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_FAILED
        });
    }
}
