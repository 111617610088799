import React from 'react'
import {connect} from "react-redux";
import {runAction} from "../actions/engine.actions";
import {getComponentState} from "../selectors";
import {isVisible} from "../constraint/checkVisibility";

const mapStateToProps = (state, ownProps) => {
    let config = state.engine.configsMap[ownProps.configId]
    return getComponentState(state, ownProps.configId) ? {
        config: state.engine.configsMap[ownProps.configId],
        isVisible: isVisible(config, state),
        blocked: getComponentState(state, ownProps.configId).blocked,
    } : {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config) => dispatch(runAction(config))
    }
};

class MessageComponent extends React.Component {


    render() {

        if (!this.props.isVisible) {
            return null;
        }

        return (
            <div className="alert alert-warning" role="alert">
                {this.props.config.content}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageComponent)

