export const getLabel = (option) => option.labels && option.labels.pl ? option.labels.pl : option.id
export const toSelectizeDictionaryEntry = (option) => {
    return {
        value: getLabel(option),
        label: getLabel(option),
        dictionaryEntry: option
    }
}
export const toSelectizeDictionary = (option) => {
    return {
        value: getLabel(option),
        label: getLabel(option),
        dictionary: option
    }
}