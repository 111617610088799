import {
    ENGINE_CLOSE_EDIT, ENGINE_MANAGE_TABLE_MOVE_COLUMN_DOWN,
    ENGINE_MANAGE_TABLE_MOVE_COLUMN_UP,
    ENGINE_MANAGE_TABLE_REMOVE_COLUMN, ENGINE_MANAGE_TABLE_SET_COLUMNS,
    ENGINE_OPEN_EDIT
} from "../actions/engine.manage.actions";


export const initialState = {isOpen: false, config: null, sampleData: null};

export default function engineManage(state = initialState, action) {
    switch (action.type) {
        case ENGINE_OPEN_EDIT:
            return {... state, isOpen: true, config: action.payload.config, sampleData: action.payload.sampleData};
        case ENGINE_CLOSE_EDIT:
            return {... initialState };
        case ENGINE_MANAGE_TABLE_SET_COLUMNS:
            return setColumns(state, action.payload);
        case ENGINE_MANAGE_TABLE_REMOVE_COLUMN:
            return removeColumn(state, action.payload);
        case ENGINE_MANAGE_TABLE_MOVE_COLUMN_UP:
            return moveColumnUp(state, action.payload);
        case ENGINE_MANAGE_TABLE_MOVE_COLUMN_DOWN:
            return moveColumnDown(state, action.payload);
        default:
            return state;
    }
}

function removeColumn(state, columnId) {
    let tableConfig = {... state.config.tableConfig};
    tableConfig.columns = tableConfig.columns.filter(columnConfig => columnConfig.id != columnId);

    return {... state, config: {...state.config, tableConfig: tableConfig}};
}


function moveColumnUp(state, columnId) {
    let tableConfig = {... state.config.tableConfig};
    let idx = tableConfig.columns.findIndex(column => column.id == columnId);

    if (idx <= 0) {
        return {... state};
    }

    let current = tableConfig.columns[idx];
    let prev = tableConfig.columns[idx-1];

    tableConfig.columns[idx-1] = current;
    tableConfig.columns[idx] = prev;

    return {... state, config: {...state.config, tableConfig: tableConfig}};
}


function moveColumnDown(state, columnId) {
    let tableConfig = {... state.config.tableConfig};
    let idx = tableConfig.columns.findIndex(column => column.id == columnId);

    if (idx <= 0 || (tableConfig.columns.length == idx +1)) {
        return {... state};
    }

    let current = tableConfig.columns[idx];
    let next = tableConfig.columns[idx+1];

    tableConfig.columns[idx+1] = current;
    tableConfig.columns[idx] = next;

    return {... state, config: {...state.config, tableConfig: tableConfig}};
}

function setColumns(state, columns) {
    let tableConfig = {... state.config.tableConfig, columns: columns};

    return {... state, config: {...state.config, tableConfig: tableConfig}};
}
