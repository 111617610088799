export const getAvailableFoldersForReactSelect = (state) => {
    return state.folders && state.folders.folders.map(value => {
            return {
                value: value,
                label: value.labels.pl
            }
        }
    );
};
export const getAvailableDictionaryEntriesForReactSelect = (state, dictionaryId) => {
    return state.folders && state.folders[dictionaryId] != null && state.folders[dictionaryId].map(value => {
            return {
                value: value,
                label: value.labels && value.labels.pl ? value.labels.pl : value.id
            }
        }
    );
};