import * as actions from '../actions/importActions'

export const initialState =  {isLoading: false, isError: false, results: null };

export default function imports(state = initialState, action) {
    switch (action.type) {
        case actions.IMPORT_ASSETS_REQUESTED:
            return {...state, isLoading: true, isError: false, results: null};
        case actions.IMPORT_ASSETS_DONE:
            return {...state, isLoading: false, isError: false, results: action.payload};
        case actions.IMPORT_ASSETS_FAILED:
            return {...state, isLoading: false, isError: true, results: null};
        default:return state;
    }
}
