import React from 'react'
import {connect} from "react-redux";
import {setAttribute} from "../../actions/artworkActions";
import {Translation} from "react-i18next";
import getObjectAttr from "../../util/getObjectAttr";


const getDatePartFromState = (dateType, yearMonthDay) => {
    return dateType && dateType[yearMonthDay]
        ? dateType[yearMonthDay] : '';
};

const mapStateToProps = (state, ownProps) => {
    return {
        labelSize: "labelSize" in ownProps ? ownProps.labelSize : 2,
        year: getDatePartFromState(getObjectAttr(ownProps.path, state.artworks.artwork), "year"),
        month: getDatePartFromState(getObjectAttr(ownProps.path, state.artworks.artwork), "month"),
        day: getDatePartFromState(getObjectAttr(ownProps.path, state.artworks.artwork), "day"),
        enabled: ownProps.enabled,
        label: ownProps.label,
        path: ownProps.path,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAttr: (atttr, value) => dispatch(setAttribute(atttr, value)),
    }
};

class DateByPartsEditableField extends React.Component {

    setFromDate(yearMonthDay, value) {
        let date = {
            year: this.props.year,
            month: this.props.month,
            day: this.props.day,
        };

        date[yearMonthDay] = value;

        this.props.setAttr(this.props.path, date);
    }


    render() {

        return (
            <Translation>{t =>
                <div className={`row form-line`}>
                    <div className={`col-lg-${this.props.labelSize} form-label`}>{t(this.props.label)}</div>
                    <div className="col">
                        <input placeholder="Rok" type="number"
                               disabled={!this.props.enabled}
                               value={this.props.year}
                               onChange={(e) => this.setFromDate("year", e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <input placeholder="Miesiąc" type="number"
                               readOnly={this.props.entry}
                               disabled={!this.props.enabled}
                               value={this.props.month}
                               onChange={(e) => this.setFromDate("month", e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <input placeholder="Dzień" type="number"
                               disabled={!this.props.enabled}
                               value={this.props.day}
                               onChange={(e) => this.setFromDate("day", e.target.value)}
                        />
                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateByPartsEditableField)

