import {call, put} from 'redux-saga/effects';
import {getArtworkFromREST, updateMemberREST} from "../api/member.api";
import * as actions from "../actions/memberActions";
import {LOADING_START, LOADING_STOP} from "../loading/loadingDuck";
import {toast} from "react-toastify";
import {ARTWORK_DETAILS_GET_DATA_DONE, ARTWORK_DETAILS_GET_DATA_FAILED} from "../actions/artworkActions";

export function* getMember(action) {

    try {
        yield put({
            type: LOADING_START,
        });

        let {memberId, clone} = action.payload;

        let artwork = yield call(getArtworkFromREST, memberId);
        if (clone) {
            artwork.id = null;
        }

        yield put({
            type: ARTWORK_DETAILS_GET_DATA_DONE,
            payload: artwork
        });

        yield put({
            type: LOADING_STOP,
        });

    } catch (e) {
        yield put({
            type: ARTWORK_DETAILS_GET_DATA_FAILED,
            payload: action.payload
        });

        yield put({
            type: LOADING_STOP,
        });

    }
}

export function* updateMember(action) {

    try {
        yield put({
            type: LOADING_START,
        });

        const artwork = yield call(updateMemberREST, action.payload);

        yield put({
            type: LOADING_STOP,
        });

        yield put({
            type: actions.MEMBER_UPDATE_DONE,
            payload: artwork
        });

        toast.success("Zapisano zmiany w rekordzie: " + artwork.id);

        if (window.location.href.indexOf("member/edit/" + artwork.id) < 0) {
            window.location.href = "/member/edit/" + artwork.id
        }

    } catch (e) {

        yield put({
            type: LOADING_STOP,
        });

        yield put({
            type: actions.MEMBER_UPDATE_FAILED
        });

        toast.error("Wystąpił błąd podczas zapisu");

    }
}
