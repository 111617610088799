import {takeEvery, takeLatest} from "@redux-saga/core/effects";
import {
    doSetPageAndSearch,
    getDataForBrowse,
    navigateToNextItemSaga,
    navigateToNthItemSaga,
    navigateToPrevItemSaga,
    SetSortingSaga,
} from "./browse.saga";
import {
    callArtworkApi,
    getArtwork,
    getArtworkChildrenSaga,
    getArtworkParent,
    removeChild,
    updateArtwork,
    validateInventoryNumber,
    validateTitle
} from "./artwork.saga";
import {removeAsset, reorderAssets, uploadAsset} from "./asset.saga";
import {
    BROWSE_GET_DATA,
    BROWSE_NAVIGATE_TO_NEXT_ITEM,
    BROWSE_NAVIGATE_TO_NTH_ITEM,
    BROWSE_NAVIGATE_TO_PREV_ITEM,
    BROWSE_SET_PAGE_AND_SEARCH,
    BROWSE_SET_SORTING_AND_SEARCH
} from "../actions/browseActions";
import {
    ARTWORK_CHILDREN_LOAD,
    ARTWORK_DETAILS_LOAD,
    ARTWORK_PARENT_LOAD,
    ARTWORK_REMOVE_CHILDREN,
    ARTWORK_UPDATE,
    ARTWORK_VALIDATE_INVENTORY_NUMBER,
    ARTWORK_VALIDATE_TITLE,
    CALL_API_ARTWORK_ACTION
} from "../actions/artworkActions";
import {ASSET_REMOVE, ASSET_REORDER, ASSET_UPLOAD} from "../actions/assetActions";
import {DICTIONARY_ENTRY_OPEN_CREATE_MODAL} from "../actions/dictionaryEntriesActions";
import {createDictionaryEntryAndSetToCurrentArtwork} from "./dictionaryEntries.saga";
import {getFolders} from "./folders.saga";
import {FOLDERS_GET_DATA} from "../actions/foldersActions";
import {IMPORT_ASSETS} from "../actions/importActions";
import {importAssets} from "./import.saga";
import {ENGINE_PREPARE, ENGINE_RUN_ACTION} from "../engine/actions/engine.actions";
import {prepareConfig, runAction} from "../engine/sagas/engine.saga";
import {AUTH_LOGOUT} from "../auth/authActions";
import {logout} from "../auth/auth.saga";
import {EXPORT_RULES} from "../engine/actions/rulesExport.actions";
import {exportRules} from "../engine/sagas/rulesExport.saga";
import {PERSON_OPEN_CREATE_MODAL} from "../actions/personActions";
import {createPersonAndSetCurrentArtwork} from "./person.saga";
import {getTracking} from "../tracking/tracking.saga";
import {TRACK_START} from "../tracking/trackingDuck";
import {cropAsset} from "./crop.saga";
import {CROP_SAVE} from "../actions/cropActions";
import {
    GET_USER_COLUMNS,
    getColumnsSaga,
    RESTORE_DEFAULT_COLUMNS,
    restoreDefaultColumns,
    saveColumns,
    SET_COLUMNS_START
} from "../table/tableConfigDuck";
import {
    APPLY_FILTER_VALUE,
    applyColumnFilter,
    FILTER_LOAD_ALL_AVAILABLE_DICTIONARIES_START,
    FILTER_LOAD_ALL_AVAILABLE_OPTIONS_START,
    filterLoadAvailableDictionariesSaga,
    filterLoadAvailableOptions
} from "../actions/filtersActions";
import {TOGGLE_ALL_ITEMS_SELECTION, toggleAllItemsSelectionSaga} from "../table/selectionDuck";
import {
    BULK_ACTIONS_PREPARE_DATA_FOR_COMPONENT,
    BULK_ADD_REMOVE_FOLDER,
    BULK_ADD_REMOVE_NAVIGART,
    BULK_ADD_REMOVE_PROJECT,
    BULK_PRINT_TO_FILE,
    bulkActionPrepareDataForComponent,
    bulkAddRemoveFolderSaga,
    bulkAddRemoveProjectSaga,
    bulkAddRemoveToNavigartSaga,
    bulkPrintSaga
} from "../table/bulkActionsDuck";
import {
    deleteQuerySaga,
    getQueries,
    QUERIES_LOAD,
    QUERY_DELETE,
    QUERY_SAVE,
    QUERY_SELECT,
    saveQuerySaga,
    selectQuerySaga
} from "../query/queryDuck";
import {
    ACL_IDENTITIES_LOAD,
    ACL_IDENTITIES_SAVE,
    getAclIdentitiesSaga,
    getUserGroupSaga,
    getUserGroupsSaga,
    getUserSaga,
    getUsersSaga,
    saveUserGroupSaga,
    saveUserSaga,
    USER_GROUP_LOAD,
    USER_GROUPS_LOAD,
    USER_LOAD,
    USER_SAVE,
    USERS_LOAD
} from "../config/admin/security/usersDuck";
import {getNewsListSaga, getNewsSaga, NEWS_LIST_LOAD, NEWS_LOAD, NEWS_SAVE, saveNewsSaga} from "../news/newsDuck";
import {
    COMMON_DATA_LOAD_DICTIONARIES,
    COMMON_DATA_LOAD_DICTIONARY_ENTRIES,
    commonDataLoadDictionariesSaga,
    commonDataLoadDictionaryEntriesSaga
} from "../common/commonDuck";
import {
    CONTENT_LIST_LOAD,
    CONTENT_LOAD,
    CONTENT_SAVE,
    getContentListSaga,
    getContentSaga,
    saveContentSaga
} from "../content/contentDuck";
import {getPersonSaga, PERSON_LOAD, PERSON_SAVE, savePersonSaga} from "../components/person/personDuck";
import {
    DICTIONARY_ENTRY_LOAD,
    DICTIONARY_ENTRY_SAVE,
    getDictionaryEntrySaga,
    saveDictionaryEntrySaga
} from "../components/dictionary-entry/dictionaryEntryDuck";
import {MEMBER_DETAILS_LOAD, MEMBER_UPDATE} from "../actions/memberActions";
import {getMember, updateMember} from "./member.saga";

export default function* rootSaga() {
    yield takeLatest(BROWSE_GET_DATA, getDataForBrowse);
    yield takeLatest(BROWSE_SET_PAGE_AND_SEARCH, doSetPageAndSearch);
    yield takeLatest(BROWSE_NAVIGATE_TO_NEXT_ITEM, navigateToNextItemSaga);
    yield takeLatest(BROWSE_NAVIGATE_TO_PREV_ITEM, navigateToPrevItemSaga);
    yield takeLatest(BROWSE_NAVIGATE_TO_NTH_ITEM, navigateToNthItemSaga);
    yield takeLatest(BROWSE_SET_SORTING_AND_SEARCH, SetSortingSaga);
    yield takeLatest(ARTWORK_DETAILS_LOAD, getArtwork);
    yield takeLatest(ARTWORK_CHILDREN_LOAD, getArtworkChildrenSaga);
    yield takeLatest(ARTWORK_PARENT_LOAD, getArtworkParent);
    yield takeLatest(ARTWORK_UPDATE, updateArtwork);
    yield takeLatest(ARTWORK_REMOVE_CHILDREN, removeChild);
    yield takeLatest(ASSET_UPLOAD, uploadAsset);
    yield takeLatest(ASSET_REMOVE, removeAsset);
    yield takeLatest(ASSET_REORDER, reorderAssets);
    yield takeLatest(DICTIONARY_ENTRY_OPEN_CREATE_MODAL, createDictionaryEntryAndSetToCurrentArtwork);
    yield takeLatest(PERSON_OPEN_CREATE_MODAL, createPersonAndSetCurrentArtwork);
    yield takeLatest(FOLDERS_GET_DATA, getFolders);
    yield takeLatest(IMPORT_ASSETS, importAssets);
    yield takeLatest(ENGINE_PREPARE, prepareConfig);
    yield takeLatest(ENGINE_RUN_ACTION, runAction);
    yield takeLatest(AUTH_LOGOUT, logout);
    yield takeLatest(EXPORT_RULES, exportRules);
    yield takeLatest(CALL_API_ARTWORK_ACTION, callArtworkApi);
    yield takeLatest(ARTWORK_VALIDATE_INVENTORY_NUMBER, validateInventoryNumber);
    yield takeLatest(ARTWORK_VALIDATE_TITLE, validateTitle);
    yield takeLatest(TRACK_START, getTracking);
    yield takeLatest(CROP_SAVE, cropAsset);
    yield takeLatest(SET_COLUMNS_START, saveColumns);
    yield takeLatest(RESTORE_DEFAULT_COLUMNS, restoreDefaultColumns);
    yield takeLatest(GET_USER_COLUMNS, getColumnsSaga);
    yield takeLatest(APPLY_FILTER_VALUE, applyColumnFilter);
    yield takeEvery(FILTER_LOAD_ALL_AVAILABLE_OPTIONS_START, filterLoadAvailableOptions);
    yield takeEvery(FILTER_LOAD_ALL_AVAILABLE_DICTIONARIES_START, filterLoadAvailableDictionariesSaga);
    yield takeLatest(TOGGLE_ALL_ITEMS_SELECTION, toggleAllItemsSelectionSaga);
    yield takeLatest(BULK_ADD_REMOVE_NAVIGART, bulkAddRemoveToNavigartSaga);
    yield takeLatest(BULK_ADD_REMOVE_PROJECT, bulkAddRemoveProjectSaga);
    yield takeLatest(BULK_ADD_REMOVE_FOLDER, bulkAddRemoveFolderSaga);
    yield takeLatest(BULK_ACTIONS_PREPARE_DATA_FOR_COMPONENT, bulkActionPrepareDataForComponent);

    yield takeLatest(BULK_PRINT_TO_FILE, bulkPrintSaga);

    yield takeLatest(QUERY_SAVE, saveQuerySaga);
    yield takeLatest(QUERIES_LOAD, getQueries);
    yield takeLatest(QUERY_SELECT, selectQuerySaga);
    yield takeLatest(QUERY_DELETE, deleteQuerySaga);

    yield takeLatest(USERS_LOAD, getUsersSaga);
    yield takeLatest(USER_LOAD, getUserSaga);
    yield takeLatest(USER_SAVE, saveUserSaga);
    yield takeLatest(USER_GROUPS_LOAD, getUserGroupsSaga);
    yield takeLatest(USER_GROUP_LOAD, getUserGroupSaga);
    yield takeLatest(ACL_IDENTITIES_LOAD, getAclIdentitiesSaga);
    yield takeLatest(ACL_IDENTITIES_SAVE, saveUserGroupSaga);

    yield takeLatest(NEWS_LIST_LOAD, getNewsListSaga);
    yield takeLatest(NEWS_LOAD, getNewsSaga);
    yield takeLatest(NEWS_SAVE, saveNewsSaga);

    yield takeEvery(COMMON_DATA_LOAD_DICTIONARY_ENTRIES, commonDataLoadDictionaryEntriesSaga);
    yield takeEvery(COMMON_DATA_LOAD_DICTIONARIES, commonDataLoadDictionariesSaga);

    yield takeLatest(CONTENT_LIST_LOAD, getContentListSaga);
    yield takeLatest(CONTENT_LOAD, getContentSaga);
    yield takeLatest(CONTENT_SAVE, saveContentSaga);

    yield takeLatest(PERSON_LOAD, getPersonSaga);
    yield takeLatest(PERSON_SAVE, savePersonSaga);

    yield takeLatest(DICTIONARY_ENTRY_LOAD, getDictionaryEntrySaga);
    yield takeLatest(DICTIONARY_ENTRY_SAVE, saveDictionaryEntrySaga);

    yield takeLatest(MEMBER_DETAILS_LOAD, getMember);
    yield takeLatest(MEMBER_UPDATE, updateMember);

}