import * as actions from '../actions/cropActions'

const initialState = {
    asset: null,
    artworkId: null,
};

export default function crop(state = initialState, action) {
    switch (action.type) {
        case actions.CROP_OPEN:
            return {...state, asset: action.payload.asset, artworkId: action.payload.artworkId};
        case actions.CROP_CLOSE:
            return {...state, asset: null, artworkId: null};
        default:
            return state;
    }
}
