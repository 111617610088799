import React from 'react'
import {connect} from "react-redux";
import {setDatesFrom, setDatesTo} from "./dictionaryEntryDuck";
import {Translation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
        dictionaryEntry: state.dictionaryEntries.dictionaryEntry,
        dateFrom: state.dictionaryEntries.dictionaryEntry
        && state.dictionaryEntries.dictionaryEntry.data
        && state.dictionaryEntries.dictionaryEntry.data.date
        && state.dictionaryEntries.dictionaryEntry.data.date.from ? state.dictionaryEntries.dictionaryEntry.data.date.from : {},
        dateTo: state.dictionaryEntries.dictionaryEntry
        && state.dictionaryEntries.dictionaryEntry.data
        && state.dictionaryEntries.dictionaryEntry.data.date
        && state.dictionaryEntries.dictionaryEntry.data.date.to ? state.dictionaryEntries.dictionaryEntry.data.date.to : {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setDatesFrom: (date) => dispatch(setDatesFrom(date)),
        setDatesTo: (date) => dispatch(setDatesTo(date)),
    }
};

class DictionaryEntryEditDates extends React.Component {


    setFromDate(yearMonthDay, value) {
        let from = {...this.props.dateFrom}

        from[yearMonthDay] = value;

        this.props.setDatesFrom(from);
    }

    setToDate(yearMonthDay, value) {
        let to = {...this.props.dateTo}

        to[yearMonthDay] = value;

        this.props.setDatesTo(to);
    }

    handleChange = (inputValue, actionMeta) => {

        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setDictionary(inputValue.value);
            default:
                break;
        }

    };

    render() {
        if (this.props.dictionaryEntry.dictionary.id != "dates") {
            return null
        }

        return (
            <div className="component">
                <header className="component-header">
                    <Translation>{t =>
                        <span>{t("dates")}</span>
                    }</Translation>
                </header>
                <div className="message">

                </div>
                <div className="component-content content-padding">

                    <div className="row form-line">
                        <div className="col">
                            <input placeholder="Rok" type="number" name="dateFromYear"
                                   value={this.props.dateFrom.year}
                                   onChange={(e) => this.setFromDate("year", e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input placeholder="Miesiąc" type="number" name="dateFromMonth"
                                   value={this.props.dateFrom.month}
                                   onChange={(e) => this.setFromDate("month", e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input placeholder="Dzień" type="number" name="dateFromDay"
                                   value={this.props.dateFrom.day}
                                   onChange={(e) => this.setFromDate("day", e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row form-line">
                        <div className="col">
                            <input placeholder="Rok" type="number" name="dateToYear"
                                   value={this.props.dateTo.year}
                                   onChange={(e) => this.setToDate("year", e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input placeholder="Miesiąc" type="number" name="dateToMonth"
                                   value={this.props.dateTo.month}
                                   onChange={(e) => this.setToDate("month", e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input placeholder="Dzień" type="number" name="dateToDay"
                                   value={this.props.dateTo.day}
                                   onChange={(e) => this.setToDate("day", e.target.value)}
                            />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

function mapDictionaryEntry(entry) {
    return {
        label: entry.labels && entry.labels.pl ? entry.labels.pl : entry.id,
        value: entry
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntryEditDates)

