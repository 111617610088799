import React from 'react'
import {connect} from "react-redux";
import {applyFilterValue, editFilter, setCurrentValue} from "../../actions/filtersActions";
import {Translation} from "react-i18next";
import SelectDictionaryEntries from "./SelectDictionaryEntries";
import SelectPersons from "./SelectPersons";
import SelectFolderComponent from "./SelectFolderComponent";
import {getCurrentSearchFilterToEdit, getSearchFilterToEdit} from "../../reducers/filteringReducer";
import VisibleInNavigartFilterComponent from "./YesNoFilterComponent";
import SelectDictionaries from "./SelectDictionaries";

const mapStateToProps = (state) => {
    return {
        editFilter: getSearchFilterToEdit(state),
        currentFilterValue: getCurrentSearchFilterToEdit(state),
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        close: () => dispatch(editFilter(null)),
        setCurrentValue: (value) => dispatch(setCurrentValue(value)),
        applyFilterValue: (filterId, value) => dispatch(applyFilterValue(filterId, value, ownProps.browseType))
    }
};


class EditSearchFilter extends React.Component {

    render() {

        let realInput = null;


        if (this.props.editFilter.type === "string") {
            realInput = <input type="text" placeholder="Search" name="real-value-input"
                               className="regular-input"
                               value={this.props.currentFilterValue}
                               onChange={(e) => this.props.setCurrentValue(e.target.value)}/>
        } else if (this.props.editFilter.type === "number") {
            realInput = <input type="number" placeholder="Search" name="real-value-input"
                               className="regular-input"
                               value={this.props.currentFilterValue}
                               onChange={(e) => this.props.setCurrentValue(e.target.value)}/>
        } else if (this.props.editFilter.type === "dictionaryEntry") {
            realInput = <SelectDictionaryEntries dictionaryId={this.props.editFilter.dictionaryId}/>
        } else if (this.props.editFilter.type === "dictionary") {
            realInput = <SelectDictionaries/>
        } else if (this.props.editFilter.type === "person") {
            realInput = <SelectPersons/>
        } else if (this.props.editFilter.type === "folder") {
            realInput = <SelectFolderComponent/>
        } else if (this.props.editFilter.type === "visibleInNavigart") {
            realInput = <VisibleInNavigartFilterComponent label="visibleInNavigart"/>
        } else if (this.props.editFilter.type === "requiresExport") {
            realInput = <VisibleInNavigartFilterComponent label="requiresExport"/>
        } else if (this.props.editFilter.type === "hasPreviews") {
            realInput = <VisibleInNavigartFilterComponent label="hasPreviews"/>
        }


        return (
            <Translation>{t =>
                <div className="modal overlay show" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true" style={{"display": "block"}}>
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t(this.props.editFilter.label)}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.props.close()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body fixed-size-modal-body">
                                {realInput}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-id="close-columns-editor"
                                        onClick={() => this.props.close()}>Anuluj
                                </button>
                                <button type="button" className="btn btn-primary" data-id="save-columns"
                                        onClick={event => this.props.applyFilterValue(this.props.editFilter.id, this.props.currentFilterValue)}>Zastosuj
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSearchFilter)

