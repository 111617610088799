// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../../../config";
import {getBaseRequestConfig} from "../../../api/baseRequestConfig";
import asyncFetch from "../../../api/async-fetch";


export const USER_LOAD = "USER_LOAD"
export const USER_SET = "USER_SET"
export const USERS_LOAD = "USERS_LOAD"
export const USERS_SET = "USERS_SET"
export const USER_SAVE = "USER_SAVE"

export const USER_GROUPS_LOAD = "USER_GROUPS_LOAD"
export const USER_GROUPS_SET = "USER_GROUPS_SET"
export const ADD_REMOVE_USER_GROUP_FROM_USER = "ADD_REMOVE_USER_GROUP_FROM_USER"

export const USER_GROUP_LOAD = "USER_GROUP_LOAD"
export const USER_GROUP_SET = "USER_GROUP_SET"

export const ACL_IDENTITIES_LOAD = "ACL_IDENTITIES_LOAD"
export const ACL_IDENTITIES_SET = "ACL_IDENTITIES_SET"
export const ACL_IDENTITIES_SAVE = "ACL_IDENTITIES_SAVE"


export const ACL_TOGGLE_PRIV = "ACL_TOGGLE_PRIV"

let initialState = {
    users: [],
    user: null,
    userGroups: [],
    userGroup: null,
    aclIdentities: null
}
// Reducer
export default function usersReducer(state = initialState, action = {}) {
    switch (action.type) {
        case USERS_SET:
            return {...state, users: action.payload}
        case USER_SET:
            return {...state, user: action.payload}
        case USER_GROUPS_SET:
            return {...state, userGroups: action.payload}
        case ADD_REMOVE_USER_GROUP_FROM_USER:
            return addRemoveUserGroupReducer(state, action.payload)
        case USER_GROUP_SET:
            return {...state, userGroup: action.payload}

        case ACL_TOGGLE_PRIV:
            return toggleAcl(state, action.payload)

        case ACL_IDENTITIES_SET:
            return {...state, aclIdentities: action.payload}
        default:
            return state
    }
}


function toggleAcl(state, payload) {
    let {type, identity, priv} = payload

    let rolePermissions = {...state.userGroup.rolePermissions};
    let typePersimission = {...state.userGroup.rolePermissions[type]};


    let identityPersmissions = []
    if (typePersimission[identity]) {
        identityPersmissions = [...typePersimission[identity]]
    }

    if (identityPersmissions.find(p => p == priv) != undefined) {
        identityPersmissions = identityPersmissions.filter(p => p != priv)
    } else {
        identityPersmissions.push(priv)
    }

    typePersimission[identity] = identityPersmissions
    rolePermissions[type] = typePersimission
    let userGroup = {...state.userGroup, rolePermissions: rolePermissions}


    return {...state, userGroup: userGroup}
}

function addRemoveUserGroupReducer(state, payload) {
    let userGroups = [...state.user.userGroups]

    if (!payload.present) {
        userGroups = userGroups.filter(ug => ug.id != payload.userGroup.id)
    } else {
        userGroups.push(payload.userGroup)
    }

    return {...state, user: {...state.user, userGroups: userGroups}}
}

export const getUsers = () => ({
    type: USERS_LOAD,
})

export const loadUser = (id) => ({
    type: USER_LOAD,
    payload: {id}
})

export const saveUser = (user) => ({
    type: USER_SAVE,
})
export const addRemoveUserGroup = (userGroup, present) => ({
    type: ADD_REMOVE_USER_GROUP_FROM_USER,
    payload: {userGroup, present}
})
export const getUserGroups = () => ({
    type: USER_GROUPS_LOAD,
})

export const getUserGroup = (id) => ({
    type: USER_GROUP_LOAD,
    payload: {id}
})

export const getAclIdentities = () => ({
    type: ACL_IDENTITIES_LOAD
})

export const saveUserGroup = () => ({
    type: ACL_IDENTITIES_SAVE
})

export const toggleAclPriv = (type, identity, priv) => ({
    type: ACL_TOGGLE_PRIV,
    payload: {type, identity, priv}
})


//
// export function* saveUsersSaga(action) {
//     try {
//
//         let {asNew} = action.payload
//         let selectedQuery = yield select(state => getSelectedQuery(state))
//         let filterValues = yield select(state => getFilterValues(state))
//
//         let query = {
//             id: asNew ? null : selectedQuery.id,
//             name: selectedQuery.name,
//             tableId: "artworks",
//             data: JSON.stringify(filterValues)
//         }
//
//         let saved = yield call(saveQueryREST, query);
//         toast.success("Zapisano zmiany");
//
//         yield put({
//             type: QUERY_SET_SELECTED,
//             payload: saved
//         });
//
//         yield put({
//             type: QUERY_CLOSE
//         });
//
//         yield put({
//             type: USERS_LOAD
//         });
//
//
//     } catch (e) {
//         toast.error("Wystąpił błąd podczas zapisu");
//     } finally {
//
//     }
// }
//
// export function* deleteQuerySaga(action) {
//     try {
//         let {queryId} = action.payload
//
//         yield call(deleteQuery, queryId);
//
//         yield put({
//             type: USERS_LOAD
//         });
//
//         toast.success("Usunięto kwerendę");
//     } catch (e) {
//         toast.error("Wystąpił błąd usuwania kwerendy");
//     } finally {
//
//     }
// }

export function* getUsersSaga() {
    try {
        let users = yield call(getUsersREST);

        let filtered = users.filter(u => u.roles && u.roles.includes("ROLE_USER"));


        yield put({
            type: USERS_SET,
            payload: filtered
        });
    } catch (e) {
        console.log(e)
        toast.error("Wystąpił błąd podczas ładowania listy użytkowników");
    } finally {

    }
}


export function* getUserSaga(action) {
    try {
        let user = yield call(getUserREST, action.payload.id);

        yield put({
            type: USER_SET,
            payload: user
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania uzytkownika");
    } finally {

    }
}


export function* saveUserSaga(action) {
    try {

        let user = yield select(state => state.users.user)
        user = yield call(saveUserREST, user);

        yield put({
            type: USER_SET,
            payload: user
        });

        toast.success("Zapisano zmiany");
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisywania uzytkownika");
    } finally {

    }
}


export function* saveUserGroupSaga(action) {
    try {

        let userGroup = yield select(state => state.users.userGroup)
        userGroup = yield call(saveUserGroupREST, userGroup);
        //
        // yield put({
        //     type: USER_GROUP_SET,
        //     payload: userGroup
        // });

        toast.success("Zapisano zmiany");
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisywania uzytkownika");
    } finally {

    }
}


export function* getUserGroupsSaga() {
    try {
        let userGroups = yield call(getUserGroupsREST);


        yield put({
            type: USER_GROUPS_SET,
            payload: userGroups
        });
    } catch (e) {
        console.log(e)
        toast.error("Wystąpił błąd podczas ładowania listy użytkowników");
    } finally {

    }
}


export function* getUserGroupSaga(action) {
    try {
        let userGroup = yield call(getUserGroupREST, action.payload.id);

        yield put({
            type: USER_GROUP_SET,
            payload: userGroup
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania uzytkownika");
    } finally {

    }
}

export function* getAclIdentitiesSaga(action) {
    try {
        let userGroup = yield call(getAclIdentitiesREST);

        yield put({
            type: ACL_IDENTITIES_SET,
            payload: userGroup
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania danych");
    } finally {

    }
}


export async function saveUserREST(user) {

    const url = `${BACKEND_URL}user/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(user)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function saveUserGroupREST(user) {

    const url = `${BACKEND_URL}user-group/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(user)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function getUsersREST() {

    const url = `${BACKEND_URL}user/all`;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}


export async function getUserREST(id) {

    const url = `${BACKEND_URL}user/` + id;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}


export async function getUserGroupsREST() {

    const url = `${BACKEND_URL}user-group/all`;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}


export async function getUserGroupREST(id) {

    const url = `${BACKEND_URL}user-group/` + id;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}


export async function getAclIdentitiesREST() {

    const url = `${BACKEND_URL}user-group/acl-object-identities`;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}


export const isSaveQueryModalOpened = (state) => state.queries.opened
export const getSelectedQuery = (state) => state.queries.selectedQuery
export const selectQueries = (state) => state.queries.queries
export const selectSearch = (state) => state.queries.search