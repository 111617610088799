import React from 'react'
import {connect} from "react-redux";
import {setCurrentValue} from "../../actions/filtersActions";
import {getFolders} from "../../actions/foldersActions";
import Select from "react-select";
import {getAvailableFoldersForReactSelect} from "../../selectors/availableFoldersForReactSelect.selector";
import {getCurrentSearchFilterToEdit} from "../../reducers/filteringReducer";

const mapStateToProps = (state) => {
    return {
        currentFilterValue: getCurrentSearchFilterToEdit(state),
        availableFolders: getAvailableFoldersForReactSelect(state)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getFolders: () => dispatch(getFolders()),
        setCurrentValue: (value) => dispatch(setCurrentValue(value)),
    }
};

class SelectFolderComponent extends React.Component {

    componentDidMount() {
        this.props.getFolders();
    }

    handleChange = (inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setCurrentValue(inputValue);
            default:
                break;
        }

    };

    render() {
        return (
            <Select
                value={this.props.currentFilterValue}
                onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                isMulti={true}
                defaultOptions
                options={this.props.availableFolders}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFolderComponent)

