// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";
import asyncFetch from "../api/async-fetch";


let emptyContent = {
    title: {},
    content: {},
    datePublished: {
        from: {}
    }
};

let initialState = {
    list: [],
    content: null,

}

export const CONTENT_LIST_LOAD = "CONTENT_LIST_LOAD"
export const CONTENT_LIST_SET = "CONTENT_LIST_SET"
export const CONTENT_LOAD = "CONTENT_LOAD"
export const CONTENT_SET = "CONTENT_SET"
export const CONTENT_SAVE = "CONTENT_SAVE"
export const CONTENT_SET_LANGUAGE_VERSION = "CONTENT_SET_LANGUAGE_VERSION"
export const CONTENT_SET_TITLE = "CONTENT_SET_TITLE"
export const CONTENT_SET_CONTENT = "CONTENT_SET_CONTENT"
export const CONTENT_SET_PUBLICATION_DATE = "CONTENT_SET_PUBLICATION_DATE"


export const getContentList = () => ({
    type: CONTENT_LIST_LOAD,
})

export const setLangVersion = (dictionaryEntry) => ({
    type: CONTENT_SET_LANGUAGE_VERSION,
    payload: dictionaryEntry
})

export const getContent = (id) => ({
    type: CONTENT_LOAD,
    payload: {id}
})

export const setTitle = (lang, value) => ({
    type: CONTENT_SET_TITLE,
    payload: {lang, value}
})

export const setContent = (lang, value) => ({
    type: CONTENT_SET_CONTENT,
    payload: {lang, value}
})


export const saveContent = () => ({
    type: CONTENT_SAVE,
})

export const setPublicationDate = (date) => ({
    type: CONTENT_SET_PUBLICATION_DATE,
    payload: date
})


// Reducer
export default function contentReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CONTENT_SET:
            return {...state, content: action.payload}
        case CONTENT_LIST_SET:
            return {...state, list: action.payload}
        case CONTENT_SET_LANGUAGE_VERSION:
            return reduceSetLanguageVersion(state, action.payload)
        case CONTENT_SET_TITLE:
            return reduceSetTitle(state, action.payload)
        case CONTENT_SET_CONTENT:
            return reduceSetContent(state, action.payload)
        case CONTENT_SET_PUBLICATION_DATE:
            return reduceSetPublicationDate(state, action.payload)
        default:
            return state
    }
}

function reduceSetLanguageVersion(state, payload) {
    let content = {...state.content, _langVersion: payload}

    return {...state, content: content}
}

function reduceSetTitle(state, payload) {
    let content = {...state.content}
    content.title[payload.lang] = payload.value

    return {...state, content: content}
}

function reduceSetContent(state, payload) {
    let content = {...state.content}
    content.content[payload.lang] = payload.value

    return {...state, content: content}
}

function reduceSetPublicationDate(state, payload) {
    let datePublished = {
        from: payload
    }

    let content = {...state.content, datePublished: datePublished}

    return {...state, content: content}
}

export function* getContentListSaga() {
    try {
        let content = yield call(getContentListREST);

        yield put({
            type: CONTENT_LIST_SET,
            payload: content
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania listy");
    } finally {

    }
}

export function* getContentSaga(action) {
    try {

        let {id} = action.payload

        let content = null;

        if (id) {
            content = yield call(getContentREST, action.payload.id);
        } else {
            content = {...emptyContent}
        }

        yield put({
            type: CONTENT_SET,
            payload: content
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania danych");
    } finally {

    }
}

export function* saveContentSaga(action) {
    try {

        let content = yield select(state => state.content.content)

        let redirect = !content.id

        content = yield call(saveContentREST, content);

        yield put({
            type: CONTENT_SET,
            payload: content
        });

        if (redirect) {
            window.location.href = "/content/edit/" + content.id;
        }

        toast.success("Zapisano zmiany");
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisywania rekordu");
    } finally {

    }
}

export async function saveContentREST(content) {

    const url = `${BACKEND_URL}content/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(content)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function getContentListREST() {

    const url = `${BACKEND_URL}content/`;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}

export async function getContentREST(id) {

    const url = `${BACKEND_URL}content/` + id;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}
