import {call, put} from 'redux-saga/effects';
import * as actions from "../actions/personActions";
import {createPersonREST} from "../api/person.api";

export function* createPersonAndSetCurrentArtwork(action) {

    try {
        yield put({
            type: actions.PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_REQUESTED,
        });

        let {name, nextAction} = action.payload;

        let person = yield call(createPersonREST, name);

        yield nextAction(person);

    } catch (e) {
        yield put({
            type: actions.PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_FAILED
        });
    }
}
