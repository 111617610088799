import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";

export async function fetchFoldersREST() {

    const url = BACKEND_URL + 'folders/';

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
