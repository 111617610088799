import asyncFetch from "../api/async-fetch";
import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";

export async function getLatestUsersActivity() {

    const url = BACKEND_URL + 'users-activity/latest';

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
