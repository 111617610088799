import React from 'react'
import {editFilter, setFilteringParam} from "../../actions/filtersActions";
import EditSearchFilter from "./EditSearchFilter";
import PickUpFilter from "./PickUpFilter";
import {getFilterValues, getSearchFilterToEdit, isPickUpFilterWindowOpened} from "../../reducers/filteringReducer";
import FilterBadge from "./FilterBadge";
import {Translation} from "react-i18next";
import SaveFiltersModal from "./SaveFiltersModal";
import {isSaveQueryModalOpened, querySelect, saveQueryOpenModal} from "../../query/queryDuck";
import connect from "react-redux/lib/connect/connect";
import {setPageAndSearch} from "../../actions/browseActions";

const mapStateToProps = (state) => {
    return {
        filters: getFilterValues(state),
        filterToEditFilter: getSearchFilterToEdit(state),
        pickUpFilterOpened: isPickUpFilterWindowOpened(state),
        isSaveQueryModalOpened: isSaveQueryModalOpened(state),
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        unsetFilteringParam: (id) => {
            dispatch(setFilteringParam(id, null))
            dispatch(setPageAndSearch(0, ownProps.browseType))
        },
        editFilter: (config) => dispatch(editFilter(config)),
        saveQuery: () => dispatch(saveQueryOpenModal()),
        cleanFilters: () => dispatch(querySelect(null, ownProps.browseType)),
    }
};

class SearchArtworksComponent extends React.Component {

    render() {

        let activeFiltersSummary = this.props.filtersAvailable
            .filter(filter => this.props.filters[filter.id])
            .map(filter => <FilterBadge filterValue={this.props.filters[filter.id]} filter={filter}/>)

        return (
            <Translation>{t =>
                <React.Fragment>
                    {activeFiltersSummary.length > 0 && <div className="row form-line">
                        <div className="col labelsbox">
                            <header>{t("appliedFilters")}</header>

                            {activeFiltersSummary}

                            <button type="button" className="btn btn-outline-primary btn-sm"
                                    onClick={() => this.props.saveQuery()}>{t("save")}
                            </button>

                            <button type="button" className="btn btn-outline-primary btn-sm"
                                    onClick={() => this.props.cleanFilters()}>{t("clear")}
                            </button>
                        </div>
                    </div>}

                    {this.props.filterToEditFilter && <EditSearchFilter browseType={this.props.browseType}/>}
                    {this.props.pickUpFilterOpened && (<PickUpFilter/>)}
                    {this.props.isSaveQueryModalOpened && <SaveFiltersModal browseType={this.props.browseType}/>}
                </React.Fragment>
            }</Translation>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SearchArtworksComponent)

