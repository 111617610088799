import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";

export async function importAssetsFromDiskREST(dryRun) {

    const url = BACKEND_URL + 'import/' + dryRun;

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function searchImageImportResultsFromREST(request) {

    const url = BACKEND_URL + 'import/search';

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
