import {select} from "@redux-saga/core/effects";
import {call, put} from 'redux-saga/effects';
import * as actions from "../actions/browseActions";
import {navigateToNthItem, setPageAndSearch} from "../actions/browseActions";
import {searchArtworksFromREST} from "../api/browse.api";
import {getSearchParams} from "../selectors/searchParams.selector";
import {ARTWORKS, DICTIONARY_ENTRIES, IMAGE_IMPORT_RESULTS, MEMBERS, PERSONS} from "../components/browse/BrowseType";
import {searchDictionaryEntriesFromREST} from "../api/dictionaryEntry.api";
import {searchPersonsFromREST} from "../api/person.api";
import {artworkFilterAvailable} from "../components/browse/filtersConfig";
import {personFiltersAvailable} from "../components/person/personFiltersConfig";
import {dictionaryEntriesFiltersAvailable} from "../components/dictionary-entry/dictionaryEntriesFiltersConfig";
import {searchImageImportResultsFromREST} from "../api/import.api";
import {searchMembersFromREST} from "../members/members.api";
import {membersFilterAvailable} from "../members/components/memberFiltersConfig";

export function* getDataForBrowse(action) {

    try {
        yield put({
            type: actions.BROWSE_GET_DATA_REQUESTED
        });

        let searchParams = null;
        let responseBody = null;

        if (action.payload.browseType == ARTWORKS) {
            searchParams = yield select(getSearchParams, action.payload.browseType, artworkFilterAvailable);

            responseBody = yield call(searchArtworksFromREST, searchParams);
        }
        else if (action.payload.browseType == DICTIONARY_ENTRIES) {
            searchParams = yield select(getSearchParams, action.payload.browseType, dictionaryEntriesFiltersAvailable);

            responseBody = yield call(searchDictionaryEntriesFromREST, searchParams);
        } else if (action.payload.browseType == PERSONS) {
            searchParams = yield select(getSearchParams, action.payload.browseType, personFiltersAvailable);

            responseBody = yield call(searchPersonsFromREST, searchParams);
        } else if (action.payload.browseType == IMAGE_IMPORT_RESULTS) {
            searchParams = yield select(getSearchParams, action.payload.browseType, []);

            responseBody = yield call(searchImageImportResultsFromREST, searchParams);
        } else if (action.payload.browseType == MEMBERS) {
            searchParams = yield select(getSearchParams, action.payload.browseType, membersFilterAvailable);

            responseBody = yield call(searchMembersFromREST, searchParams);
        }


        yield put({
            type: actions.BROWSE_GET_DATA_DONE,
            payload: {
                response: responseBody,
                browseType: action.payload.browseType
            }
        });

        if (action.payload.nextAction) {
            yield put(action.payload.nextAction);
        }

    } catch (e) {

        yield put({
            type: actions.BROWSE_GET_DATA_FAILED,
            payload: {
                exception: e,
                browseType: action.payload.browseType
            }
        });

    }
}


export function *doSetPageAndSearch(action) {
    yield put({
        type: actions.BROWSE_SET_PAGE,
        payload: action.payload
    });

    yield put({
        type: actions.BROWSE_GET_DATA,
        payload: {browseType: action.payload.browseType}
    });
}

export function* navigateToNextItemSaga(action) {
    let {recordId, browseType, history} = action.payload

    const content = yield select((state) => {
        return state.browse[browseType].results.content
    }, browseType);

    let pos = content.findIndex(el => el.id == recordId)

    if (pos < 0) {
        return
    }

    const pageSize = yield select((state) => {
        return state.browse[browseType].results.size
    }, browseType);

    if (pos < pageSize - 1) {
        yield put(navigateToNthItem(++pos, browseType, history));
    } else {
        const pageNumber = yield select((state) => {
            return state.browse[browseType].results.number
        }, browseType);

        let page = pageNumber + 1

        //go to next page, and fetch first element
        yield put({
            type: actions.BROWSE_SET_PAGE,
            payload: {page, browseType}
        });

        yield put({
            type: actions.BROWSE_GET_DATA,
            payload: {browseType, nextAction: navigateToNthItem(0, browseType, history)}
        });
    }
}


export function* navigateToPrevItemSaga(action) {
    let {recordId, browseType, history} = action.payload

    const content = yield select((state) => {
        return state.browse[browseType].results.content
    }, browseType);

    let pos = content.findIndex(el => el.id == recordId)

    if (pos < 0) {
        return
    }

    if (pos > 0) {
        yield put(navigateToNthItem(--pos, browseType, history));
    } else {
        const pageNumber = yield select((state) => {
            return state.browse[browseType].results.number
        }, browseType);

        let page = pageNumber - 1

        //go to next page, and fetch first element
        yield put({
            type: actions.BROWSE_SET_PAGE,
            payload: {page, browseType}
        });

        const pageSize = yield select((state) => {
            return state.browse[browseType].results.size
        }, browseType);

        yield put({
            type: actions.BROWSE_GET_DATA,
            payload: {browseType, nextAction: navigateToNthItem(pageSize - 1, browseType, history)}
        });
    }
}


export function* navigateToNthItemSaga(action) {
    let {idx, history, browseType} = action.payload

    const content = yield select((state) => {
        return state.browse[browseType].results.content
    }, browseType);

    const urlPart = getUrlPartForType(browseType)
    let artworkId = content[idx].id
    history.push("/" + urlPart + "/edit/" + artworkId);
}

function getUrlPartForType(type) {
    if (type == MEMBERS) {
        return "member"
    }

    return "artwork"
}


export function* SetSortingSaga(action) {
    yield put({
        type: actions.BROWSE_SET_SORTING,
        payload: action.payload
    });

    yield put(setPageAndSearch(0, action.payload.browseType));
}
