import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from "./baseRequestConfig";
import {BACKEND_URL} from "../config";
import {MEMBERS} from "../components/browse/BrowseType";

export async function uploadAssetREST(artworkId, file, referenceType) {

    const url = BACKEND_URL + 'upload/' + artworkId + '/' + referenceType;

    const baseRequestConfig = getBaseRequestConfig("POST");
    delete baseRequestConfig.headers["Content-Type"];


    let data = new FormData();
    data.append('file', file);

    const requestConfig = {...baseRequestConfig, body: data};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function removeAssetREST(assetId, artworkId, referenceType) {

    const url = `${BACKEND_URL}asset/${assetId}/${artworkId}/${referenceType}/`;

    const baseRequestConfig = getBaseRequestConfig("DELETE");
    // delete baseRequestConfig.headers["Content-Type"];


    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}


export async function setAssetsOrderREST(artworkId, orderedAssetIds, referenceType) {
    let typePath = 'artwork/'

    if (referenceType == MEMBERS) {
        typePath = 'member/'
    }

    const url = BACKEND_URL + typePath + artworkId + '/order-assets';

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(orderedAssetIds)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
