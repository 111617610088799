import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {filterToggle, loadAvailableDictionaries, setFilteringParam} from "../../actions/filtersActions";
import {getFilterAvailableOptions, getFilterIsExpanded, getFilterValue} from "../../reducers/filteringReducer";
import {getLabel, toSelectizeDictionary} from "../../util/dictionaryEntryUtils";
import {setPageAndSearch} from "../../actions/browseActions";

const mapStateToProps = (state, ownProps) => {
    return {
        options: getFilterAvailableOptions(state, "dictionaries"),
        selected: getFilterValue(state, ownProps.filterName),
        isExpanded: getFilterIsExpanded(state, ownProps.filterName),
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        prepareDataForComponent: () => dispatch(loadAvailableDictionaries()),
        setFilteringParam: (dictionaryId, values) => {
            dispatch(setFilteringParam("dictionary", values))
            dispatch(setPageAndSearch(0, ownProps.browseType))
        },
        filterToggle: () => dispatch(filterToggle(ownProps.filterName))
    }
};


class DictionariesSubSearchSide extends React.Component {

    componentDidMount() {
        this.props.prepareDataForComponent();
    }

    setFilter(option) {
        let newOptions = []

        if (isSelected(this.props.selected, option.id)) {
            newOptions = this.props.selected.filter(sel => sel.dictionary.id !== option.id)

            if (newOptions.length == 0) {
                newOptions = null
            }
        } else {
            newOptions = [...this.props.selected ? this.props.selected : [], toSelectizeDictionary(option)]
        }

        this.props.setFilteringParam(this.props.filterName, newOptions)
    }

    render() {

        let truncateOptions = this.props.options.length > this.props.visibleItems
        let options = this.props.options;


        if (truncateOptions && !this.props.isExpanded) {
            options = options.slice(0, this.props.visibleItems);
        }

        let optionsList = options.map(option => {
            return (
                <dd onClick={() => this.setFilter(option)}>
                    <input type="checkbox" checked={isSelected(this.props.selected, option.id)}/>
                    <span>{getLabel(option)}</span>
                </dd>
            )
        });

        return (
            <div className="filter">
                <Translation>{t =>
                    <h4>{t("dictionary")}</h4>
                }</Translation>

                <dl>
                    {optionsList}
                    <dd className="font-weight-bold">
                        {truncateOptions && !this.props.isExpanded &&
                        <b onClick={() => this.props.filterToggle()}>Pokaż więcej &raquo;</b>}
                        {truncateOptions && this.props.isExpanded &&
                        <b onClick={() => this.props.filterToggle()}>Pokaż mniej &laquo;</b>}
                    </dd>
                </dl>
            </div>
        )
    }
}

function isSelected(options, dictionaryId) {
    return options && options.filter(option => option.dictionary.id == dictionaryId).length > 0
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionariesSubSearchSide)

