// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";


let emptyPerson = {
    name: "",
    description: "",
};

let initialState = {
    person: null,

}

export const PERSON_LOAD = "PERSON_LOAD"
export const PERSON_SET = "PERSON_SET"
export const PERSON_SAVE = "PERSON_SAVE"
export const PERSON_SET_NAME = "PERSON_SET_NAME"
export const PERSON_SET_DESCRIPTION = "PERSON_SET_DESCRIPTION"


export const getPerson = (id) => ({
    type: PERSON_LOAD,
    payload: {id}
})

export const setName = (value) => ({
    type: PERSON_SET_NAME,
    payload: value
})

export const setDescription = (value) => ({
    type: PERSON_SET_DESCRIPTION,
    payload: value
})


export const savePerson = () => ({
    type: PERSON_SAVE,
})


// Reducer
export default function personsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case PERSON_SET:
            return {...state, person: action.payload}
        case PERSON_SET_NAME:
            return reduceSetName(state, action.payload)
        case PERSON_SET_DESCRIPTION:
            return reduceSetDescription(state, action.payload)
        default:
            return state
    }
}

function reduceSetName(state, payload) {
    let person = {...state.person, name: payload}
    return {...state, person: person}
}

function reduceSetDescription(state, payload) {
    let person = {...state.person, description: payload}
    return {...state, person: person}
}

export function* getPersonSaga(action) {
    try {

        let {id} = action.payload

        let news = null;

        if (id) {
            news = yield call(getPersonREST, action.payload.id);
        } else {
            news = {...emptyPerson}
        }

        yield put({
            type: PERSON_SET,
            payload: news
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania danych");
    } finally {

    }
}

export function* savePersonSaga(action) {
    try {

        let news = yield select(state => state.persons.person)

        let redirect = !news.id

        news = yield call(savePersonREST, news);

        yield put({
            type: PERSON_SET,
            payload: news
        });

        if (redirect) {
            window.location.href = "/person/edit/" + news.id;
        }

        toast.success("Zapisano zmiany");
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisywania rekordu");
    } finally {

    }
}

export async function savePersonREST(news) {

    const url = `${BACKEND_URL}persons/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(news)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function getPersonREST(id) {

    const url = `${BACKEND_URL}persons/` + id;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}
