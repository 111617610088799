import React from 'react'
import {connect} from "react-redux";
import getObjectAttr from "../../util/getObjectAttr";
import AsyncCreatableSelect from "react-select/async-creatable";
import {getDictionaryEntriesByLabel} from "../../api/dictionaryEntry.api";
import {ARTWORK_ADD_ATTRIBUTE, setAttribute} from "../../actions/artworkActions";
import {viewToDbMapper} from "./OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import ToggleEditable from "./ToggleEditable";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";


const mapStateToProps = (state, ownProps) => {
    let list = getObjectAttr(ownProps.path, state.artworks.artwork);
    return {
        enabled: selectFieldEditEnabled(state, ownProps.path),
        field: Array.isArray(list) ? list.map((dictionaryEntry) => {
            return toSelectizeDictionaryEntry(dictionaryEntry)
        }) : []
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAttribute: (attribute, value) => dispatch(setAttribute(attribute, value)),
        openCreateDictionaryEntryModal: (dictionaryType, label) => dispatch(openCreateDictionaryEntryModal(dictionaryType, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE,
            payload: {
                attributeToSet: dictionaryType,
            }
        })),
    }
};


class SelectizeEditableField extends React.Component {

    handleChange = (inputValue, actionMeta) => {

        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length - 1].label;
                this.props.openCreateDictionaryEntryModal(this.props.path, label);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setAttribute(this.props.path, viewToDbMapper(inputValue));
            default:
                break;
        }

    };

    promiseOptions = inputValue => {
        let dictionaryId = this.props.path;
        if (this.props.dictionaryId) {
            dictionaryId = this.props.dictionaryId
        }
        return getDictionaryEntriesByLabel(dictionaryId, inputValue);
    };

    render() {

        let props = null
        if (this.props.hideSelectArrow) {
            props = {
                components: {DropdownIndicator: () => null, IndicatorSeparator: () => null}
            }
        }

        return (
            <div className="component">
                <header className="component-header">
                    <Translation>{t =>
                        <span>{t(this.props.path)}</span>
                    }</Translation>
                    <ToggleEditable fieldId={this.props.path}/>
                </header>
                <div className="message">

                </div>
                <div className="component-content">

                    <AsyncCreatableSelect
                        {...props}
                        formatOptionLabel={function (data) {
                            return (
                                <span dangerouslySetInnerHTML={{__html: data.label}}/>
                            );
                        }}
                        placeholder={""}
                        value={this.props.field}
                        onChange={this.handleChange}
                        isMulti={true}
                        isDisabled={!this.props.enabled}
                        defaultOptions
                        loadOptions={this.promiseOptions}
                    />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectizeEditableField)

