import React from 'react'
import {connect} from "react-redux";
import {setCurrentValue} from "../../actions/filtersActions";
import AsyncSelect from 'react-select/async';
import {getCurrentSearchFilterToEdit} from "../../reducers/filteringReducer";
import {fetchDictionaries} from "../../api/dictionary.api";

const mapStateToProps = (state, ownProps) => {
    return {
        currentFilterValue: getCurrentSearchFilterToEdit(state),
        // available: getAvailableDictionaryEntriesForReactSelect(state, ownProps.dictionaryId)
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setCurrentValue: (value) => dispatch(setCurrentValue(value)),
    }
};

class SelectDictionaries extends React.Component {

    componentDidMount() {
    }

    handleChange = (inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setCurrentValue(inputValue);
            default:
                break;
        }

    };

    render() {
        return (
            <AsyncSelect
                value={this.props.currentFilterValue}
                onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                isMulti={true}
                cacheOptions
                defaultOptions={true}
                loadOptions={(inputValue) => fetchDictionaries()}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDictionaries)

