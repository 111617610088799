import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';

export async function getREST(url) {

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await isJson(response) ? response.json() : response;
}

export async function postREST(url, data) {

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(data)};

    const response = await asyncFetch(url, requestConfig);

    return await isJson(response) ? response.json() : response;
}

const isJson = (response) => {
    let ct = response.headers.get("content-type");
    return ct && ct.includes('application/json');
};
