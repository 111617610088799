import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";
import AwesomeDebouncePromise from "awesome-debounce-promise";

export async function getArtworkFromREST(artworkId) {

    const url = BACKEND_URL + 'artwork/'+artworkId;
    const requestConfig = getBaseRequestConfig();


    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function getArtworkChildrenFromREST(artworkId) {

    const url = BACKEND_URL + 'artwork/'+artworkId+"/children";
    const requestConfig = getBaseRequestConfig();


    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function updateArtworkREST(artwork) {

    let url = BACKEND_URL + 'artwork/';
    if (artwork.id) {
        url += "update/"+artwork.id;
    } else {
        url += "create"
    }

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(artwork)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function removeChildREST(parentId, childId) {
    let url = BACKEND_URL + 'artwork/' + parentId + '/remove-child/' + childId;

    const baseRequestConfig = getBaseRequestConfig("DELETE");

    return await asyncFetch(url, baseRequestConfig);
}

export async function callArtworkREST(actionName, artworkId) {

    let url = `${BACKEND_URL}artwork/${artworkId}/${actionName}`;


    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

const asyncFetchDebounced = AwesomeDebouncePromise(
    asyncFetch,
    500,
    {},
);

export async function isInventoryNumberUnique(artworkId, inventoryNumber) {

    let data = {artworkId, inventoryNumber};

    let url = BACKEND_URL + 'artwork/is-inventory-number-unique';


    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(data)};

    const response = await asyncFetchDebounced(url, requestConfig);

    return await response.json();
}
