// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
export const TRACK_START = "TRACK_START"
export const TRACK_SET_RECORDS = "TRACK_SET_RECORDS"
export const TRACK_ACTIVITY = "TRACK_ACTIVITY"

// Reducer
export default function trackingReducer(state = {records: []}, action = {}) {
    switch (action.type) {
        case TRACK_ACTIVITY:

            let record = {...action.payload.record, at: Date.parse(action.payload.record.at)}

            return {
                ...state,
                records: trackActivity([...state.records], record)
            }
        case TRACK_SET_RECORDS:
            let records2 = [...action.payload.records];
            records2.forEach(r => r.at = Date.parse(r.at));

            return {
                ...state,
                records: action.payload.records
            }

        default:
            return state
    }
}

function trackActivity(records, record) {

    let updated = false;
    records.forEach(r => {
        if (r.user == record.user && r.inventoryNumber == record.inventoryNumber) {
            r.at = record.at;
            updated = true;
            return;
        }
    })

    if (updated) {
        return records;
    }

    return [...records, record]
}

export const addActivityRecord = (record) => ({
    type: TRACK_ACTIVITY,
    payload: {record}
})

export const trackStart = () => ({
    type: TRACK_START
})

export const selectAllRecords = (state) => {
    return state.tracking.records.sort((a, b) => b.at - a.at)
}