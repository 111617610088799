import {combineReducers} from 'redux';
import browseReducer from "./browseReducer";
import filtersReducer from "./filteringReducer";
import artworkReducer from "./artworkReducer";
import foldersReducer from "./foldersReducer";
import {ARTWORKS, DICTIONARY_ENTRIES, IMAGE_IMPORT_RESULTS, MEMBERS, PERSONS} from "../components/browse/BrowseType";
import imports from "./importReducer";
import engine from "../engine/reducers/engineReducer";
import finalFormReducer from "../engine/form/finalFormDuck";
import userReducer from "../user/userDuck";
import engineManage from "../engine/reducers/engineManageReducer";
import loadingReducer from "../loading/loadingDuck";
import trackingReducer from "../tracking/trackingDuck";
import cropReducer from "./cropReducer";
import tableConfigReducer from "../table/tableConfigDuck";
import tableSelectionReducer from "../table/selectionDuck";
import bulkActionsReducer from "../table/bulkActionsDuck";
import queryReducer from "../query/queryDuck";
import usersReducer from "../config/admin/security/usersDuck";
import newsReducer from "../news/newsDuck";
import commonDataReducer from "../common/commonDuck";
import contentReducer from "../content/contentDuck";
import personsReducer from "../components/person/personDuck";
import dictionaryEntriesReducer from "../components/dictionary-entry/dictionaryEntryDuck";

const rootReducer = combineReducers({
  browse: combineReducers({
    ARTWORKS: createFilteredReducer(browseReducer, action => action.payload && action.payload.browseType == ARTWORKS),
    MEMBERS: createFilteredReducer(browseReducer, action => action.payload && action.payload.browseType == MEMBERS),
    DICTIONARY_ENTRIES: createFilteredReducer(browseReducer, action => action.payload && action.payload.browseType == DICTIONARY_ENTRIES),
    PERSONS: createFilteredReducer(browseReducer, action => action.payload && action.payload.browseType == PERSONS),
    IMAGE_IMPORT_RESULTS: createFilteredReducer(browseReducer, action => action.payload && action.payload.browseType == IMAGE_IMPORT_RESULTS),
  }),
  filters: filtersReducer,
  artworks: artworkReducer,
  folders: foldersReducer,
  imports: imports,
  engine: engine,
  engineManage: engineManage,
  finalForm: finalFormReducer,
  user: userReducer,
  tracking: trackingReducer,
  loading: loadingReducer,
  crop: cropReducer,
  tableConfig: tableConfigReducer,
  tableSelection: tableSelectionReducer,
  bulkActions: bulkActionsReducer,
  queries: queryReducer,
  users: usersReducer,
  commonData: commonDataReducer,
  news: newsReducer,
  content: contentReducer,
  persons: personsReducer,
  dictionaryEntries: dictionaryEntriesReducer
});

function createFilteredReducer(reducerFunction, reducerPredicate) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  }
}

export default rootReducer
