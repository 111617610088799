import React from 'react'
import {Translation} from "react-i18next";

export const dictionaryEntryTableConfiguration = {
    id: "dictionaryEntryTableV1",
    columns: [
        {
            id: "name pl",
            label: "name pl",
            renderer: (entry) => entry.labels && entry.labels.pl,
            sortableKey: "tokenizedLabels.pl"
        },
        {
            id: "name en",
            label: "name en",
            renderer: (entry) => entry.labels && entry.labels.en,
            sortableKey: "tokenizedLabels.en"
        },
        {
            id: "code",
            label: "code",
            renderer: (entry) => entry.data && entry.data.code ? entry.data.code : null
        },
        {
            id: "dictionary",
            label: "dictionary",
            renderer: (entry) => entry.dictionary ?
                <Translation>{t => t("dictionary." + entry.dictionary.id)}</Translation> : null
        }

    ],
    recordIdentifier: (entry) => entry.id,
    onRowClick: (entry) => window.location.href = "/dictionary-entry/edit/" + entry.id,
}