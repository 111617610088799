import React from 'react'
import * as actions from "../../actions/artworkActions";
import {connect} from "react-redux";
import SimpleEditableField from "../../components/item/SimpleEditableField";
import SelectizeEditableField from "../../components/item/SelectizeEditableField";
import ArtworkPreviews from "../../components/item/ArtworkPreviews";
import {Translation} from "react-i18next";
import CropComponent from "../../components/crop/CropComponent";
import {hasRole} from "../../engine/selectors";
import {withRouter} from "react-router-dom";
import {MEMBERS} from "../../components/browse/BrowseType";
import NavigateInSearchResultsComponent from "../../components/browse/NavigateInSearchResultsComponent";
import PersonalDataEditableField from "./PersonalDataEditableField";
import HomeAddressEditableField from "./HomeAddressEditableField";
import ContactEditableField from "./ContactEditableField";
import RolesEditableField from "./RolesEditableField";
import WorkplaceEditableField from "./WorkplaceEditableField";
import {getMemberDetails, startUpdateMember} from "../../actions/memberActions";
import CorrespondenceAddressEditableField from "./CorrespondenceAddressEditableField";
import MemberOfEditableFields from "./MemberOfEditableFields";

const mapStateToProps = (state, ownProps) => {
    return {
        cloneInventoryNumber: ownProps.match.params.cloneInventoryNumber,
        recordId: ownProps.match.params.id,
        parentArtworkId: ownProps.match.params.parentArtworkId,
        record: state.artworks.artwork,
        canCreate: hasRole(state, "EDITOR")
    }
        ;
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRecord: (memberId, clone) => dispatch(getMemberDetails(memberId, clone)),
        setArtworkToEdit: (artwork) => dispatch(actions.setArtworkToEdit(artwork)),
        startUpdate: (artwork) => dispatch(startUpdateMember(artwork))
    }
};

class MemberDetails extends React.Component {

    componentWillMount() {
        this.prepare();
    }

    componentDidUpdate() {
        this.prepare();
    }

    prepare() {
        if (!this.props.record || this.props.record.id != this.props.recordId) {

            if (this.props.recordId) {
                this.props.getRecord(this.props.recordId, false);
            } else {

                let newArtwork = {};


                this.props.setArtworkToEdit(newArtwork);
            }
        }
    }


    updateArtwork() {
        this.props.startUpdate(this.props.record);
    }

    goBack() {
        this.props.history.push("/members/");
    }

    goToClone() {
        this.props.history.push("/artwork/clone/" + this.props.record.id);
    }


    render() {
        if (!this.props.record || !this.props.canCreate) {
            return (<div></div>);
        }


        let artworkPreview = null;
        let additionalActionsBar = null;
        if (this.props.record.id && !this.props.record.deleted) {
            artworkPreview = (<ArtworkPreviews type={MEMBERS}/>)
        } else if (!this.props.record.deleted) {
            artworkPreview = (<Translation>{t => <div className="alert alert-secondary" role="alert">
                {t("saveNewArtworkThenUploadImages")}
            </div>}</Translation>);
            additionalActionsBar = (<Translation>{t => <div className="alert alert-secondary inline" role="alert">
                {t("documentIsNotSaved")}
            </div>}</Translation>);
        } else {
            additionalActionsBar = (<Translation>{t => <div className="alert alert-danger inline" role="alert">
                {t("documentIsDeleted")}
            </div>}</Translation>);
        }


        return (
            <div className="row">
                <div className="col-md-2 item-details-left-col">
                    {artworkPreview}
                </div>
                <main className="col-md-10 content">
                    <div className="row">
                        <CropComponent/>
                    </div>
                    <div className="row">
                        <div className="col header-actions-menu">
                            <button type="button" className="btn btn-outline-primary" onClick={() => this.goBack()}><i
                                className="fas fa-angle-left"></i></button>
                            {this.props.canCreate &&
                                <button type="button" className="btn btn-primary" disabled={this.props.record.deleted}
                                        onClick={event => this.updateArtwork()}>Zapisz
                                </button>}
                            {additionalActionsBar}

                        </div>
                        <div className="col header-actions-menu">
                            {/*<VisibleInNavigartEditableField/>*/}
                        </div>
                        <div className="col header-actions-menu">
                            <NavigateInSearchResultsComponent browseType={MEMBERS} recordId={this.props.record.id}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-5">
                            <PersonalDataEditableField/>
                            <HomeAddressEditableField/>
                            <CorrespondenceAddressEditableField/>
                            <SelectizeEditableField path="speciality" dictionaryId="speciality"/>
                            <ContactEditableField/>
                            <WorkplaceEditableField/>
                            <SimpleEditableField path="retired" type="checkbox"/>
                        </div>
                        <div className="col-xl-5">
                            {/*<MembersField/>*/}
                            <MemberOfEditableFields/>
                            <SelectizeEditableField path="committees" dictionaryId="committees"/>
                            <RolesEditableField/>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MemberDetails))