export const ENGINE_PREPARE = "ENGINE_PREPARE";
export const ENGINE_UPDATE_CONFIG_WITHOUT_CHILDREN = "ENGINE_UPDATE_CONFIG_WITHOUT_CHILDREN";
export const ENGINE_PREPARE_SET_MAIN_CONFIG = "ENGINE_PREPARE_SET_MAIN_CONFIG";
export const ENGINE_PREPARE_COMPONENT = "ENGINE_PREPARE_COMPONENT";
export const ENGINE_RUN_ACTION = "ENGINE_RUN_ACTION";
export const ENGINE_RUN_ACTION_SHOW_COMPONENT = "ENGINE_RUN_ACTION_SHOW_COMPONENT";
export const ENGINE_RUN_ACTION_HIDE_COMPONENT = "ENGINE_RUN_ACTION_HIDE_COMPONENT";
export const ENGINE_RUN_ACTION_BLOCK_COMPONENT = "ENGINE_RUN_ACTION_BLOCK_COMPONENT";
export const ENGINE_RUN_ACTION_UNBLOCK_COMPONENT = "ENGINE_RUN_ACTION_UNBLOCK_COMPONENT";
export const ENGINE_SET_DATA_TO_COMPONENT = "ENGINE_SET_DATA_TO_COMPONENT";
export const ENGINE_SET_PAGING_DATA_TO_COMPONENT = "ENGINE_SET_PAGING_DATA_TO_COMPONENT";

export function prepareEngine(config) {
    return {
        type: ENGINE_PREPARE,
        payload: config
    }
}


export function updateConfigWithoutChildren(config) {
    return {
        type: ENGINE_UPDATE_CONFIG_WITHOUT_CHILDREN,
        payload: config
    }
}

export function runAction(config, record) {
    return {
        type: ENGINE_RUN_ACTION,
        payload: {
            actionConfig: config,
            record: record
        }
    }
}

