import React from 'react'
import {connect} from "react-redux";
import {
    closeColumnsEditor,
    selectColumnsDraft,
    selectOrderedColumns,
    selectSortedColumns,
    setColumnOnPosition,
    setColumnsDraft,
    setColumnsStart,
    setDefaultColumns,
    toggleColumn
} from "../../table/tableConfigDuck";
import {Translation} from "react-i18next";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";


const mapStateToProps = (state, ownProps) => {
    return {
        columns: selectSortedColumns(state, ownProps.tableConfig.id),
        columnsDraft: selectColumnsDraft(state, ownProps.tableConfig.id),
        orderedColumns: selectOrderedColumns(state, ownProps.tableConfig.id, ownProps.tableConfig)
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setColumnsDraft: (id, columns) => dispatch(setColumnsDraft(id, columns)),
        setColumnsStart: (id, columns) => dispatch(setColumnsStart(id, columns)),
        toggleColumn: (id, columns) => dispatch(toggleColumn(id, columns)),
        closeColumnsEditor: () => dispatch(closeColumnsEditor()),
        setDefaultColumns: (tableConfig) => dispatch(setDefaultColumns(tableConfig)),
        reorderAssets: (columns, result) => dispatch(setColumnOnPosition(ownProps.tableConfig.id, columns, result.source.index, result.destination.index)),
    }
};



class ManageTableColumnsComponent extends React.Component {

    componentDidMount() {
        this.props.setColumnsDraft(this.props.tableConfig.id, this.props.orderedColumns)
    }


    render() {
        let id2position = {};
        let id2enabled = {}
        this.props.columnsDraft.forEach(c => {
            id2position[c.id] = c.position;
            id2enabled[c.id] = c.enabled;
        });

        let id2columnConfig = {}
        let columns = [...this.props.tableConfig.columns]

        let sortedColumns = columns.sort((a, b) => {
            let o1 = id2position[a.id]
            let o2 = id2position[b.id]

            if (o1 != undefined && o2 != undefined) {
                if (o1 > o2) {
                    return 1
                }
                if (o1 < o2) {
                    return -1
                }
            }
            return 0
        })


        return (
            <div className="modal overlay show" id="exampleModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" style={{"display": "block"}}>
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Zarządzanie kolumnami tabeli</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => this.props.closeColumnsEditor()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-hover  f11">
                                <thead>
                                </thead>
                                <DragDropContext onDragEnd={(result) => this.props.reorderAssets(columns, result)}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <tbody
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                            {sortedColumns.map((column, index) => (
                                                <Draggable key={column.id} draggableId={column.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <td>
                                                                <input type="checkbox" r
                                                                       id={column.id}
                                                                       value={true}
                                                                       checked={id2enabled[column.id] || column.required}
                                                                       disabled={column.required}
                                                                       onChange={(e) => this.props.toggleColumn(this.props.tableConfig.id, column.id)}/>
                                                            </td>

                                                            <td
                                                            >
                                                                <Translation>{t =>
                                                                    <label for={column.id}>

                                                                        {t(column.label)}
                                                                    </label>
                                                                }</Translation>
                                                            </td>

                                                        </tr>
                                                    )}
                                                </Draggable>

                                            ))}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-id="restore-defaults"
                                    onClick={event => this.props.setDefaultColumns(this.props.tableConfig)}>Przywróć
                                domyślne
                            </button>
                            <button type="button" className="btn btn-secondary" data-id="close-columns-editor"
                                    onClick={() => this.props.closeColumnsEditor()}>Anuluj
                            </button>
                            <button type="button" className="btn btn-primary" data-id="save-columns"
                                    onClick={event => this.props.setColumnsStart(this.props.tableConfig.id, this.props.columnsDraft)}>Zapisz
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTableColumnsComponent)

