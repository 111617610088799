import {call, put, select} from "redux-saga/effects";
import {getDictionaryEntries} from "../api/dictionaryEntry.api";
import {toast} from "react-toastify";
import {getFilterAvailableOptions} from "../reducers/filteringReducer";
import {setPageAndSearch} from "./browseActions";
import {fetchDictionariesREST} from "../api/dictionary.api";

export const FILTERS_SET_PARAM = 'FILTERS_SET_PARAM';
export const SET_EDIT_FILTER = 'SET_EDIT_FILTER';
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const APPLY_FILTER_VALUE = 'APPLY_FILTER_VALUE';
export const OPEN_CLOSE_PICKUP_WINDOW = 'OPEN_CLOSE_PICKUP_WINDOW';
export const FILTER_LOAD_ALL_AVAILABLE_OPTIONS_START = 'FILTER_LOAD_ALL_AVAILABLE_OPTIONS_START';
export const FILTER_LOAD_ALL_AVAILABLE_DICTIONARIES_START = 'FILTER_LOAD_ALL_AVAILABLE_DICTIONARIES_START';
export const FILTER_SET_AVAILABLE_OPTIONS = 'FILTER_SET_AVAILABLE_OPTIONS';
export const FILTER_TOGGLE = 'FILTER_TOGGLE';
export const FILTER_SET_VALUES = 'FILTER_SET_VALUES';

export function setFilteringParam(param, value) {
    return {
        type: FILTERS_SET_PARAM,
        payload: {param, value}
    };
}

export function editFilter(fieldConfig) {
    return {
        type: SET_EDIT_FILTER,
        payload: {fieldConfig}
    };
}

export function setCurrentValue(value) {
    return {
        type: SET_CURRENT_FILTER,
        payload: {value}
    };
}

export function openClosePickupWindow(opened, filtersAvailable) {
    return {
        type: OPEN_CLOSE_PICKUP_WINDOW,
        payload: {opened, filtersAvailable}
    };
}

export function applyFilterValue(filterId, value, browseType) {
    return {
        type: APPLY_FILTER_VALUE,
        payload: {filterId, value, browseType}
    };
}

export function filterToggle(filterId) {
    return {
        type: FILTER_TOGGLE,
        payload: {filterId}
    };
}

export function* applyColumnFilter(action) {

    let {filterId, value, browseType} = action.payload;
    yield put(setFilteringParam(filterId, value));
    yield put(editFilter(null));
    yield put(setCurrentValue(null));
    yield put(setPageAndSearch(0, browseType));

}

export function loadAvailableOptions(dictionaryId) {
    return {
        type: FILTER_LOAD_ALL_AVAILABLE_OPTIONS_START,
        payload: {dictionaryId}
    };
}


export function loadAvailableDictionaries() {
    return {
        type: FILTER_LOAD_ALL_AVAILABLE_DICTIONARIES_START,
    };
}

export function* filterLoadAvailableOptions(action) {

    let {dictionaryId} = action.payload;

    try {
        let options = yield select(state => getFilterAvailableOptions(state, dictionaryId))

        if (options.length == 0) {
            options = yield call(getDictionaryEntries, dictionaryId);

            yield put({
                type: FILTER_SET_AVAILABLE_OPTIONS,
                payload: {dictionaryId, options}
            });
        }

    } catch (e) {
        console.error(e)
        toast.error("Wystąpił błąd podczas ładowania danych");
    }

}

export function* filterLoadAvailableDictionariesSaga(action) {
    try {
        let dictionaryId = "dictionaries";
        let options = yield select(state => getFilterAvailableOptions(state, dictionaryId))


        if (options.length == 0) {
            options = yield call(fetchDictionariesREST);

            yield put({
                type: FILTER_SET_AVAILABLE_OPTIONS,
                payload: {dictionaryId, options}
            });
        }

    } catch (e) {
        console.error(e)
        toast.error("Wystąpił błąd podczas ładowania danych");
    }

}