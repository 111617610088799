// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";
import asyncFetch from "../api/async-fetch";
import {getFilterValues} from "../reducers/filteringReducer";
import {FILTER_SET_VALUES} from "../actions/filtersActions";
import {setPageAndSearch} from "../actions/browseActions";

export const QUERY_SAVE_OPEN_MODAL = "QUERY_SAVE_OPEN_MODAL"
export const QUERY_SAVE_AS = "QUERY_SAVE_AS"
export const QUERY_CLOSE = "QUERY_CLOSE"
export const QUERY_SAVE = "QUERY_SAVE"
export const QUERY_SET_NAME_ATTR = "QUERY_SET_NAME_ATTR"

export const QUERY_SELECT = "QUERY_SELECT"
export const QUERY_SET_SELECTED = "QUERY_SET_SELECTED"

export const QUERY_DELETE = "QUERY_DELETE"

export const QUERIES_LOAD = "QUERIES_LOAD"
export const QUERIES_SET = "QUERIES_SET"
export const QUERIES_SET_SEARCH = "QUERIES_SET_SEARCH"

let initialState = {
    opened: false,
    queries: [],
    search: "",
    selectedQuery: {
        id: null,
        name: "",
    }
}
// Reducer
export default function queryReducer(state = initialState, action = {}) {
    switch (action.type) {
        case QUERY_SAVE_OPEN_MODAL:
            return {...state, opened: true}
        case QUERY_CLOSE:
            return {...state, opened: false}
        case QUERIES_SET_SEARCH:
            return {...state, search: action.payload}
        case QUERY_SET_SELECTED:
            return {...state, selectedQuery: action.payload}
        case QUERIES_SET:
            return {...state, queries: action.payload}
        case QUERY_SET_NAME_ATTR:
            return setName(state, action.payload)
        default:
            return state
    }
}

const setName = (state, payload) => {
    let {name} = payload
    let query = {...state.selectedQuery, name: name}
    return {...state, selectedQuery: query};
}

export const closeQueryModal = () => ({
    type: QUERY_CLOSE,
})

export const saveQueryOpenModal = () => ({
    type: QUERY_SAVE_OPEN_MODAL,
})

export const loadQueries = () => ({
    type: QUERIES_LOAD,
})


export const querySave = (asNew) => ({
    type: QUERY_SAVE,
    payload: {asNew}
})

export const querySetName = (name) => ({
    type: QUERY_SET_NAME_ATTR,
    payload: {name}
})

export const queryDelete = (queryId) => ({
    type: QUERY_DELETE,
    payload: {queryId}
})

export const querySelect = (query, browseType) => ({
    type: QUERY_SELECT,
    payload: {query, browseType}
})

export const queriesSetSearch = (name) => ({
    type: QUERIES_SET_SEARCH,
    payload: name
})

export function* selectQuerySaga(action) {
    let {query, browseType} = action.payload

    yield put({
        type: QUERY_SET_SELECTED,
        payload: query
    });


    let filters = {}
    if (query) {
        filters = JSON.parse(query.data)
    }

    yield put({
        type: FILTER_SET_VALUES,
        payload: filters
    });

    yield put(setPageAndSearch(0, browseType))

}

export function* saveQuerySaga(action) {
    try {

        let {asNew} = action.payload
        let selectedQuery = yield select(state => getSelectedQuery(state))
        let filterValues = yield select(state => getFilterValues(state))

        let query = {
            id: asNew ? null : selectedQuery.id,
            name: selectedQuery.name,
            tableId: "artworks",
            data: JSON.stringify(filterValues)
        }

        let saved = yield call(saveQueryREST, query);
        toast.success("Zapisano zmiany");

        yield put({
            type: QUERY_SET_SELECTED,
            payload: saved
        });

        yield put({
            type: QUERY_CLOSE
        });

        yield put({
            type: QUERIES_LOAD
        });


    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisu");
    } finally {

    }
}

export function* deleteQuerySaga(action) {
    try {
        let {queryId} = action.payload

        yield call(deleteQuery, queryId);

        yield put({
            type: QUERIES_LOAD
        });

        toast.success("Usunięto kwerendę");
    } catch (e) {
        toast.error("Wystąpił błąd usuwania kwerendy");
    } finally {

    }
}

export function* getQueries() {
    try {
        let queries = yield call(getQueriesREST, "artworks");

        yield put({
            type: QUERIES_SET,
            payload: queries
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania kwerend");
    } finally {

    }
}


export async function saveQueryREST(query) {

    const url = `${BACKEND_URL}search-query/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(query)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function getQueriesREST(tableId) {

    const url = `${BACKEND_URL}search-query/` + tableId;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}

export async function deleteQuery(queryId) {

    const url = `${BACKEND_URL}search-query/${queryId}/delete`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "DELETE"};

    return asyncFetch(url, requestConfig);
}

export const isSaveQueryModalOpened = (state) => state.queries.opened
export const getSelectedQuery = (state) => state.queries.selectedQuery
export const selectQueries = (state) => state.queries.queries
export const selectSearch = (state) => state.queries.search