import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";
import {toSelectizePerson} from "../util/personUtils";

export async function getPersonsByName(name) {

    const url = BACKEND_URL + 'persons/by-name/' + name + "/50";

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    const data = await response.json();

    return data.map((person) => toSelectizePerson(person));
}


export async function searchPersonsFromREST(request) {

    const url = BACKEND_URL + 'persons/search';

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function createPersonREST(name) {

    const url = `${BACKEND_URL}persons/create`;

    const baseRequestConfig = getBaseRequestConfig();

    var request = {
        name: name
    };

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}