export const MEMBER_UPDATE = 'MEMBER_UPDATE';
export const MEMBER_UPDATE_REQUESTED = 'MEMBER_UPDATE_REQUESTED';
export const MEMBER_UPDATE_DONE = 'MEMBER_UPDATE_DONE';
export const MEMBER_UPDATE_FAILED = 'MEMBER_UPDATE_FAILED';

export const MEMBER_DETAILS_LOAD = "MEMBER_DETAILS_LOAD";
export const MEMBER_DETAILS_GET_DATA_REQUESTED = 'MEMBER_DETAILS_GET_DATA_REQUESTED';
export const MEMBER_DETAILS_GET_DATA_DONE = 'MEMBER_DETAILS_GET_DATA_DONE';
export const MEMBER_DETAILS_GET_DATA_FAILED = 'MEMBER_DETAILS_GET_DATA_FAILED';

export function startUpdateMember(member) {
    return {
        type: MEMBER_UPDATE,
        payload: member
    }
}

export function getMemberDetails(memberId, clone) {
    return {
        type: MEMBER_DETAILS_LOAD,
        payload: {memberId, clone}
    }
}
