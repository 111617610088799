import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";

export async function getArtworkFromREST(memberId) {

    const url = BACKEND_URL + 'member/' + memberId;
    const requestConfig = getBaseRequestConfig();


    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

export async function updateMemberREST(member) {

    let url = BACKEND_URL + 'member/';
    if (member.id) {
        url += "update/" + member.id;
    } else {
        url += "create"
    }

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(member)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
