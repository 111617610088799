import React from 'react'
import {connect} from "react-redux";
import {runAction} from "../actions/engine.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.engine.configsMap[ownProps.configId],
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config) => dispatch(runAction(config, {}))
    }
};

class ButtonComponent extends React.Component {



    render() {
       return (
           <button id="start-import-trigger" type="button" className="btn btn-primary"
                   onClick={() => this.props.runAction(this.props.config.action)}>
               {this.props.config.label}
           </button>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonComponent)

