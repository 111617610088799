import {COMPONENT_STATE} from "./base/currentRecord";
import {IS_EQUAL} from "./qualifier/fieldIsEqual";
import {checkConstraint} from "./checkConstraint";


export function isVisible(config, state) {
    let visible = true;

    if (config.visibilityConstraint) {
        visible = checkConstraint(config.visibilityConstraint, {
            state
        });
    }
    else {
        let visibilityConstraint = {
            source: {
                type: COMPONENT_STATE,
                options: {
                    componentId: config.id,
                    attr: "visibility"
                }
            },
            qualifier: {
                type: IS_EQUAL,
                options: {
                    value: "visible"
                }
            },
            negation: false,
        };

        visible = checkConstraint(visibilityConstraint, {
            state
        });
    }

    return visible;
}