import {BACKEND_URL} from "../../config";

export const initAppConfig = {
    type: "call-rest",
    url: BACKEND_URL + 'user',
    setResponseToComponent: "loggedUserComponent",
    successAction: {
        type: "unblock-component",
        componentId: "mainScreen",
    },
    errorAction: {
        type: "redirect",
        path: "/login",
    }
};
