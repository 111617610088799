export const IMPORT_ASSETS = "IMPORT_ASSETS";
export const IMPORT_ASSETS_REQUESTED = 'IMPORT_ASSETS_REQUESTED';
export const IMPORT_ASSETS_DONE = 'IMPORT_ASSETS_DONE';
export const IMPORT_ASSETS_FAILED = 'IMPORT_ASSETS_FAILED';

export function importAssets(dryRun) {
    return {
        type: IMPORT_ASSETS,
        payload: {dryRun}
    }
}
