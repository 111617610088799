import {put} from "@redux-saga/core/effects";
import * as actions from "../actions/engine.actions";
import {selectFormValues, selectMainConfig} from "../selectors";

export function *exportRules() {



    let config = yield selectMainConfig();


    const filename = 'data.json';
    const jsonStr = JSON.stringify(config, null, 4);

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);



}
