import React from 'react'
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {getAllSelected, SELECTION_STATE, toggleAllItemsSelection} from "../../table/selectionDuck";


const mapStateToProps = (state, ownProps) => {
    return {
        selectionState: getAllSelected(state)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleAllItemsSelection: () => dispatch(toggleAllItemsSelection()),
    }
};


class SelectAllCheckboxComponent extends React.Component {

    componentDidMount() {
        this._setIndeterminate();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.selectionState !== this.props.selectionState) {
            this._setIndeterminate();
        }
    }

    _setIndeterminate() {
        const node = ReactDOM.findDOMNode(this);
        node.indeterminate = this.props.selectionState === SELECTION_STATE.SOME;
    }

    render() {

        return <input type="checkbox"
                      checked={this.props.selectionState !== SELECTION_STATE.NONE}
                      onClick={() => this.props.toggleAllItemsSelection()}/>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAllCheckboxComponent)