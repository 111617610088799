import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";
import asyncFetch from "../api/async-fetch";

export async function searchMembersFromREST(request) {

    const url = BACKEND_URL + 'member/search/';

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
