// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
export const USER_SET_TOKEN = "USER_SET_TOKEN"

// Reducer
export default function userReducer(state = {token: null}, action = {}) {
    switch (action.type) {
        case USER_SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        default:
            return state
    }
}

export const setToken = (token) => ({
    type: USER_SET_TOKEN,
    payload: token
})

