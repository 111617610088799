import {call, put} from 'redux-saga/effects';
import {CROP_CLOSE} from "../actions/cropActions";
import {LOADING_START, LOADING_STOP} from "../loading/loadingDuck";
import {cropAssetREST} from "../api/crop.api";
import {toast} from "react-toastify";
import {ASSET_REFRESH_PREVIEW} from "../actions/assetActions";

export function* cropAsset(action) {
    try {
        yield put({
            type: LOADING_START,
        });

        let {crop, assetId, artworkId} = action.payload;

        let result = yield call(cropAssetREST, assetId, artworkId, crop);

        if (result) {
            yield put({
                type: ASSET_REFRESH_PREVIEW,
                payload: {assetId}
            });
            yield put({
                type: CROP_CLOSE,
            });
            toast.success("Zapisano miniaturkę");
        } else {
            toast.error("Wystąpił błąd podczas zapisu");
        }
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisu");
    } finally {
        yield put({
            type: LOADING_STOP,
        });
    }
}
