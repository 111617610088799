import * as actions from '../actions/browseActions'

export const initialState = {
  isLoading: false, isError: false, results: {content: [], pageable: {pageNumber: 0, pageSize: 20}},
  pageNumber: 0,
  pageSize: 20,
  sortBy: {
    by: "inventoryNumber",
    direction: "ASC"
  }
};

export default function browse(state = initialState, action) {
  switch (action.type) {
    case actions.BROWSE_GET_DATA_REQUESTED:
      return {...state, isLoading: true, isError: false};
    case actions.BROWSE_GET_DATA_DONE:
      return {...state, isLoading: false, results: action.payload.response};
    case actions.BROWSE_SET_PAGE:
      return {...state, pageNumber: action.payload.page};
    case actions.BROWSE_GET_DATA_FAILED:
      return {...state, isLoading: false, isError: true};
    case actions.BROWSE_REPLACE_RECORD_ON_BROWSE_TABLE:
      return replaceRecord(state, action.payload);
    case actions.BROWSE_SET_SORTING:
      return setSorting(state, action.payload);
    default:
      return state;
  }
}

function replaceRecord(state, payload) {
  let resultsContent = [...state.results.content]

  let idx = resultsContent.findIndex(payload.findCallback);

  if (idx > -1) {
    resultsContent[idx] = payload.record;
  }

  return {...state, results: {...state.results, content: resultsContent}};
}

function setSorting(state, payload) {

  let keyword = payload.sortBy
  let sortBy = {...state.sortBy}

  if (sortBy.by == keyword) {
    sortBy.direction = sortBy.direction != "ASC" ? "ASC" : "DESC";
  } else {
    sortBy.by = keyword;
    sortBy.direction = "ASC";
  }

  return {...state, sortBy: sortBy};
}
