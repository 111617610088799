export const dictionaryEntriesFiltersAvailable = [
    {
        id: "name",
        label: "name",
        type: "string",
    },
    {
        id: "dictionary",
        label: "dictionary",
        type: "dictionary",
    },

];