import React from 'react'
import {connect} from "react-redux";
import {runAction} from "../actions/engine.actions";
import {checkConstraint} from "../constraint/checkConstraint";
import {CURRENT_RECORD} from "../constraint/base/currentRecord";

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.engine.configsMap[ownProps.configId],
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config, record) => dispatch(runAction(config, record))
    }
};

const getCtx = (record) => {
    let ctx = {};
    ctx[CURRENT_RECORD] = record;
    return ctx;
}

class ActionsListComponent extends React.Component {




    render() {

        let actions = this.props.actions.map((actionBtDef) => (checkConstraint(actionBtDef.constraint, getCtx(this.props.record)) &&
                <button key={"action_" + actionBtDef.label} type="button" className="btn btn-primary"
                    onClick={(proxy) =>{this.props.runAction(actionBtDef.action, this.props.record); proxy.stopPropagation();}}>
                    {actionBtDef.label}
                </button>)
        );

        return (
         <React.Fragment>
             {actions}
         </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsListComponent)

