import {getComponentStateAttr} from "../../selectors";

export const CURRENT_RECORD = "CURRENT_RECORD";
export const COMPONENT_STATE = "COMPONENT_STATE";

export function currentRecordFetchForConstraint (sourceDef, context){ return context[CURRENT_RECORD]};


export function componentStateForConstraint(sourceDef, context) {
    let {componentId, attr} = sourceDef.options;
    let {state} = context;

    return getComponentStateAttr(state, componentId, attr);
}
