import React from 'react'
import {connect} from "react-redux";
import {closeModalPreview, setPreviewThumbnailSize} from "../../actions/assetActions";
import {BACKEND_URL} from "../../config";

const mapStateToProps = (state, ownProps) => {
    return {
        asset: state.artworks.assetModalPreview ? state.artworks.assetModalPreview.asset : null,
        thumbnailSize: state.artworks.assetModalPreview ? state.artworks.assetModalPreview.thumbnailSize : null,
    }
        ;
};
const mapDispatchToProps = (dispatch) => {
    return {
        closeModalPreview: (assetId, artworkId) => dispatch(closeModalPreview()),
        setPreviewThumbnailSize: (thumbnailSize) => dispatch(setPreviewThumbnailSize(thumbnailSize)),
    };
};

class AssetPreviewModal extends React.Component {

    render() {
        if (!this.props.asset) {
            return null;
        }

        let imageHref = BACKEND_URL + "asset/" + this.props.asset.id + "/" + this.props.thumbnailSize + "/raw";

        let menu = Object.keys(this.props.asset.thumbnails)
            .filter(t => t != "square_cutout_medium")
            .map(thumbailSize =>
                <button
                    key={thumbailSize}
                    className={`btn ${thumbailSize == this.props.thumbnailSize ? "btn-primary" : "btn-secondary"} btn-sm`}
                    onClick={() => this.props.setPreviewThumbnailSize(thumbailSize)}>{thumbailSize}</button>
            )


        return (
            <div className="modal overlay show" id="exampleModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" style={{"display": "block"}}>
                <div className="modal-dialog modal-fullscreen modal-dialog-scrollable modal-dialog-centered"
                     role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.props.asset.filename}
                                {menu}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => this.props.closeModalPreview()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img src={imageHref}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetPreviewModal)