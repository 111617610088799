import {FIELD_IS_EQUAL, fieldIsEqualImpl, IS_EQUAL, isEqualImpl} from "./fieldIsEqual";

const qualifiers = {};
qualifiers[FIELD_IS_EQUAL] = fieldIsEqualImpl;
qualifiers[IS_EQUAL] = isEqualImpl;

export function checkConstraintWithQualifier(qualifierDef, baseObject) {
    let qualifier = qualifiers[qualifierDef.type];

    //TODO merge engines default qualifiers map with projects configuration

    if (qualifier) {
        return qualifier(qualifierDef, baseObject)
    }

    throw "Not found fetcher of constraint base object for type: " + qualifierDef.type;
}