import React from 'react'
import {connect} from "react-redux";
import {setCode} from "./dictionaryEntryDuck";
import {Translation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
        dictionaryEntry: state.dictionaryEntries.dictionaryEntry,
        code: state.dictionaryEntries.dictionaryEntry
        && state.dictionaryEntries.dictionaryEntry.data
        && state.dictionaryEntries.dictionaryEntry.data.code ? state.dictionaryEntries.dictionaryEntry.data.code : null,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCode: (code) => dispatch(setCode(code)),
    }
};

class DictionaryEntryEditCode extends React.Component {


    render() {
        if (this.props.dictionaryEntry.dictionary.id != "iconclass") {
            return null
        }

        return (
            <div className="component">
                <header className="component-header">
                    <Translation>{t =>
                        <span>{t("code")}</span>
                    }</Translation>
                </header>
                <div className="message">

                </div>
                <div className="component-content">
                        <textarea
                            className="full-width"
                            name={"code"}
                            value={this.props.code}
                            onChange={(e) => this.props.setCode(e.target.value)}
                        />
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntryEditCode)

