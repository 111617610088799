import {call, put} from 'redux-saga/effects';
import * as actions from "../actions/importActions";
import {importAssetsFromDiskREST} from "../api/import.api";

export function *importAssets(action) {

    const {dryRun} = action.payload

    try {
        yield put({
            type: actions.IMPORT_ASSETS_REQUESTED,
        });

        const responseBody = yield call(importAssetsFromDiskREST, dryRun);

        yield put({
            type: actions.IMPORT_ASSETS_DONE,
            payload: responseBody
        });

    } catch (e) {

        yield put({
            type: actions.IMPORT_ASSETS_FAILED,
            payload: action.payload
        });

    }
}

