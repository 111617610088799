/**
 *
 */

import {getBaseForConstraint} from "./base/constraintBaseType";
import {checkConstraintWithQualifier} from "./qualifier/qualifiers";

export const checkConstraint = (constraint, context) => {

    if (!constraint) {
        return true;
    }

    let baseObject = getBaseForConstraint(constraint.source, context);

    if (constraint.type === "OPERATOR") {
        let operatorType = constraint.operatorType;

        if (!constraint.children) {
            throw new Error("no children defind for constraint operator");
        }

        let childrenResult = false;
        let firstIteration = true;

        for (let c of constraint.children) {
            let result = checkConstraint(c, context);

            if (firstIteration) {
                childrenResult = result;
                firstIteration = false;
            }

            if (operatorType === "AND" && !result) {
                return constraint.negation ? true : false;
            }

            if (operatorType === "OR" && result) {
                return constraint.negation ? false : true;
            }
        }

        return constraint.negation ? !childrenResult : childrenResult;

    }
    else {
        let res = checkConstraintWithQualifier(constraint.qualifier, baseObject);

        return constraint.negation ? !res : res;
    }

    return false;
};
