import React from 'react'
import {connect} from "react-redux";
import {editFilter, openClosePickupWindow} from "../../actions/filtersActions";
import {Translation} from "react-i18next";
import {getSearchFilterToEdit} from "../../reducers/filteringReducer";

const mapStateToProps = (state) => {
    return {
        editFilter: getSearchFilterToEdit(state),
        filtersAvailable: state.filters.filtersAvailable,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setEditFilter: (fieldConfig) => {
            dispatch(editFilter(fieldConfig))
            dispatch(openClosePickupWindow(false))
        },
        closePickUpWindow: () => dispatch(openClosePickupWindow(false)),
    }
};


class PickUpFilter extends React.Component {

    render() {

        let size = this.props.filtersAvailable.length;
        let half = Math.round(size / 2)

        let filtersToAdd1 = [];
        let filtersToAdd2 = [];

        this.props.filtersAvailable.forEach(filter => {
            let val = (<Translation key={filter.id}>{t =>
                    <a className="dropdown-item add-filter" href="#"
                       onClick={() => this.props.setEditFilter(filter)}>{t(filter.label)}
                        <i className="fas fa-plus-square add-filter-hidden-icon"></i>
                    </a>
                }</Translation>
            )

            if (size-- > half) {
                filtersToAdd1.push(val)
            } else {
                filtersToAdd2.push(val)
            }
        });

        return (
            <div className="modal overlay show" id="exampleModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true" style={{"display": "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
                     role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <Translation>{t =>
                                <h5 className="modal-title" id="exampleModalLabel">{t("selectFilter")}</h5>
                            }</Translation>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => this.props.closePickUpWindow()}>
                                <span aria-hidden="true">&times;</span>
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6">
                                    {filtersToAdd1}
                                </div>
                                <div className="col-6">
                                    {filtersToAdd2}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickUpFilter)

