export const membersFilterAvailable = [
    {
        id: "dateOfBirth",
        label: "dateOfBirth",
        type: "date",
    },
    {
        id: "dateOfDeath",
        label: "dateOfDeath",
        type: "date",
    },
    {
        id: "city",
        label: "city",
        type: "dictionaryEntry",
        dictionaryId: "members_city"
    },
    {
        id: "postalCode",
        label: "postalCode",
        type: "string",
    },
    {
        id: "members_city",
        label: "members_city",
        type: "dictionaryEntry",
        dictionaryId: "members_city"
    },
    {
        id: "speciality",
        label: "speciality",
        type: "dictionaryEntry",
        dictionaryId: "members_speciality"
    },
    {
        id: "department",
        label: "department",
        type: "dictionaryEntry",
        dictionaryId: "members_department"
    },
    {
        id: "committees",
        label: "committees",
        type: "dictionaryEntry",
        dictionaryId: "members_committees"
    },
    {
        id: "roles",
        label: "roles",
        type: "dictionaryEntry",
        dictionaryId: "members_roles"
    },
];