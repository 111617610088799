import React from 'react'
import {connect} from "react-redux";
import ActionsListComponent from "./ActionsListComponent";
import {runAction} from "../actions/engine.actions";
import getObjectAttr from "../../util/getObjectAttr";
import {Translation} from "react-i18next";
import {
    getColumns,
    openColumnsEditor,
    selectOrderedColumns,
    selectTableConfigurationOpened,
    selectVisibleColumns,
    selectVisibleColumnsDefined,
    setDefaultConfig
} from "../../table/tableConfigDuck";
import ManageTableColumnsComponent from "./ManageTableColumnsComponent";

import {getSelectedItems, toggleItem} from "../../table/selectionDuck";
import SelectAllCheckboxComponent from "./SelectAllCheckboxComponent";
import {setSortingAndSearch} from "../../actions/browseActions";
import {withRouter} from "react-router-dom";


const mapStateToProps = (state, ownProps) => {
    return {
        visibleColumnsDefined: selectVisibleColumnsDefined(state, ownProps.tableConfig.id),
        visibleColumns: selectVisibleColumns(state, ownProps.tableConfig.id, ownProps.tableConfig),
        orderedColumns: selectOrderedColumns(state, ownProps.tableConfig.id, ownProps.tableConfig),
        showTableConfiguration: selectTableConfigurationOpened(state),
        selectedItems: getSelectedItems(state),
        sortBy: ownProps.browseType && state.browse[ownProps.browseType] ? state.browse[ownProps.browseType].sortBy : null,
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        runAction: (config, record) => dispatch(runAction(config, record)),
        dispatchAnyAction: (actionName, payload) => dispatch({
                type: actionName,
                payload: payload
            }
        ),
        setDefaultConfig: (id, columns) => dispatch(setDefaultConfig(id, columns)),
        getColumns: (id) => dispatch(getColumns(id)),
        openColumnsEditor: () => dispatch(openColumnsEditor()),
        toggleSelection: (id) => dispatch(toggleItem(id)),
        setSorting: (keyword) => dispatch(setSortingAndSearch(keyword, ownProps.browseType)),
    }
};

class BrowseTableComponent extends React.Component {

    componentDidMount() {
        this.props.setDefaultConfig(this.props.tableConfig.id, this.props.tableConfig)

        if (!this.props.visibleColumnsDefined) {
            this.props.getColumns(this.props.tableConfig.id)
        }

    }

    onRowClick(record) {
        if (this.props.tableConfig.onRowClick) {
            if (typeof this.props.tableConfig.onRowClick === "function") {
                this.props.tableConfig.onRowClick(record, this.props.history);
            } else {
                this.props.runAction(this.props.tableConfig.onRowClick, record);
            }
        }
    }

    onCellClick(e, record, columnConfig) {
        if (columnConfig.onClickAction) {
            this.props.dispatchAnyAction(
                columnConfig.onClickAction.actionName,
                columnConfig.onClickAction.preparePayload(record)
            );
            e.stopPropagation();
        }
    }

    onColumnHeaderClick(e, columnConfig) {
        if (columnConfig.hasOwnProperty("sortableKey")) {
            this.props.setSorting(columnConfig.sortableKey);
        }
    }

    render() {
        let columnsMap = {}
        this.props.tableConfig.columns.forEach(column => {
            columnsMap[column.id] = column;
        })

        let header = this.props.visibleColumns.map(i => {
            let columnConfig = columnsMap[i]
            if (columnConfig == undefined) {
                return
            }

            let sortedBy = null;
            if (columnConfig.hasOwnProperty("sortableKey")) {
                if (this.props.sortBy && this.props.sortBy.by == columnConfig.sortableKey) {//is sorted by current column
                    if (this.props.sortBy.direction == "ASC") {
                        sortedBy = <i className="fa fa-sort-up" aria-hidden="true"></i>
                    } else {
                        sortedBy = <i className="fa fa-sort-down" aria-hidden="true"></i>
                    }
                } else {
                    //indicate that column is sortable
                    sortedBy = <i className="fa fa-sort" aria-hidden="true" style={{color: "grey"}}></i>
                }
            }

            return (<th key={columnConfig.id}>
                <Translation>{t =>
                    <span
                        onClick={(e) => this.onColumnHeaderClick(e, columnConfig)}>
                            {t(columnConfig.label)}
                        {sortedBy}
                        </span>
                }</Translation>
            </th>)
        });


        let headerActions = null;

        if (this.props.tableConfig.rowActions) {
            headerActions = (<th key="rowActions">
                <span>

                </span>
            </th>);
        }

        let selectableHeader = null;


        if (this.props.tableConfig.isSelectable) {

            selectableHeader = (<th key="selectableColumn">
                <span>
                    <SelectAllCheckboxComponent/>
                </span>
            </th>);
        }


        let rows = this.props.records && this.props.records.map((record, index) => {
            let recordId = this.props.tableConfig.recordIdentifier(record);
            let row = this.props.visibleColumns.map(columnId => {
                let columnConfig = columnsMap[columnId]
                if (columnConfig == undefined) {
                    return
                }

                let value = null;

                if (columnConfig.renderer) {
                    value = columnConfig.renderer(record)
                } else if (columnConfig.path) {
                    value = getObjectAttr(columnConfig.path, record);
                } else {
                    value = record[columnConfig.id];
                }


                let trustAsHtml = false

                if ((typeof value === 'string' || value instanceof String) && (!columnConfig.allowHtml)) {
                    // value = value.replace(/(<([^>]+)>)/ig, '');
                    trustAsHtml = true
                }

                if (columnConfig.trimToLength && value && value.length > columnConfig.trimToLength + 2) {
                    value = value.replace(/(<([^>]+)>)/ig, '');
                    value = value.substring(0, columnConfig.trimToLength) + "...";
                }

                let style = {}
                if (columnConfig.minWidth) {
                    style.minWidth = columnConfig.minWidth;
                }

                return (
                    trustAsHtml ?
                        <td key={recordId + "_" + columnConfig.id} className={"align-middle "}
                            style={style}
                            onClick={(e) => this.onCellClick(e, record, columnConfig)}
                            dangerouslySetInnerHTML={{__html: value}}>

                        </td>
                        :
                        <td key={recordId + "_" + columnConfig.id} className={"align-middle "}
                            style={style}
                            onClick={(e) => this.onCellClick(e, record, columnConfig)}
                        >
                            {value}
                        </td>
                )
            });


            let actionsCell = null;

            if (this.props.tableConfig.rowActions) {
                actionsCell = (
                    <td key={recordId + "_" + "rowActions"} className={"align-middle"}>
                        <ActionsListComponent actions={this.props.tableConfig.rowActions} record={record} />
                    </td>
                );
            }

            let selectableCell = null;

            if (this.props.tableConfig.isSelectable) {
                let id = this.props.tableConfig.recordIdentifier(record)
                selectableCell = (
                    <td key={recordId + "_" + "selectable"} className={"align-middle"} onClick={event => event.stopPropagation()}>
                        <input type="checkbox" checked={this.props.selectedItems.includes(id)}
                               onClick={event => this.props.toggleSelection(id)}/>
                    </td>
                );
            }


            let cssClass = "";

            if (this.props.tableConfig.rowRendererHelper) {
                for (const [key, value] of Object.entries(this.props.tableConfig.rowRendererHelper(record))) {
                    if (value) {
                        cssClass += key + " ";
                    }
                }

            }


            return (
                <tr key={recordId}
                    className={cssClass}
                    onClick={() => this.onRowClick(record)}>
                    {selectableCell && selectableCell}
                    {row}
                    {actionsCell && actionsCell}
                </tr>
            )
        });


        return (
            <React.Fragment>
                {this.props.showTableConfiguration
                && <ManageTableColumnsComponent tableConfig={this.props.tableConfig}
                                                orderedColumns={this.props.orderedColumns}/>}
                <div>
                    <table className="table table-hover artworks f11">
                        <thead>
                        <tr>
                            {selectableHeader && selectableHeader}
                            {header}
                            {headerActions && headerActions}

                            {
                                this.props.tableConfig.manageColumns &&
                                <th className="open-table-manager">
                                    <i className="fas fa-cog " onClick={() => this.props.openColumnsEditor()}/>
                                </th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BrowseTableComponent))

