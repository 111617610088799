import React from 'react'
import {connect} from "react-redux";
import * as actions from "../../actions/browseActions";
import {DICTIONARY_ENTRIES} from "../browse/BrowseType";
import PaginateWrapperComponent from "../browse/PaginateWrapperComponent";
import BrowseTableComponent from "../../engine/component/BrowseTableComponent";
import {dictionaryEntryTableConfiguration} from "./dictionaryEntryTable.config";
import {Translation} from "react-i18next";
import SearchArtworksComponent from "../browse/SearchArtworksComponent";
import {openClosePickupWindow} from "../../actions/filtersActions";
import {dictionaryEntriesFiltersAvailable} from "./dictionaryEntriesFiltersConfig";
import DictionaryEntriesFiltersSidebar from "./DictionaryEntriesFiltersSidebarComponent";
import {getUserPermissions} from "../../engine/selectors";

const mapStateToProps = (state) => {
    return {
        content: state.browse[DICTIONARY_ENTRIES].results.content,
        canCreate: getUserPermissions(state, "modules", "dictionary_entry").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getEntries: (page) => dispatch(actions.setPageAndSearch(page, DICTIONARY_ENTRIES)),
        openPickUpWindow: (value) => dispatch(openClosePickupWindow(true, dictionaryEntriesFiltersAvailable)),
    }
};

class DictionaryEntriesList extends React.Component {

    componentDidMount() {
        this.props.getEntries(0);
    }

    goToCreateNew() {
        window.location.href = "/dictionary-entry/create";
    }

    render() {

        return (
            <div className="row justify-content-between">
                <sidebar className="col-md-2 artwork-sidebar">
                    <div>
                        <DictionaryEntriesFiltersSidebar/>
                    </div>
                </sidebar>
                <main className="col-md-10 content">
                    <div className="row">
                        <h2>Lista wpisów słownikowych</h2>
                    </div>
                    <SearchArtworksComponent filtersAvailable={dictionaryEntriesFiltersAvailable}
                                             browseType={DICTIONARY_ENTRIES}/>

                    <div className="row d-flex align-items-center">
                        <Translation>{t =>

                            <React.Fragment>
                                <div className="col header-actions-menu">
                                    {this.props.canCreate && <button type="button" className="btn btn-primary"
                                                                     onClick={() => this.goToCreateNew()}>{t("new...")}
                                    </button>}
                                    <button type="button" className="btn btn-outline-primary refresh"
                                            onClick={() => this.props.getEntries(0)}>
                                        <i className="fas fa-sync-alt"></i></button>
                                </div>

                                <div className="col header-actions-menu">

                                </div>

                                <div className="col col header-actions-menu">
                                    <button type="button" className="btn btn-outline-primary"
                                            onClick={() => this.props.openPickUpWindow()}>
                                        <i className="fas fa-filter"></i>
                                    </button>

                                </div>
                            </React.Fragment>
                        }</Translation>
                        <div className="col-md-6 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={DICTIONARY_ENTRIES}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <BrowseTableComponent tableConfig={dictionaryEntryTableConfiguration}
                                                  browseType={DICTIONARY_ENTRIES}
                                                  records={this.props.content}/>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col">
                        </div>
                        <div className="col-md-4 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={DICTIONARY_ENTRIES}/>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntriesList)

