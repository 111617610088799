import React from 'react'
import {connect} from "react-redux";
import BrowseTableComponent from "./BrowseTableComponent";
import {getComponentState} from "../selectors";

const mapStateToProps = (state, ownProps) => {

    return getComponentState(state, ownProps.configId) ? {
        config: state.engine.configsMap[ownProps.configId],
        data: getComponentState(state, ownProps.configId).data,
    } : {};
};
const mapDispatchToProps = (dispatch) => {
    return {

    }
};

class TableComponent extends React.Component {
    render() {
       return (
           <BrowseTableComponent tableConfig={this.props.config.tableConfig}
                                 records={this.props.data}/>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent)

