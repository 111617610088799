import React from 'react'
import {connect} from "react-redux";

import {Translation} from "react-i18next";
import {openClosePickupWindow} from "../../actions/filtersActions";
import DictionariesSubSearchSide from "../browse/DictionariesSubSearchSide";
import {dictionaryEntriesFiltersAvailable} from "./dictionaryEntriesFiltersConfig";
import {DICTIONARY_ENTRIES} from "../browse/BrowseType";


const mapStateToProps = (state) => {
    return state.browse;
};
const mapDispatchToProps = (dispatch) => {
    return {
        openPickUpWindow: () => dispatch(openClosePickupWindow(true, dictionaryEntriesFiltersAvailable)),
    }
};

class DictionaryEntriesFiltersSidebar extends React.Component {

    render() {
        return (
            <Translation>{t =>
                <div>
                    <h3>
                        <span>{t("filters")}</span>

                        <button type="button" className="btn btn-outline-primary btn-sm"
                                onClick={() => this.props.openPickUpWindow()}>
                            <i className="fas fa-filter"></i>
                            <span>{t("more")}</span>
                        </button>
                    </h3>
                    <DictionariesSubSearchSide filterName="dictionary" visibleItems={14}
                                               browseType={DICTIONARY_ENTRIES}/>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEntriesFiltersSidebar)

