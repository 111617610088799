import React from "react";
import {CALL_API_ARTWORK_ACTION} from "../../actions/artworkActions";
import {BACKEND_URL} from "../../config";

export const artworksListTableConfiguration = {
    id: "artworksTableV8",
    manageColumns: true,
    columns: [
        {
            id: "preview",
            label: "preview",
            default: false,
            renderer: (artwork) =>

                artwork.previews && artwork.previews.length > 0 && artwork.previews[0] && artwork.previews[0].ref ?
                    <img className="img-preview-in-table"
                         src={BACKEND_URL + "asset/" + artwork.previews[0].ref.id + "/small/raw"}/> : null
            // <img className="img-preview-in-table" src={"http://pauart.pl/api/assets/" + artwork.previews[0].ref.id + "/small/raw"} /> : null

        },
        {
            id: "inventoryNumber",
            label: "inventoryNumber",
            default: true,
            required: true,
            sortableKey: "tokenizedInventoryNumber",
            minWidth: "170px"
        },
        {
            id: "title",
            label: "title",
            required: true,
            default: true,
            sortableKey: "title",
            minWidth: "300px"
        },
        {
            id: "folders",
            label: "folders",
            required: false,
            default: false,
            renderer: (artwork) => contactListOfDicEntries(artwork.folders),
        },
        {
            id: "author",
            label: "authors",
            default: true,
            minWidth: "150px",
            renderer: (artwork) => contactListAuthors(artwork.authors),
            trimToLength: 36
        },
        {
            id: "cardPreparationAuthor",
            label: "cardPreparation.author",
            sortableKey: "cardPreparation.cardAuthor",
            default: true,
            minWidth: "150px",
            renderer: (artwork) => artwork.cardPreparation && artwork.cardPreparation.cardAuthor ? artwork.cardPreparation.cardAuthor : null
        },
        {
            id: "materialTechnique",
            label: "materialTechnique.techniques",
            default: true,
            renderer: (artwork) => artwork.materialTechnique ? contactListOfDicEntries(artwork.materialTechnique.techniques) : null
        },
        {
            id: "visibleInNavigart",
            label: "Navigart",
            default: true,
            renderer: (artwork) => artwork.visibleInNavigart ? <i className="fas fa-eye" style={{color: "green"}}/> :
                <i className="fas fa-eye-slash" style={{color: "grey"}}/>,
            onClickAction: {
                type: "REACT-DISPATCH",
                actionName: CALL_API_ARTWORK_ACTION,
                preparePayload: (artwork) => {
                    return {artworkId: artwork.id, apiActionName: "toggle-is-in-navigart"}
                },
            }
        },
        {
            id: "exportStatus",
            label: "exportStatus",
            default: true,
            renderer: (artwork) => {
                if (artwork.requiresExport) {
                    if (artwork.visibleInNavigart) {
                        return <i className="fas fa-cloud-upload-alt" style={{color: "blue"}}
                                  title="will be exported"></i>;
                    }

                    return <i className="fas fa-cloud-download-alt" style={{color: "red"}}
                              title="will be unexported"></i>;
                } else {
                    if (artwork.visibleInNavigart) {
                        return <i className="fas fa-check" style={{color:"green"}} title="is up to date"/>;
                    }

                    return <i className="fas fa-check" style={{color:"grey"}} title="is up to date"/>;
                }

                return null;
            },
            onClickAction: {
                type: "REACT-DISPATCH",
                actionName: CALL_API_ARTWORK_ACTION,
                preparePayload: (artwork) => {
                    return {artworkId: artwork.id, apiActionName: "reexport"}
                },
            }
        },
        {
            id: "iconclass",
            label: "iconclass",
            default: true,
            renderer: (artwork) => contactListOfDicEntries(artwork.iconclass)
        },
        {
            id: "author.autorTekstu",
            label: "author.autorTekstu",
            renderer: (artwork) => artwork.author ? contactListPersons(artwork.author.autorTekstu) : null
        },
        {
            id: "author.autorWzoruBezposredniego",
            label: "author.autorWzoruBezposredniego",
            renderer: (artwork) => artwork.author ? contactListPersons(artwork.author.autorWzoruBezposredniego) : null
        },
        {
            id: "author.autorWzoruPosredniego",
            label: "author.autorWzoruPosredniego",
            renderer: (artwork) => artwork.author ? contactListPersons(artwork.author.autorWzoruPosredniego) : null
        },
        {
            id: "author.wydawca",
            label: "author.wydawca",
            renderer: (artwork) => artwork.author ? contactListPersons(artwork.author.wydawca) : null
        },
        {
            id: "inscription.inscription",
            label: "inscription.inscription",
            renderer: (artwork) => artwork.inscription ? artwork.inscription.inscription : null,
            trimToLength: 36,
        }, {
            id: "project",
            label: "project",
            renderer: (artwork) => contactListOfDicEntries(artwork.projects),
            trimToLength: 36
        },
        {
            id: "location",
            label: "location",
            renderer: (artwork) => contactListOfDicEntries(artwork.location),
            trimToLength: 36
        },
        {
            id: "materialTechnique.filigran",
            label: "materialTechnique.filigran",
            renderer: (artwork) => artwork.materialTechnique ? contactListOfDicEntries(artwork.materialTechnique.filigran) : null
        },
        {
            id: "materialTechnique.materials",
            label: "materialTechnique.materials",
            renderer: (artwork) => artwork.materialTechnique ? contactListOfDicEntries(artwork.materialTechnique.materials) : null
        },
        {
            id: "placeOfCreation.comment",
            label: "placeOfCreation.comment",
            renderer: (artwork) => artwork.placeOfCreation ? artwork.placeOfCreation.comment : null,
            trimToLength: 36
        },
        {
            id: "placeOfCreation.country",
            label: "placeOfCreation.country",
            renderer: (artwork) => artwork.placeOfCreation ? contactListOfDicEntries(artwork.placeOfCreation.country) : null,
            trimToLength: 36
        },
        {
            id: "placeOfCreation.city",
            label: "placeOfCreation.city",
            renderer: (artwork) => artwork.placeOfCreation ? contactListOfDicEntries(artwork.placeOfCreation.city) : null,
            trimToLength: 36
        },
        {
            id: "objectTypes",
            label: "objectTypes",
            renderer: (artwork) => contactListOfDicEntries(artwork.objectTypes),
            trimToLength: 36
        },
        {
            id: "description.references",
            label: "description.references",
            renderer: (artwork) => artwork.description ? contactListOfDicEntries(artwork.description.references) : null,
            trimToLength: 50
        },
        {
            id: "description.description",
            label: "description.description",
            renderer: (artwork) => artwork.description ? artwork.description.description : null,
            trimToLength: 80
        },
        {
            id: "description.tags",
            label: "description.tags",
            renderer: (artwork) => artwork.description ? contactListOfDicEntries(artwork.description.references) : null,
            trimToLength: 50
        },
        {
            id: "cardPreparation.authoredDate",
            label: "cardPreparation.authoredDate",
            renderer: (artwork) => artwork.cardPreparation && artwork.cardPreparation.cardAuthoredDate ? renderPauDate(artwork.cardPreparation.cardAuthoredDate.from) : null
        },
        {
            id: "cardPreparation.approveDate",
            label: "cardPreparation.approveDate",
            renderer: (artwork) => artwork.cardPreparation && artwork.cardPreparation.approvedDate ? renderPauDate(artwork.cardPreparation.approvedDate.from) : null
        },
        {
            id: "cardPreparation.approver",
            label: "cardPreparation.approver",
            renderer: (artwork) => getAttr(artwork.cardPreparation, "approver")
        },
        {
            id: "copyright",
            label: "copyright",
            renderer: (artwork) => artwork.copyright
        },
        {
            id: "provenance",
            label: "provenance",
            renderer: (artwork) => contactListOfDicEntries(artwork.provenance),
            trimToLength: 36
        },
        {
            id: "storage",
            label: "storage",
            trimToLength: 36
        },
        {
            id: "dimensions",
            label: "dimensions",
            trimToLength: 36
        },
        {
            id: "ownership",
            label: "ownership",
            renderer: (artwork) => contactListOfDicEntries(artwork.ownership),
            trimToLength: 36
        },
        {
            id: "dateOfCreation",
            label: "dateOfCreation",
            renderer: (artwork) => {
                if (artwork.dateOfCreation && artwork.dateOfCreation.entry) {
                    return artwork.dateOfCreation.entry.labels.pl
                }
            }
        },
        {
            id: "system.dateCreated",
            label: "system.dateCreated",
            renderer: (artwork) => getAttr(artwork._system, "dateCreated")
        },
        {
            id: "system.dateModified",
            label: "system.dateModified",
            renderer: (artwork) => getAttr(artwork._system, "dateModified")
        },
        {
            id: "system.modifier",
            label: "system.modifier",
            renderer: (artwork) => getAttr(artwork._system, "modifier")
        },
        {
            id: "system.creator",
            label: "system.creator",
            renderer: (artwork) => getAttr(artwork._system, "creator")
        },
    ],
    isSelectable: true,
    recordIdentifier: (artwork) => artwork.id,
    onRowClick: (artwork, history) => history.push("/artwork/edit/" + artwork.id),
    rowRendererHelper: (artwork) => {
        return {"deleted": artwork.deleted}
    }
}


function renderPauDate(date) {
    if (!date) return null

    let str = "";

    if (date.year) {
        str += (date.year).toString()
        if (date.month) {
            str += "-" + (date.month).toString().padStart(2, '0')
            if (date.day) {
                str += "-" + (date.day).toString().padStart(2, '0')
            }
        }
    }

    return str
}

function getAttr(object, attr) {
    return object && object.hasOwnProperty(attr) ? object[attr] : null
}

export function contactListOfDicEntries(entries) {
    if (!Array.isArray(entries)) {
        return null
    }

    return entries
        .filter(e => e !== null)
        .map(e => e.labels && e.labels.pl ? e.labels.pl : e.id)
        .join(', ')
}

export function contactListPersons(entries) {
    if (!Array.isArray(entries)) {
        return null
    }

    return entries
        .map(e => e.name ? e.name : e.id)
        .join(',')
}

export function contactListAuthors(entries) {
    if (!Array.isArray(entries)) {
        return null
    }

    return entries
        .filter(e => e != null && e.author)
        .map(e => e.author.name ? e.author.name : e.author.id)
        .join(', ')
}