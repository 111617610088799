import React from 'react'
import {connect} from "react-redux";
import BrowseTableComponent from "./BrowseTableComponent";
import ReactPaginate from "react-paginate";
import {runAction} from "../actions/engine.actions";
import FormComponent from "./FormComponent";
import {findConfigRecursive, getComponentState, selectConfigById} from "../selectors";
import {openEdit} from "../actions/engine.manage.actions";
import PaginateWrapperComponent from "../../components/browse/PaginateWrapperComponent";
import {ARTWORKS} from "../../components/browse/BrowseType";

const mapStateToProps = (state, ownProps) => {

    return {
        config: state.engine.configsMap[ownProps.configId],
        data: getComponentState(state, ownProps.configId).data,
        totalPages: getComponentState(state, ownProps.configId).pagination.totalPages,
        pageNumber: getComponentState(state, ownProps.configId).pagination.pageNumber,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config) => dispatch(runAction(config)),
        openEdit: (config, sampleData) => dispatch(openEdit(config, sampleData))
    }
};

class AjaxTableComponent extends React.Component {


    setPageAndSearch(pageNumber) {
        this.props.runAction(getPage(this.props.config, pageNumber));
    }

    componentDidMount() {
        if (this.props.config) {
            this.props.runAction(prepareConfigForFetching(this.props.config));
        }
    }

    render() {
        let searchForm = null;
        if (this.props.config.searchForm) {
            searchForm = (
                <FormComponent config={getFormConfig(this.props.config)}/>
            );


        }


        return (
            <React.Fragment>
                {/*<span onClick={() => this.props.openEdit(this.props.config, this.props.data)}>edit</span>*/}
                {searchForm}

                <div className="row">

                    <div className="col header-actions-menu">
                        <button type="button" className="btn btn-primary">New...</button>

                    </div>
                    <div className="col-md-4 pagination-react-paginate">
                        <ReactPaginate pageCount={this.props.totalPages}
                                       pageRangeDisplayed={3}
                                       marginPagesDisplayed={3}
                                       forcePage={this.props.pageNumber}
                                       onPageChange={(page) => this.setPageAndSearch(page.selected)}
                                       previousLabel="&laquo;"
                                       nextLabel="&raquo;"
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col table-responsive">
                <BrowseTableComponent tableConfig={this.props.config.tableConfig}
                                      records={this.props.data}/>
                    </div>
                </div>


                <div className="row">

                    <div className="col">
                    </div>
                    <div className="col-md-4 pagination-react-paginate">
                        <ReactPaginate pageCount={this.props.totalPages}
                                       pageRangeDisplayed={3}
                                       marginPagesDisplayed={3}
                                       forcePage={this.props.pageNumber}
                                       onPageChange={(page) => this.setPageAndSearch(page.selected)}
                                       previousLabel="&laquo;"
                                       nextLabel="&raquo;"
                        />
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

let getFormConfig = (componentConfig) => {
    return {
        id: componentConfig.id + "_SearchForm",
        fields: componentConfig.searchForm.fields,
        submit: {
            label: "Search!"
        },
        submitAction: getPage(componentConfig, 0)
    }
};


let getPage = (componentConfig, pageNumber) => {
    return {
        type: "set_pagination_page",
        componentId: componentConfig.id,
        pagination: {
            pageNumber: pageNumber,
        },
        children: [
            prepareConfigForFetching(componentConfig)
        ]
    }
}

let prepareConfigForFetching = (componentConfig) => {
    //todo: block/unblock current component when searching
    return {
        type: "block-component",
        componentId: componentConfig.id,
        children: [
            {
                type: "call-rest-post",
                url: componentConfig.dataSource.url,
                setResponseToComponent: componentConfig.id,
                addPagingDataFromComponent: componentConfig.id,
                mergeDataFromForm: componentConfig.searchForm ? componentConfig.id + "_SearchForm" : null,
                isPaged: true,
                successAction: {
                    children: [
                        {
                            type: "unblock-component",
                            componentId: componentConfig.id
                        }
                    ]
                },
                errorAction: {
                    children: [
                        {
                            type: "unblock-component",
                            componentId: componentConfig.id
                        }
                    ]
                }
            }
        ]
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AjaxTableComponent)

