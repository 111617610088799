import React from 'react'
import {connect} from "react-redux";

import ReactPaginate from 'react-paginate';
import * as actions from "../../actions/browseActions";

const mapStateToProps = (state, ownProps) => {
    return {
        totalPages: state["browse"][ownProps.browseType].results.totalPages,
        totalElements: state["browse"][ownProps.browseType].results.totalElements,
        size: state["browse"][ownProps.browseType].results.size,
        number: state["browse"] [ownProps.browseType].results.number
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPage: (page) => dispatch(actions.setPageAndSearch(page, ownProps.browseType))
    }
};

class PaginateWrapperComponent extends React.Component {

    _handleKeyDown = (e) => {
        let val = parseInt(e.target.value)
        if (e.key === 'Enter') {
            if (typeof val == 'number' && val > 0 && val <= this.props.totalPages) {
                this.props.getPage(val - 1)
                e.target.value = null;
            }
        }
    }

    render() {

        let countersReady = this.props.size && this.props.size > 0

        let firstItemNo = (this.props.size * this.props.number) + 1
        let lastItem = this.props.size * (this.props.number + 1)
        if (lastItem > this.props.totalElements) {
            lastItem = this.props.totalElements
        }

        let counter = <span style={{color: "#6c757d"}}>{firstItemNo}-{lastItem}/{this.props.totalElements}</span>

        return (

            <React.Fragment>

                {this.props.showCounter && countersReady && counter}

                {this.props.showCounter && countersReady &&
                <input type="text"
                       placeholder={this.props.number + 1}
                       onKeyDown={this._handleKeyDown}
                       style={{color: "#6c757d", borderColor: "#6c757d", width: 48, marginLeft: "20px"}}
                ></input>
                }

                <ReactPaginate pageCount={this.props.totalPages}
                               pageRangeDisplayed={4}
                               marginPagesDisplayed={1}
                               forcePage={this.props.number}
                               onPageChange={(page) => this.props.getPage(page.selected)}
                               onPageActive={(page) => alert(page)}
                               previousLabel="&laquo;"
                               nextLabel="&raquo;"
                />
            </React.Fragment>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginateWrapperComponent)

