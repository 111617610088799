import React from 'react'
import {connect} from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import {getPersonsByName} from "../../api/person.api";
import {openCreatePersonModal} from "../../actions/personActions";
import {toSelectizePerson} from "../../util/personUtils";


const mapStateToProps = (state, ownProps) => {
    return {
        field: dbToViewMapper(ownProps.records, ownProps.mappingToDbPath)
    }
};

const mapNewObjectAfterCreation = (person, mappingToDbPath) => {

    if (!mappingToDbPath) {
        return person;
    }

    let wrapper = {};
    wrapper[mappingToDbPath] = person;

    return wrapper;
}

const mapDispatchToProps = (dispatch) => {
    return {
        openCreatePersonModal: (name, setterAction, records, mappingToDbPath) => dispatch(openCreatePersonModal(name,
            (person) => {
                setterAction([...records, mapNewObjectAfterCreation(person, mappingToDbPath)])
            }
        ))
    }
};

const viewToDbMapperForPerson = (path, inputValue) => {
    if (!inputValue) {
        return [];
    }

    if (path) {
        return inputValue.map((item) => {
            let obj = {};
            obj[path] = item.person;
            return obj;
        });
    }

    return inputValue.map((item) => item.person);
};

const dbToViewMapper = (records, mappingToDbPath) => {
    return records.map((record) => getPersonFromRecord(record, mappingToDbPath));
};

const getPersonFromRecord = (record, mappingToDbPath) => {
    if (!record) {
        return null;
    }

    let person = mappingToDbPath ? record[mappingToDbPath] : record;

    if (!person) {
        return null;
    }

    return toSelectizePerson(person);
};

const promiseOptions = inputValue => {
    return getPersonsByName(inputValue);
};

class PersonEditableField extends React.Component {

    handleChange = (inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length-1].label;
                this.props.openCreatePersonModal(label, this.props.setterAction, this.props.records, this.props.mappingToDbPath);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setterAction(viewToDbMapperForPerson(this.props.mappingToDbPath, inputValue));
            default:
                break;
        }

    };

    render() {
        return (
               <AsyncCreatableSelect
                   components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                   formatOptionLabel={function (data) {
                       return (
                           <span dangerouslySetInnerHTML={{__html: data.label}}/>
                       );
                   }}
                   placeholder={""}
                   value={this.props.field}
                   onChange={this.handleChange}
                   isDisabled={this.props.isDisabled}
                   isMulti={true}
                   loadOptions={promiseOptions}
               />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonEditableField)