import {call, put} from 'redux-saga/effects';
import {TRACK_ACTIVITY, TRACK_SET_RECORDS} from "./trackingDuck";
import {getLatestUsersActivity} from "./tracking.api";

export function* getTracking() {
    let records = yield call(getLatestUsersActivity);

    yield put({
        type: TRACK_SET_RECORDS,
        payload: {records}
    });

}

function* track(record) {

    console.log(record);

    yield put({
        type: TRACK_ACTIVITY,
        payload: {record}
    });

}
