import React from 'react'
import {connect} from "react-redux";
import {BACKEND_URL} from "../../config";
import ReactCrop from "react-image-crop";
import {closeCrop, saveCrop} from "../../actions/cropActions";
import {scaleCrop, prepareInitialCrop} from "./crop-dimensions-utils";


const mapStateToProps = (state) => {
    return {
        asset: state.crop.asset,
        artworkId: state.crop.artworkId,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        closeCrop: () => dispatch(closeCrop()),
        saveCrop: (crop, assetId, artworkId) => dispatch(saveCrop(crop, assetId, artworkId))
    }
};

const initialCropSettings = {
    aspect: 1,
    unit: "px",
}

class CropComponent extends React.Component {
    state = {
        src: null,
        crop: {},
    };

    imageRef = null;

    onCropChange = (crop, percentCrop) => {
        this.setState({crop});
    };

    onImageLoaded = image => {
        this.imageRef = image;

        let cropOverride = this.props.asset.thumbnails["square_cutout_small"].cropOverride;

        let crop = prepareInitialCrop(image, initialCropSettings, cropOverride)

        this.setState({crop: crop});
        return false; // Return false when setting crop state in here.
    };

    save(crop) {
        let scale = this.imageRef.naturalWidth / this.imageRef.width;
        let scaledCrop = scaleCrop(scale, crop);

        this.props.saveCrop(scaledCrop, this.props.asset.id, this.props.artworkId);
    }

    render() {
        if (!this.props.asset) {
            return null;
        }

        const {crop} = this.state;

        let huge = BACKEND_URL + "asset/" + this.props.asset.id + "/huge/raw";

        return (
            <React.Fragment>
                <div className="overlay">
                    <div className="overlay-content">
                        <div className="footer">
                            <button type="button" className="btn btn-secondary" data-id="close-crop-action"
                                    onClick={() => this.props.closeCrop()}>Anuluj
                            </button>
                            <button type="button" className="btn btn-primary" data-id="save-crop-action"
                                    onClick={() => this.save(crop)}>Zapisz
                            </button>
                        </div>
                        <div>
                            <ReactCrop src={huge} crop={crop} onImageLoaded={this.onImageLoaded}
                                       onChange={this.onCropChange}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CropComponent)

