import React from 'react'
import {connect} from "react-redux";
import {getComponentState} from "../selectors";
import {isVisible} from "../constraint/checkVisibility";
import {Translation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    let config = state.engine.configsMap[ownProps.configId]
    return getComponentState(state, ownProps.configId) ? {
        // visibility: getComponentState(state, ownProps.configId).visibility,
        config: config,
        isVisible: isVisible(config, state),
        blocked: getComponentState(state, ownProps.configId).blocked,
    } : {};
};
const mapDispatchToProps = (dispatch) => {
    return {}
};

class HeaderComponent extends React.Component {

    render() {

        if (!this.props.isVisible) {
            return null;
        }

        let header = <h3><Translation>{t => t(this.props.config.content)}</Translation></h3>;

        if (this.props.config.size) {
            if (this.props.config.size == 5) {
                header = <h5><Translation>{t => t(this.props.config.content)}</Translation></h5>;
            }


        }

        return header;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

