// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";
import asyncFetch from "../api/async-fetch";
import {ARTWORKS} from "../components/browse/BrowseType";
import {getSelectedItems, SELECT_NONE} from "./selectionDuck";
import {LOADING_START, LOADING_STOP} from "../loading/loadingDuck";
import {setPageAndSearch} from "../actions/browseActions";
import {getDictionaryEntries} from "../api/dictionaryEntry.api";
import {fetchFoldersREST} from "../api/folders.api";

export const BULK_ADD_REMOVE_NAVIGART = "BULK_ADD_REMOVE_NAVIGART"
export const BULK_ADD_REMOVE_PROJECT = "BULK_ADD_REMOVE_PROJECT"
export const BULK_ADD_REMOVE_FOLDER = "BULK_ADD_REMOVE_FOLDER"
export const BULK_ACTIONS_PREPARE_DATA_FOR_COMPONENT = "BULK_ACTIONS_PREPARE_DATA_FOR_COMPONENT"
export const BULK_ACTIONS_SET_PROJECTS = "BULK_ACTIONS_SET_PROJECTS"
export const BULK_ACTIONS_SET_FOLDERS = "BULK_ACTIONS_SET_FOLDERS"
export const BULK_OPERATION_TYPE_ADD_REMOVE = {
    ADD: "ADD",
    REMOVE: "REMOVE"
}
export const BULK_PRINT_TO_FILE = "BULK_PRINT_TO_FILE"

export const bulkAddRemoveToNavigart = (operationType) => ({
    type: BULK_ADD_REMOVE_NAVIGART,
    payload: {operationType}
})

export const bulkAddRemoveProject = (projectId, operationType) => ({
    type: BULK_ADD_REMOVE_PROJECT,
    payload: {projectId, operationType}
})

export const bulkAddRemoveFolder = (folderId, operationType) => ({
    type: BULK_ADD_REMOVE_FOLDER,
    payload: {folderId, operationType}
})

export const bulkPrintToFile = () => ({
    type: BULK_PRINT_TO_FILE,
    payload: {}
})

export const prepareDataForComponent = () => ({
    type: BULK_ACTIONS_PREPARE_DATA_FOR_COMPONENT,
})


let initialState = {
    projects: [],
    folders: [],
}


export default function bulkActionsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case BULK_ACTIONS_SET_PROJECTS:
            return {...state, projects: action.payload.projects}
        case BULK_ACTIONS_SET_FOLDERS:
            return {...state, folders: action.payload.folders}
        default:
            return state
    }
}

export const getProjectsForBulkActions = (state) => state.bulkActions ? state.bulkActions.projects : []
export const getFoldersForBulkActions = (state) => state.bulkActions ? state.bulkActions.folders : []

export function* bulkActionPrepareDataForComponent(action) {
    try {
        let projects = yield select(state => getProjectsForBulkActions(state))

        if (projects.length == 0) {
            projects = yield call(getDictionaryEntries, "projects");

            yield put({
                type: BULK_ACTIONS_SET_PROJECTS,
                payload: {projects}
            });
        }


        let folders = yield select(state => getFoldersForBulkActions(state))

        if (folders.length == 0) {
            folders = yield call(fetchFoldersREST);

            yield put({
                type: BULK_ACTIONS_SET_FOLDERS,
                payload: {folders}
            });
        }

    } catch (e) {

        toast.error("Wystąpił błąd podczas ładowania listy projektów");
    }

}

export function* bulkAddRemoveToNavigartSaga(action) {

    yield put({
        type: LOADING_START,
    });

    try {
        let selected = yield select(state => getSelectedItems(state))

        yield call(bulkToggleNavigartREST, selected, action.payload.operationType);

        yield put({
            type: SELECT_NONE,
        });

        yield put(setPageAndSearch(0, ARTWORKS));

        yield put({
            type: LOADING_STOP,
        });
        toast.success("Zapisano zmiany");

    } catch (e) {

        yield put({
            type: LOADING_STOP,
        });

        toast.error("Wystąpił błąd podczas zapisu");
    }

}


export function* bulkAddRemoveProjectSaga(action) {

    yield put({
        type: LOADING_START,
    });

    try {
        let selected = yield select(state => getSelectedItems(state))

        yield call(bulkToggleProjectREST, selected, action.payload.projectId, action.payload.operationType);

        yield put({
            type: SELECT_NONE,
        });

        yield put(setPageAndSearch(0, ARTWORKS));

        yield put({
            type: LOADING_STOP,
        });
        toast.success("Zapisano zmiany");

    } catch (e) {

        yield put({
            type: LOADING_STOP,
        });

        toast.error("Wystąpił błąd podczas zapisu");
    }

}


export function* bulkAddRemoveFolderSaga(action) {

    yield put({
        type: LOADING_START,
    });

    try {
        let selected = yield select(state => getSelectedItems(state))

        yield call(bulkToggleFolderREST, selected, action.payload.folderId, action.payload.operationType);

        yield put({
            type: LOADING_STOP,
        });
        toast.success("Rozpoczęto wydruk");

    } catch (e) {

        yield put({
            type: LOADING_STOP,
        });

        toast.error("Wystąpił błąd podczas wydruku");
    }
}

export function* bulkPrintSaga(action) {

    yield put({
        type: LOADING_START,
    });

    try {
        let selected = yield select(state => getSelectedItems(state))

        yield call(bulkPrintArtworkRest, selected, action.payload.folderId, action.payload.operationType);

        yield put({
            type: SELECT_NONE,
        });

        yield put({
            type: LOADING_STOP,
        });
        toast.success("Zapisano zmiany");
    } catch (e) {

        yield put({
            type: LOADING_STOP,
        });

        toast.error("Wystąpił błąd podczas zapisu");
    }
}

export async function bulkToggleNavigartREST(artworks, BULK_OPERATION_TYPE_ADD_REMOVE) {
    let url = BACKEND_URL + 'artwork/bulk/navigart/' + BULK_OPERATION_TYPE_ADD_REMOVE;

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(artworks)};

    await asyncFetch(url, requestConfig);
}

export async function bulkToggleProjectREST(artworks, projectId, BULK_OPERATION_TYPE_ADD_REMOVE) {
    let url = BACKEND_URL + 'artwork/bulk/project/' + projectId + '/' + BULK_OPERATION_TYPE_ADD_REMOVE;

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(artworks)};

    await asyncFetch(url, requestConfig);
}

export async function bulkToggleFolderREST(artworks, folderId, BULK_OPERATION_TYPE_ADD_REMOVE) {
    let url = BACKEND_URL + 'artwork/bulk/folder/' + folderId + '/' + BULK_OPERATION_TYPE_ADD_REMOVE;

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(artworks)};

    await asyncFetch(url, requestConfig);
}


export async function bulkPrintArtworkRest(artworks) {
    let url = BACKEND_URL + 'artwork/bulk/print';

    const baseRequestConfig = getBaseRequestConfig("POST");

    const requestConfig = {...baseRequestConfig, body: JSON.stringify(artworks)};

    await asyncFetch(url, requestConfig);
}
