import {call, put} from 'redux-saga/effects';
import * as actions from "../actions/foldersActions";
import {fetchFoldersREST} from "../api/folders.api";
import {getDictionaryEntries} from "../api/dictionaryEntry.api";

export function *getFolders(action) {

    try {
        yield put({
            type: actions.FOLDERS_GET_DATA_REQUESTED,
        });

        let folders = yield call(fetchFoldersREST);

        yield put({
            type: actions.FOLDERS_GET_DATA_DONE,
            payload: folders
        });

    } catch (e) {
        yield put({
            type: actions.FOLDERS_GET_DATA_FAILED
        });
    }
}
