export const FIELD_IS_EQUAL = "FIELD_IS_EQUAL";

export const IS_EQUAL = "IS_EQUAL";

export const fieldIsEqualImpl = (qualifierDef, baseObject) => {
    return baseObject[qualifierDef.options.field] === qualifierDef.options.value;
};

export const isEqualImpl = (qualifierDef, baseObject) => {
    return baseObject === qualifierDef.options.value;
};