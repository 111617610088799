import DictionaryEntriesList from "../components/dictionary-entry/DictionaryEntriesListComponent";
import DictionaryEntryEditComponent from "../components/dictionary-entry/DictionaryEntryEditComponent";

export const dictionaryEntriesScreen = {
    id: "dictionaryEntriesScreen",
    type: "custom-component",
    component: DictionaryEntriesList,
    route: {
        path: "/dictionary-entry",
        exact: true
    }
};

export const dictionaryEntriesEditScreen = {
    id: "dictionaryEntriesEditScreen",
    type: "custom-component",
    component: DictionaryEntryEditComponent,
    route: {
        path: "/dictionary-entry/edit/:id",
        exact: true
    }
}

export const dictionaryEntriesCreateScreen = {
    id: "dictionaryEntriesCreateScreen",
    type: "custom-component",
    component: DictionaryEntryEditComponent,
    route: {
        path: "/dictionary-entry/create",
        exact: true
    }
}