import * as actions from '../actions/filtersActions'


const initialState = {
    filterValues: {},
    editFilter: null,
    currentFilterValue: null,
    pickUpFilterOpened: false,
    availableOptions: {},
    expandedItems: {},
    filtersAvailable: []
};

export default function filters(state = initialState, action) {
    switch (action.type) {
        case actions.FILTERS_SET_PARAM:
            return setFilterValue(state, action.payload)

        case actions.SET_EDIT_FILTER: {
            return setEditFilter(state, action.payload)
        }

        case actions.SET_CURRENT_FILTER: {
            return {...state, currentFilterValue: action.payload.value}
        }

        case actions.OPEN_CLOSE_PICKUP_WINDOW: {
            return {
                ...state,
                pickUpFilterOpened: action.payload.opened,
                filtersAvailable: action.payload.opened ? action.payload.filtersAvailable : []
            }
        }

        case actions.FILTER_SET_VALUES: {
            return {...state, filterValues: action.payload}
        }

        case actions.FILTER_SET_AVAILABLE_OPTIONS:
            return setAvailableOptions(state, action.payload)

        case actions.FILTER_TOGGLE:
            return toggleFilter(state, action.payload)

        default:
            return state;
    }
}

function setFilterValue(state, payload) {
    let {param, value} = payload;

    let newFilterValues = {...state.filterValues}

    if (value != null) {
        newFilterValues[param] = {
            value: value
        }
    } else {
        delete newFilterValues[param]
    }

    return {...state, filterValues: {...newFilterValues}};
}

function setEditFilter(state, payload) {
    let {fieldConfig} = payload

    let currentValue = null

    if (fieldConfig) {
        currentValue = state.filterValues[fieldConfig.id] ? state.filterValues[fieldConfig.id].value : null
    }
    return {...state, editFilter: fieldConfig, currentFilterValue: currentValue}
}

function setAvailableOptions(state, payload) {
    let availableOptions = {...state.availableOptions};
    let {dictionaryId, options} = payload;
    availableOptions[dictionaryId] = options;

    return {...state, availableOptions: availableOptions}
}

function toggleFilter(state, payload) {
    let expandedItems = {...state.expandedItems};
    let {filterId} = payload;
    expandedItems[filterId] = !expandedItems[filterId];

    return {...state, expandedItems: expandedItems}
}

export const getFilterValue = (state, filterId) => state.filters.filterValues[filterId] ? state.filters.filterValues[filterId].value : null
export const getFilterValues = (state) => state.filters.filterValues
export const getSearchFilterToEdit = (state) => state.filters.editFilter
export const getCurrentSearchFilterToEdit = (state) => state.filters.currentFilterValue
export const isPickUpFilterWindowOpened = (state) => state.filters.pickUpFilterOpened
export const getFilterAvailableOptions = (state, dictionaryId) => state.filters.availableOptions && state.filters.availableOptions[dictionaryId] ? state.filters.availableOptions[dictionaryId] : []
export const getFilterIsExpanded = (state, filterId) => state.filters.expandedItems && state.filters.expandedItems[filterId]
