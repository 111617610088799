import {put} from "redux-saga/effects";
import * as actions from "../engine/actions/engine.actions";
import {initAppConfig} from "../config/app/initApp.config";

export function *logout(action) {
    window.sessionStorage.accessToken = null;

    yield put({
        type: actions.ENGINE_SET_DATA_TO_COMPONENT,
        payload: {
            componentId: initAppConfig.setResponseToComponent,
            response: null
        }
    });
}

