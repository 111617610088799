import React from 'react'
import {connect} from "react-redux";
import * as actions from "../../actions/assetActions";
import {openModalPreview} from "../../actions/assetActions";
import * as cropActions from "../../actions/cropActions";
import {BACKEND_URL} from "../../config";

const mapStateToProps = (state, ownProps) => {
    return {}
        ;
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        removeAsset: (assetId, artworkId) => dispatch(actions.removeAsset(assetId, artworkId, ownProps.type)),
        cropAsset: (asset, artworkId) => dispatch(cropActions.openCrop(asset, artworkId)),
        openModalPreview: (asset, thumbnailSize) => dispatch(openModalPreview(asset, thumbnailSize))
    };
};

class AssetPreview extends React.Component {

    render() {
        let small = BACKEND_URL + "asset/" + this.props.asset.id + "/medium/raw";
        let fileName = this.props.asset.filename;

        if (this.props.asset.refresh) {
            small = small + "?refresh=" + this.props.asset.refresh;
        }

        // small = "http://pauart.pl/api/assets/" + this.props.asset.oldId + "/medium/raw"

        return (<div className="asset-preview">
            <div className="image-viewer">
                <img className="img-responsive small-preview" src={small}
                     onClick={() => this.props.openModalPreview(this.props.asset, "huge")}/>
                <span className="filename">
                    {this.props.asset.refresh}{fileName}
                </span>
            </div>
            <div className="actions">
                <button className="btn btn-outline-primary"
                   onClick={() => this.props.removeAsset(this.props.asset.id, this.props.artworkId)}>
                    <i className="far fa-trash-alt"></i>
                </button>
                {this.props.position === 0 && <button className="btn btn-outline-primary" data-id="crop-asset-action"
                   onClick={() => this.props.cropAsset(this.props.asset, this.props.artworkId)}>
                    <i className="fas fa-crop"></i>
                </button>}
                <button className="btn btn-outline-primary arrows-sign">
                    <i className="fas fa-arrows-alt-v"></i>
                </button>
            </div>
        </div>);
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AssetPreview)