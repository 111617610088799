import {call, put} from 'redux-saga/effects';
import {
    callArtworkREST,
    getArtworkChildrenFromREST,
    getArtworkFromREST,
    isInventoryNumberUnique,
    removeChildREST,
    updateArtworkREST
} from "../api/artwork.api";
import * as actions from "../actions/artworkActions";
import {getArtworkChildren} from "../actions/artworkActions";
import {BROWSE_REPLACE_RECORD_ON_BROWSE_TABLE} from "../actions/browseActions";
import {ARTWORKS} from "../components/browse/BrowseType";
import {LOADING_START, LOADING_STOP} from "../loading/loadingDuck";
import {toast} from "react-toastify";

export function *getArtwork(action) {

    try {
        yield put({
            type: LOADING_START,
        });

        let {artworkId, clone} = action.payload;

        let artwork = yield call(getArtworkFromREST, artworkId);
        if (clone) {
            artwork.id = null;
        }

        yield put({
            type: actions.ARTWORK_DETAILS_GET_DATA_DONE,
            payload: artwork
        });

        yield put({
            type: LOADING_STOP,
        });

    } catch (e) {
        yield put({
            type: actions.ARTWORK_DETAILS_GET_DATA_FAILED,
            payload: action.payload
        });

        yield put({
            type: LOADING_STOP,
        });

    }
}

export function* getArtworkChildrenSaga(action) {

    try {
        let {artworkId} = action.payload;

        let artwork = yield call(getArtworkChildrenFromREST, artworkId);

        yield put({
            type: actions.ARTWORK_CHILDREN_LOAD_DONE,
            payload: artwork
        });

    } catch (e) {
        yield put({
            type: actions.ARTWORK_CHILDREN_LOAD_FAILED,
            payload: action.payload
        });

    }
}


export function *getArtworkParent(action) {

    try {
        let {artworkId} = action.payload;

        let artwork = yield call(getArtworkFromREST, artworkId);

        yield put({
            type: actions.ARTWORK_PARENT_LOAD_DONE,
            payload: artwork
        });

    } catch (e) {
        yield put({
            type: actions.ARTWORK_PARENT_LOAD_FAILED,
            payload: action.payload
        });

    }
}

export function *updateArtwork(action) {

    try {
        yield put({
            type: LOADING_START,
        });

        const artwork = yield call(updateArtworkREST, action.payload);

        yield put({
            type: LOADING_STOP,
        });

        yield put({
            type: actions.ARTWORK_UPDATE_DONE,
            payload: artwork
        });

        toast.success("Zapisano zmiany w rekordzie: " + artwork.id);

        if (window.location.href.indexOf("artwork/edit/"+artwork.id) < 0) {
            window.location.href = "/artwork/edit/" + artwork.id
        }

    } catch (e) {

        yield put({
            type: LOADING_STOP,
        });

        yield put({
            type: actions.ARTWORK_UPDATE_FAILED
        });

        toast.error("Wystąpił błąd podczas zapisu");

    }
}

export function *callArtworkApi(action) {

    try {


        const artwork = yield call(callArtworkREST, action.payload.apiActionName, action.payload.artworkId);

        yield put({
            type: BROWSE_REPLACE_RECORD_ON_BROWSE_TABLE,
            payload: {
                browseType: ARTWORKS,
                record: artwork,
                findCallback: (candidate) => candidate.id === artwork.id,
            }
        });


    } catch (e) {



    }
}

export function* validateInventoryNumber(action) {

    let {value} = action.payload

    if (!value?.trim().length > 0) {
        yield put({
            type: actions.ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT,
            payload: {
                path: "inventoryNumber",
                message: "inventoryNumberIsEmpty",
            }
        });

        return
    }

    let isUnique = yield call(isInventoryNumberUnique, action.payload.artworkId, action.payload.value);

    yield put({
        type: actions.ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT,
        payload: {
            path: "inventoryNumber",
            message: isUnique ? null : "inventoryNumberIsNotUnique",
        }
    });
}

export function* validateTitle(action) {

    let {value} = action.payload

    let isNotEmpty = value?.trim().length > 0

    yield put({
        type: actions.ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT,
        payload: {
            path: "title",
            message: isNotEmpty ? null : "titleIsEmpty",
        }
    });

}

export function* removeChild(action) {

    try {
        yield call(removeChildREST, action.payload.parentId, action.payload.childId);

        yield put(getArtworkChildren(action.payload.parentId));

        toast.success("Odłączono rekord podrzędny");

    } catch (e) {
        toast.error("Wystąpił błąd");
    }
}