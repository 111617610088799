import React from 'react';
import * as actions from "../actions/assetActions";
import {connect} from "react-redux";
import {Translation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        record: state.artworks.artwork,
        type: ownProps.type
    }
        ;
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        uploadAsset: (artworkId, file) => dispatch(actions.uploadAsset(artworkId, file, ownProps.type))
    }
};

class UploadAsset extends React.Component {

    onFileChange = (event) => {
        event.preventDefault();

        this.props.uploadAsset(this.props.record.id, event.target.files[0]);
    };

    render() {
        return (
            <div>


                <Translation>{t =>
                    <label className="btn btn-primary" htmlFor="my-file-selector">

                        <input id="my-file-selector" type="file" className="d-none" onChange={this.onFileChange}/>
                        {t("addImage")}
                    </label>
                }</Translation>
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(UploadAsset)