const scaleCrop = (scale, crop) => {
    let scaledCrop = {...crop}

    scaledCrop.width = Math.floor(scale * scaledCrop.width);
    scaledCrop.height = Math.floor(scale * scaledCrop.height);
    scaledCrop.x = Math.floor(scale * scaledCrop.x);
    scaledCrop.y = Math.floor(scale * scaledCrop.y);

    return scaledCrop;
}


const prepareInitialCrop = (image, initialCrop, cropOverride) => {
    let crop = {...initialCrop};

    if (cropOverride) {
        let scale = image.width / image.naturalWidth;
        cropOverride.aspect = initialCrop.aspect;
        cropOverride.unit = initialCrop.unit;
        crop = scaleCrop(scale, cropOverride);
    } else {
        let size = image.width > image.height ? image.height : image.width
        size = Math.floor(size * 0.7);

        crop.width = size;
        crop.height = size;
        crop.y = 0;
        crop.x = Math.floor((image.width - size) / 2);
    }

    return crop;
}

export {scaleCrop, prepareInitialCrop}