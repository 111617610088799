import React from 'react'
import {render} from 'react-dom'
import {applyMiddleware, createStore} from 'redux'
import {Provider} from 'react-redux'
import App from './components/browse/App'
import reducer from './reducers'
import {createLogger} from 'redux-logger'
import createSagaMiddleware, {END} from 'redux-saga';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-image-crop/dist/ReactCrop.css';
import './i18n';
import './main.scss';


import reduxCookiesMiddleware from 'redux-cookies-middleware';
import rootSaga from "./sagas/rootSaga";

// state to persist in cookies
const paths = {

};

let initialState = {};
const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducer,
    initialState,
    applyMiddleware(
        loggerMiddleware,
        sagaMiddleware,
        reduxCookiesMiddleware(paths)
    )
);

sagaMiddleware.run(rootSaga);
store.close = () => store.dispatch(END);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
