import * as actions from '../actions/foldersActions'

const initialState = {
    folders: [],
    ownership: [],
};

export default function folders(state = initialState, action) {
    switch (action.type) {
        case actions.FOLDERS_GET_DATA_DONE:
            return {...state, folders: action.payload};
        default:
            return state;
    }
}
