export const PERSON_OPEN_CREATE_MODAL = 'PERSON_OPEN_CREATE_MODAL';
export const PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_REQUESTED = 'PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_REQUESTED';
export const PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_DONE = 'PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_DONE';
export const PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_FAILED = 'PERSON_CREATE_FROM_LABEL_AND_SET_FOR_EDITTED_ARTWORK_FAILED';

export function openCreatePersonModal(name, nextAction) {
    return {
        type: PERSON_OPEN_CREATE_MODAL,
        payload: {name: name, nextAction: nextAction}
    };
}
