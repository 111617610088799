import React from 'react'
import {connect} from "react-redux";
import {applyFilterValue} from "../../actions/filtersActions";
import {Translation} from "react-i18next";
import {getCurrentSearchFilterToEdit, getSearchFilterToEdit} from "../../reducers/filteringReducer";
import {closeQueryModal, getSelectedQuery, querySave, querySetName} from "../../query/queryDuck";

const mapStateToProps = (state) => {
    return {
        editFilter: getSearchFilterToEdit(state),
        currentFilterValue: getCurrentSearchFilterToEdit(state),
        selectedQuery: getSelectedQuery(state),
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        close: () => dispatch(closeQueryModal()),
        setCurrentValue: (value) => dispatch(querySetName(value)),
        querySave: (asNew) => dispatch(querySave(asNew)),
        applyFilterValue: (filterId, value) => dispatch(applyFilterValue(filterId, value))
    }
};


class SaveFiltersModal extends React.Component {

    render() {

        let name = this.props.selectedQuery ? this.props.selectedQuery.name : ""
        let isValid = name != ""
        let isExisting = this.props.selectedQuery && this.props.selectedQuery.id

        return (
            <Translation>{t =>
                <div className="modal overlay show" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true" style={{"display": "block"}}>
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("saveQuery")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.props.close()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body fixed-size-modal-body">

                                <input type="text" placeholder="Search" name="real-value-input"
                                       className="regular-input"
                                       value={name}
                                       onChange={(e) => this.props.setCurrentValue(e.target.value)}/>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-id="close-columns-editor"
                                        onClick={() => this.props.close()}>{t("cancel")}
                                </button>
                                <button type="button" className="btn btn-success" data-id="save-columns"
                                        disabled={!isValid}
                                        onClick={event => this.props.querySave(false)}>{t("save")}
                                </button>
                                {isExisting && <button type="button" className="btn btn-primary" data-id="save-columns"
                                                       disabled={!isValid}
                                                       onClick={event => this.props.querySave(true)}>{t("saveAs")}
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveFiltersModal)

