import {
    COMPONENT_STATE,
    componentStateForConstraint,
    CURRENT_RECORD,
    currentRecordFetchForConstraint
} from "./currentRecord";


const fetchingMethods = {};
fetchingMethods[CURRENT_RECORD] = currentRecordFetchForConstraint;
fetchingMethods[COMPONENT_STATE] = componentStateForConstraint;

export function getBaseForConstraint(constraintSource, constraintContext) {
    let fetchingMethod = fetchingMethods[constraintSource.type];

    //TODO merge engines default fetching methods map with projects configuration

    if (fetchingMethod) {
        return fetchingMethod(constraintSource, constraintContext);
    }

    throw "Not found fetcher of constraint base object for type: " + constraintSource.type;
}
