import React from 'react'
import {connect} from "react-redux";
import {setCurrentValue} from "../../actions/filtersActions";
import {getAvailableDictionaryEntriesForReactSelect} from "../../selectors/availableFoldersForReactSelect.selector";
import {getCurrentSearchFilterToEdit} from "../../reducers/filteringReducer";
import Select from "react-select";

const mapStateToProps = (state, ownProps) => {
    return {
        currentFilterValue: getCurrentSearchFilterToEdit(state),
        available: getAvailableDictionaryEntriesForReactSelect(state, ownProps.dictionaryId)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentValue: (value) => dispatch(setCurrentValue(value)),
    }
};

class YesNoFilterComponent extends React.Component {

    options = [
        {
            label: "Yes",
            value: true
        },

        {
            label: "No",
            value: false
        }
    ];

    handleChange = (inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setCurrentValue(inputValue);
            default:
                break;
        }

    };

    render() {
        return (
            <Select
                name="visibleInNavigart"
                value={this.props.currentFilterValue}
                onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                isClearable={true}
                defaultOptions
                options={this.options}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(YesNoFilterComponent)

