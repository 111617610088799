import {call, put, select} from 'redux-saga/effects';
import * as actions from "../actions/assetActions";
import {removeAssetREST, setAssetsOrderREST, uploadAssetREST} from "../api/asset.api";
import {LOADING_START, LOADING_STOP} from "../loading/loadingDuck";
import {toast} from "react-toastify";

export function *uploadAsset(action) {

    let toastId;

    try {
        yield put({
            type: LOADING_START,
        });



        toastId = toast.info("Trwa wgrywanie pliku", {
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
        });
        const responseBody = yield call(uploadAssetREST, action.payload.artworkId, action.payload.file, action.payload.referenceType);

        yield put({
            type: actions.ASSET_UPLOAD_DONE,
            payload: responseBody
        });

        yield put({
            type: LOADING_STOP
        });

        toast.success("Zapisano obraz");
        toast.dismiss(toastId)

    } catch (e) {

        yield put({
            type: LOADING_STOP
        });

        yield put({
            type: actions.ASSET_UPLOAD_FAILED,
            payload: action.payload
        });

        toast.error("Wystąpił błąd podczas zapisywania pliku");
        toast.dismiss(toastId)
    }
}

export function *removeAsset(action) {

    try {
        yield put({
            type: LOADING_START,
        });

        const responseBody = yield call(removeAssetREST, action.payload.assetId, action.payload.artworkId, action.payload.referenceType);

        yield put({
            type: actions.ASSET_REMOVE_DONE,
            payload: responseBody
        });

        yield put({
            type: LOADING_STOP
        });

        toast.success("Usunięto obraz");

    } catch (e) {

        yield put({
            type: actions.ASSET_REMOVE_FAILED,
            payload: action.payload
        });

        yield put({
            type: LOADING_STOP
        });

        toast.error("Wystąpił błąd podczas usuwania obrazu");


    }
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function *reorderAssets(action) {

    let previews = yield select(state => state.artworks.artwork.previews)

    try {

        let {sourceIndex, destinationIndex, referenceType} = action.payload;
        let reordered = reorder(previews, sourceIndex, destinationIndex)


        if (false) { // todo: in case of no change in previews order - stop action
            return;
        }

        yield put({
            type: LOADING_START,
        });


        let artworkId = yield  select(state => state.artworks.artwork.id)

        yield put({
            type: actions.ASSET_REORDER_DONE,
            payload: reordered
        });

        let orderedAssetIds = [];
        reordered.forEach(preview => orderedAssetIds.push(preview.ref.id));

        //call api
        yield call(setAssetsOrderREST, artworkId, orderedAssetIds, referenceType);

        yield put({
            type: LOADING_STOP
        });

        toast.success("Zmieniono kolejność obrazów");

    } catch (e) {

        //reset order to orginal state
        yield put({
            type: actions.ASSET_REORDER_DONE,
            payload: previews
        });


        yield put({
            type: actions.ASSET_REORDER_FAILED,
            payload: action.payload
        });

        yield put({
            type: LOADING_STOP
        });

        toast.error("Wystąpił błąd podczas zmiany kolejności obrazów");

    }
}
