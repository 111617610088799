import HttpApiCallError from "../errors/HttpApiCallError";
import jwtDecode from 'jwt-decode';

export default async function asyncFetch(url, requestConfig = {}) {

    const response = await fetch(url, requestConfig);

    const isSuccess = response.status >= 200 && response.status < 300;

    let authHeader = response.headers.get('Authorization');

    if (authHeader) {
        let decoded = jwtDecode(authHeader);
        console.log("receive auth header", authHeader, decoded, new Date(decoded.exp * 1000));
        window.sessionStorage.accessToken = authHeader;
    }

    if (isSuccess) {
        return response;
    }

    const error = new HttpApiCallError(
        response.statusText,
        response.status
    );
    error.response = await response.json();

    throw error;
}
