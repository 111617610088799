import React from 'react'
import {Link} from "react-router-dom";
import UserNavPanel from "./UserNavPanel";
import {connect} from "react-redux";
import {isVisible} from "../../constraint/checkVisibility";
import {getComponentState} from "../../selectors";

const mapStateToProps = (state, ownProps) => {
    let config = state.engine.configsMap[ownProps.configId];

    return {
        config: config,
        isVisible: isVisible(config, state),
        blocked: getComponentState(state, ownProps.configId).blocked,
        userPermissions: state.engine.components.loggedUserComponent && state.engine.components.loggedUserComponent.data.permissions ? state.engine.components.loggedUserComponent.data.permissions : {},
        userRoles: state.engine.components.loggedUserComponent ? state.engine.components.loggedUserComponent.data.roles : [],
    };
};
const mapDispatchToProps = (dispatch) => {
    return {}
};

class NavBar extends React.Component {
    render() {
        if (!this.props.isVisible) {
            return null;
        }

        let items = this.props.config.items
            .filter(item => filterMenuItem(item.requiredPermission, this.props.userPermissions))
            .map((item) => (

                <li className="nav-item" key={item.url}>
                    <Link className="nav-link" to={"/" + item.url}>{item.label}</Link>
                </li>
            ));

        return (
            <header className="top">
                <div class="logo"></div>
                <nav className="navbar navbar-expand-lg">
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav mr-auto">
                                {items}
                            </ul>
                            <UserNavPanel/>
                        </div>
                </nav>
            </header>
        )
    }
}

export function filterMenuItem(requiredPermissions, userPermissions) {
    if (requiredPermissions == null || requiredPermissions == undefined || requiredPermissions.length == 0) {
        return true
    }

    let permissions = {...userPermissions}

    for (let rp of requiredPermissions) {
        if (!permissions.hasOwnProperty(rp)) {
            return false;
        }
        permissions = {...permissions[rp]}
    }

    return permissions
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
