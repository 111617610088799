export const ARTWORK_DETAILS_LOAD = "ARTWORK_DETAILS_LOAD";
export const ARTWORK_DETAILS_GET_DATA_REQUESTED = 'ARTWORK_DETAILS_GET_DATA_REQUESTED';
export const ARTWORK_DETAILS_GET_DATA_DONE = 'ARTWORK_DETAILS_GET_DATA_DONE';
export const ARTWORK_DETAILS_GET_DATA_FAILED = 'ARTWORK_DETAILS_GET_DATA_FAILED';
export const ARTWORK_SET_ATTRIBUTE = 'ARTWORK_SET_ATTRIBUTE';
export const ARTWORK_ADD_ATTRIBUTE = 'ARTWORK_ADD_ATTRIBUTE';
export const ARTWORK_REMOVE_FROM_ATTRIBUTE = 'ARTWORK_REMOVE_FROM_ATTRIBUTE';
export const ARTWORK_SET_ATTRIBUTE_SECOND_LVL = 'ARTWORK_SET_ATTRIBUTE_SECOND_LVL';
export const ARTWORK_SET_ATTRIBUTE_THIRD_LVL = 'ARTWORK_SET_ATTRIBUTE_THIRD_LVL';
export const ARTWORK_ADD_ATTRIBUTE_SECOND_LVL = 'ARTWORK_ADD_ATTRIBUTE_SECOND_LVL';
export const ARTWORK_SET_CARD_PREPARATION_DATE = 'ARTWORK_SET_CARD_PREPARATION_DATE';
export const ARTWORK_UPDATE = 'ARTWORK_UPDATE';
export const ARTWORK_UPDATE_REQUESTED = 'ARTWORK_UPDATE_REQUESTED';
export const ARTWORK_UPDATE_DONE = 'ARTWORK_UPDATE_DONE';
export const ARTWORK_UPDATE_FAILED = 'ARTWORK_UPDATE_FAILED';
export const ARTWORK_CHILDREN_LOAD = 'ARTWORK_CHILDREN_LOAD';
export const ARTWORK_CHILDREN_LOAD_DONE = 'ARTWORK_CHILDREN_LOAD_DONE';
export const ARTWORK_CHILDREN_LOAD_FAILED = 'ARTWORK_CHILDREN_LOAD_FAILED';
export const ARTWORK_PARENT_LOAD = 'ARTWORK_PARENT_LOAD';
export const ARTWORK_PARENT_LOAD_DONE = 'ARTWORK_PARENT_LOAD_DONE';
export const ARTWORK_PARENT_LOAD_FAILED = 'ARTWORK_PARENT_LOAD_FAILED';
export const ARTWORK_VALIDATE_INVENTORY_NUMBER = 'ARTWORK_VALIDATE_INVENTORY_NUMBER';
export const ARTWORK_VALIDATE_TITLE = 'ARTWORK_VALIDATE_TITLE';
export const ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT = 'ARTWORK_SET_INVENTORY_NUMBER_VALIDATION_RESULT';
export const ARTWORK_TOGGLE_EDIT = 'ARTWORK_TOGGLE_EDIT';

export const ARTWORK_REMOVE_CHILDREN = 'ARTWORK_REMOVE_CHILDREN';

export const CALL_API_ARTWORK_ACTION = 'CALL_API_ARTWORK_ACTION';

export function getArtworkDetails(artworkId, clone) {
    return {
        type: ARTWORK_DETAILS_LOAD,
        payload: {artworkId, clone}
    }
}

export function getArtworkChildren(artworkId) {
    return {
        type: ARTWORK_CHILDREN_LOAD,
        payload: {artworkId}
    }
}

export function getArtworkParent(artworkId) {
    return {
        type: ARTWORK_PARENT_LOAD,
        payload: {artworkId}
    }
}


export function setArtworkToEdit(artwork) {
    return {
        type: ARTWORK_DETAILS_GET_DATA_DONE,
        payload: artwork
    }
}

export function setAttribute(attribute, value) {
    let crumbs = attribute.split(".");

    if (crumbs.length == 1) {
        return {
            type: ARTWORK_SET_ATTRIBUTE,
            payload: {attribute, value}
        }
    } else if (crumbs.length == 2) {
        let secondAttr = crumbs[1]

        if (!isNaN(secondAttr)) {
            secondAttr = parseInt(secondAttr)
        }

        return {
            type: ARTWORK_SET_ATTRIBUTE_SECOND_LVL,
            payload: {attr: crumbs[0], subattr: secondAttr, val: value}
        }
    } else if (crumbs.length == 3) {
        let attr2nd = crumbs[1]
        let attr3rd = crumbs[2]

        if (!isNaN(attr2nd)) {
            attr2nd = parseInt(attr2nd)
        }
        if (!isNaN(attr3rd)) {
            attr3rd = parseInt(attr3rd)
        }

        return {
            type: ARTWORK_SET_ATTRIBUTE_THIRD_LVL,
            payload: {attr: crumbs[0], subattr: attr2nd, attrLevel3: attr3rd, val: value}
        }
    }


}

export function setAttributeSecondLevel(attr, subattr, val) {
    return {
        type: ARTWORK_SET_ATTRIBUTE_SECOND_LVL,
        payload: {attr, subattr, val}
    }
}


export function setCardPreparationDate(dateType, yearMonthDay, datePartValue) {
    return {
        type: ARTWORK_SET_CARD_PREPARATION_DATE,
        payload: {dateType, yearMonthDay, datePartValue}
    }
}

export function startUpdate(artwork) {
    return {
        type: ARTWORK_UPDATE,
        payload: artwork
    }
}

export function removeChild(parentId, childId) {
    return {
        type: ARTWORK_REMOVE_CHILDREN,
        payload: {parentId, childId}
    }
}

export function toggleEdit(fieldID) {
    return {
        type: ARTWORK_TOGGLE_EDIT,
        payload: {fieldID}
    }
}


export function addToAttribute(attributeToSet, val) {
    return {
        type: ARTWORK_ADD_ATTRIBUTE,
        payload: {attributeToSet, val}
    }
}


export function removeFromAttribute(attributeToSet, idx) {
    return {
        type: ARTWORK_REMOVE_FROM_ATTRIBUTE,
        payload: {attributeToSet, idx}
    }
}



