import React from 'react'
import {connect} from "react-redux";
import PersonEditableField from "./PersonEditableField";
import {setAttributeSecondLevel} from "../../actions/artworkActions";
import {Translation} from "react-i18next";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "./ToggleEditable";


const authorsTypes = ["autorWzoruPosredniego", "autorWzoruBezposredniego", "autorTekstu", "wydawca"]

const getAuthorsByType = (state, type) => state.artworks.artwork.author && Array.isArray(state.artworks.artwork.author[type]) ? state.artworks.artwork.author[type] : [];

const mapStateToProps = (state, ownProps) => {
    let props = {
        enabled: selectFieldEditEnabled(state, "otherAuthors"),
    };
    authorsTypes.forEach(type => props[type] = getAuthorsByType(state, type));
    return props;
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOtherAuthor: (authorType, person) => dispatch(setAttributeSecondLevel("author", authorType, person))
    }
};

//move out from this class
export const viewToDbMapper = (inputValue) => {
    return inputValue ? inputValue.map((item) => item.dictionaryEntry) : [];
};



class OtherAuthorsEditableField extends React.Component {

    render() {

        let editors = authorsTypes.map(type => (
            <div className="row form-line">
                <Translation>{t =>
                    <div className="col-lg-2 form-label">{t("author." + type)}</div>
                }</Translation>
                <div className="col">
                    <PersonEditableField
                        placeholder={""}
                        path={type}
                        isDisabled={!this.props.enabled}
                        records={this.props[type]}
                        setterAction={(people) => this.props.setOtherAuthor(type, people)}
                    />
                </div>
            </div>
        ));


        return (

                <div className="component">
                    <header className="component-header">
                        <Translation>{t =>
                            <span>{t("otherAuthors")}</span>
                        }</Translation>
                        <ToggleEditable fieldId={"otherAuthors"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        {editors}
                    </div>
                </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherAuthorsEditableField)

