import React from 'react'
import {connect} from "react-redux";
import {Translation} from 'react-i18next';

import {Editor} from '@tinymce/tinymce-react';
import getObjectAttr from "../../util/getObjectAttr";
import {setAttribute} from "../../actions/artworkActions";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "./ToggleEditable";


const mapStateToProps = (state, ownProps) => {
    return {
        enabled: selectFieldEditEnabled(state, ownProps.path),
        field: getObjectAttr(ownProps.path, state.artworks.artwork),
        artworkId: getObjectAttr("id", state.artworks.artwork),
        validationResults: getObjectAttr(ownProps.path, state.artworks.validationResults),
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setAttribute: (attribute, value) => dispatch(setAttribute(attribute, value)),
        validate: (action, artworkId, value) => dispatch({
            type: action,
            payload: {artworkId, value}
        })
    }
};

export const tinyMceOptions = {
    elementpath: false,
    statusbar: true,
    menubar: false,
    valid_elements: '+*[*]',
    verify_html: false,
    forced_root_block: false,
    plugins: ['link', 'charmap', 'code'],
    toolbar: "bold italic underline strikethrough | " +
        " bullist numlist | undo redo | removeformat | subscript superscript | link charmap |"
};

class SimpleEditableField extends React.Component {

    handleEditorChange = (content, editor) => {
        this.setValue(content);
    };

    setValue = (value) => {
        if (this.props.validator) {
            this.props.validate(this.props.validator, this.props.artworkId, value)
        }

        this.props.setAttribute(this.props.path, value);
    }

    render() {

        let editor = null;
        if (this.props.type == "input") {
            editor = (<textarea
                disabled={!this.props.enabled}
                className="full-width"
                name={this.props.path + "editablefIELD"}
                value={this.props.field}
                onChange={(e) => this.setValue(e.target.value)}
            />);
        } else if (this.props.type == "date") {
            editor = (<textarea
                disabled={!this.props.enabled}
                className="full-width"
                name={this.props.path + "editablefIELD"}
                value={this.props.field}
                onChange={(e) => this.setValue(e.target.value)}
            />);
        } else if (this.props.type == "checkbox") {
            editor = (<input type="checkbox"
                             disabled={!this.props.enabled}
                             className="full-width"
                             name={this.props.path + "editablefIELD"}
                             value={this.props.field}
                             onChange={(e) => this.setValue(e.target.value)}
            />);
        } else {
            editor = (<Editor
                apiKey="o1p8a6a82ye3v8z03yctvwp70y3iyo8lst7tamh2a7zeibhd"
                initialValue={this.props.field}
                disabled={!this.props.enabled}
                init={{
                    height: 150,
                    menubar: false,
                    plugins: tinyMceOptions.plugins,
                    toolbar: tinyMceOptions.toolbar,
                    branding: false
                }}
                onEditorChange={this.handleEditorChange}
            />);
        }

        let validationResultTranslated = this.props.validationResults ?
            <Translation>{t =>
                <div className="alert alert-danger" role="alert">
                    {t(this.props.validationResults)}
                </div>
            }</Translation>
            : null;

        let errorClass = this.props.validationResults ? "has-errors" : null

        return (
            <div className={`component ${errorClass}`}>
                <header className="component-header">
                    <Translation>{t =>
                        <span>{t(this.props.path)}</span>
                    }</Translation>
                    <ToggleEditable fieldId={this.props.path}/>
                </header>

                <div className="component-content form-line">
                    {editor}
                </div>

                {validationResultTranslated && validationResultTranslated}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleEditableField)

