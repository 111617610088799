import React from 'react'
import {connect} from "react-redux";
import {toggleEdit} from "../../actions/artworkActions";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";


const mapStateToProps = (state, ownProps) => {
    return {
        enabled: selectFieldEditEnabled(state, ownProps.fieldId),
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleEnabled: () => dispatch(toggleEdit(ownProps.fieldId)),
    }
};

class ToggleEditable extends React.Component {
    render() {
        return (
            <button type="button" className="btn btn-outline-secondary edit" title="Edit"
                    onClick={() => this.props.toggleEnabled()}>
                {this.props.enabled ? <i className="far fa-times-circle"></i> : <i className="fas fa-pencil-alt"></i>}
            </button>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleEditable)

