import React from 'react'
import {connect} from "react-redux";
import logout from "../../../auth/authActions";
import {Link} from "react-router-dom";

const mapStateToProps = (state) => ({
    isLogged: state.engine.components.loggedUserComponent && state.engine.components.loggedUserComponent.data,
    loggedUser: state.engine.components.loggedUserComponent ? state.engine.components.loggedUserComponent.data : null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout())
});

const UserNavPanel = (props) => {

    return props.isLogged ?
        (
            <React.Fragment>
                <div class="user">
                    <span>
                        {props.loggedUser ? props.loggedUser.id : ""}
                    </span>
                    <a onClick={() => props.logout()} role="menuitem" tabIndex="-1" href="#" className="ml-3">
                        Wyloguj</a>
                </div>
            </React.Fragment>
        )
        :
        (

            <Link className="nav-link" to={"/login"}><i className="far fa-signout-alt"></i>Zaloguj</Link>
        )

};

export default connect(mapStateToProps, mapDispatchToProps)(UserNavPanel)
