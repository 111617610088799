import React from 'react'
import {connect} from "react-redux";
import {prepareEngine} from "../engine/actions/engine.actions";
import {mainConfig} from "../config/main.config";
import ViewComponent from "../engine/component/ViewComponent";

const mapStateToProps = (state) => {
    return {
        mainConfig: state.engine.config
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        prepareEngine: () => dispatch(prepareEngine(mainConfig))
    }
};

class MainComponent extends React.Component {


    componentDidMount() {
        this.props.prepareEngine();
    }

    render() {

        return (
            (this.props.mainConfig &&
                <ViewComponent configId={this.props.mainConfig.id} key={this.props.mainConfig.id}/>)
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent)

