import {BACKEND_URL} from "../../config";

export const loginScreen = {
    id: "loginScreen",
    type: "component",
    grid: {
        role: "row"
    },
    route: {
        path: "/login",
        exact: false
    },
    children: [
        {
            id: "loginScreenFormLeftPlaceholder",
            type: "component",
            grid: {
                role: "col",
                size: 2,
            }
        },
        {
            id: "loginScreenFormParent",
            type: "component",
            grid: {
                role: "col",
                size: 5,
            },
            cssClass: "loginFormContent",
            children: [
                {
                    id: "loginScreenForm",
                    grid: {
                        role: "col",

                    },

                    type: "form",
                    layout: {
                        direction: "vertical"
                    },
                    fields: [
                        {
                            label: "Username",
                            name: "username",
                            type: "text",
                            validators: ["required"]
                        },
                        {
                            label: "Password",
                            name: "password",
                            type: "password",
                            validators: ["required"]
                        }
                    ],
                    submit: {
                        label: "Login"
                    },
                    submitAction: {
                        children: [
                            {
                                type: "block-component",
                                componentId: "loginScreenForm"
                            },
                            {
                                type: "call-rest-post",
                                url: BACKEND_URL + 'login',
                                baseDateFromComponent: "loginScreenForm",
                                mergeDataFromForm: "loginScreenForm",
                                setToken : true,
                                successAction: {
                                    type: "unblock-component",
                                    componentId: "loginScreenForm",
                                    children: [
                                        {
                                            type: "call-rest",
                                            url: BACKEND_URL + 'user',
                                            setResponseToComponent: "loggedUserComponent",
                                            successAction: {
                                                type: "redirect",
                                                path: "/",
                                            }
                                        }
                                    ],
                                },
                                errorAction: {
                                    children: [
                                        {
                                            type: "unblock-component",
                                            componentId: "loginScreenForm"
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    cancelAction: {
                        children: [
                            {
                                type: "hide-component",
                                componentId: "repositoryFormMain"
                            },

                        ]
                    }
                }
            ]


        },

    ]
};
