import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";

export async function searchArtworksFromREST(request) {

    const url = BACKEND_URL + 'artwork/search/';

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function getArtworksIdsFromREST(request) {

    const url = BACKEND_URL + 'artwork/search/get-ids';

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(request)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}
