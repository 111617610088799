import {
    ENGINE_PREPARE_COMPONENT, ENGINE_PREPARE_SET_MAIN_CONFIG,
    ENGINE_RUN_ACTION_BLOCK_COMPONENT,
    ENGINE_RUN_ACTION_HIDE_COMPONENT,
    ENGINE_RUN_ACTION_SHOW_COMPONENT,
    ENGINE_RUN_ACTION_UNBLOCK_COMPONENT,
    ENGINE_SET_DATA_TO_COMPONENT,
    ENGINE_SET_PAGING_DATA_TO_COMPONENT, ENGINE_UPDATE_CONFIG_WITHOUT_CHILDREN,
} from "../actions/engine.actions";

export const initialState = {components: {}, config: null, configsMap: {}, isLoading: false};

export default function engine(state = initialState, action) {
    switch (action.type) {
        case ENGINE_PREPARE_COMPONENT:
            return prepareComponent(state, action.payload);
        case ENGINE_UPDATE_CONFIG_WITHOUT_CHILDREN:
            return updateConfigWithoutChildren(state, action.payload);
        case ENGINE_PREPARE_SET_MAIN_CONFIG:
            return prepareConfigs(state, action.payload.payload);
        case ENGINE_RUN_ACTION_SHOW_COMPONENT:
            return setComponentAttr(state, action.payload, "visibility", "visible");
        case ENGINE_RUN_ACTION_HIDE_COMPONENT:
            return setComponentAttr(state, action.payload, "visibility", "hidden");
        case ENGINE_RUN_ACTION_BLOCK_COMPONENT:
            return setComponentAttr(state, action.payload, "blocked", true);
        case ENGINE_RUN_ACTION_UNBLOCK_COMPONENT:
            return setComponentAttr(state, action.payload, "blocked", false);
        case ENGINE_SET_DATA_TO_COMPONENT:
            return setComponentAttr(state, action.payload.componentId, "data", action.payload.response);
        case ENGINE_SET_PAGING_DATA_TO_COMPONENT:
            return mergeComponentAttr(state, action.payload.componentId, "pagination", action.payload.pagination);

        default:
            return state;
    }
}


function prepareConfigs(state, config) {

    let configsMap = {};
    prepareConfigsRecursive(config, configsMap);

    return {... state, config: config, configsMap: configsMap};
}

function prepareConfigsRecursive(config, configsMap) {
    configsMap[config.id] = config;

    if (config.children && config.children.length > 0) {
        for (let idx in config.children) {
            prepareConfigsRecursive(config.children[idx], configsMap);
        }
    }


}


function prepareComponent(state, config) {
    let newState = {...state};
    newState.components[config.id] = {
        id: config.id,
        visibility: config.visibility ? config.visibility : "visible",
        blocked: false
    };

    if (config.type == "ajax-table") {
        newState.components[config.id].pagination = {
            totalPages: 0,
            totalElements: 0,
            pageNumber: 0,
            pageSize: 10
        }
    }

    return newState;
}


function updateConfigWithoutChildren(state,configUpdate) {
    let newConfig = {...configUpdate, children: state.configsMap[configUpdate.id].children};
    let configsMapNem = {...state.configsMap};
    configsMapNem[configUpdate.id] = newConfig;

    return {...state, configsMap: configsMapNem};
}
//
// function findConfigRecursiveAndUpdate(config, configUpdate) {
//     if (config.id == configUpdate.id) {
//
//
//         for (let key in configUpdate) {
//             if (key !== "children") {
//                 config[key] = configUpdate[key];
//             }
//         }
//
//
//         return config;
//     }
//
//     if (config.children && config.children.length > 0) {
//         for (let idx in config.children) {
//             let result = findConfigRecursiveAndUpdate(config.children[idx], configUpdate);
//             if (result) {
//                 return result;
//             }
//         }
//     }
//
//     return null;
// }

//
// function showComponent(state, componentId) {
//     let newState = {...state};
//     newState.components[componentId].visibility = "visible";
//
//     return newState;
// }
//
// function hideComponent(state, componentId) {
//     let newState = {...state};
//     newState.components[componentId].visibility = "hidden";
//
//     return newState;
// }
//
// function blockComponent(state, componentId) {
//
//     let newState = {...state};
//     newState.components[componentId].blocked = true;
//
//     return newState;
// }

function setComponentAttr(state, componentId, attr, attrVal) {

    let newState = {...state};
    if (!newState.components[componentId]) {
        newState.components[componentId]={};
    }
    newState.components[componentId][attr] = attrVal;

    return newState;
}

function mergeComponentAttr(state, componentId, attr, attrVal) {

    let newState = {...state};

    for (let key of Object.keys(attrVal)) {

        newState.components[componentId][attr][key] = attrVal[key];

    }

    return newState;
}
