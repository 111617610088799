export const CROP_OPEN = 'CROP_OPEN';
export const CROP_SAVE = 'CROP_SAVE';
export const CROP_CLOSE = 'CROP_CLOSE';

export function openCrop(asset, artworkId) {
    return {
        type: CROP_OPEN,
        payload: {asset, artworkId}
    };
}

export function saveCrop(crop, assetId, artworkId) {
    return {
        type: CROP_SAVE,
        payload: {crop, assetId, artworkId}
    };
}

export function closeCrop() {
    return {
        type: CROP_CLOSE
    };
}