export const artworkFilterAvailable = [
    {
        id: "folders",
        label: "folders",
        type: "folder",
    },
    {
        id: "inventoryNumber",
        label: "inventoryNumber",
        type: "string",
    },
    {
        id: "title",
        label: "title",
        type: "string",
    },
    {
        id: "authors",
        label: "authors",
        type: "person",
    },
    {
        id: "iconclass",
        label: "iconclass",
        type: "dictionaryEntry",
        dictionaryId: "iconclass"
    },

    //other authors
    {
        id: "author.autorTekstu",
        label: "author.autorTekstu",
        type: "person"
    },
    {
        id: "author.autorWzoruBezposredniego",
        label: "author.autorWzoruBezposredniego",
        type: "person"
    },
    {
        id: "author.autorWzoruPosredniego",
        label: "author.autorWzoruPosredniego",
        type: "person"
    },
    {
        id: "author.wydawca",
        label: "author.wydawca",
        type: "person"
    },

    {
        id: "inscription.inscription",
        label: "inscription.inscription",
        type: "string"
    },
    {
        id: "projects",
        label: "project",
        type: "dictionaryEntry",
        dictionaryId: "projects"
    },
    {
        id: "location",
        label: "location",
        type: "dictionaryEntry",
        dictionaryId: "locations"
    },

    //material/techinque
    {
        id: "materialTechnique.materials",
        label: "materialTechnique.materials",
        type: "dictionaryEntry",
        dictionaryId: "materials"
    },
    {
        id: "materialTechnique.techniques",
        label: "materialTechnique.techniques",
        type: "dictionaryEntry",
        dictionaryId: "techniques"
    },
    {
        id: "materialTechnique.filigran",
        label: "materialTechnique.filigran",
        type: "dictionaryEntry",
        dictionaryId: "filigrans"
    },


    //place of creation
    {
        id: "placeOfCreation.comment",
        label: "placeOfCreation.comment",
        type: "string"
    },
    {
        id: "placeOfCreation.country",
        label: "placeOfCreation.country",
        type: "dictionaryEntry",
        dictionaryId: "countries"

    },
    {
        id: "placeOfCreation.city",
        label: "placeOfCreation.city",
        type: "dictionaryEntry",
        dictionaryId: "cities"
    },


    {
        id: "objectTypes",
        label: "objectTypes",
        type: "dictionaryEntry",
        dictionaryId: "objectTypes",
    },

    //description
    {
        id: "description.references",
        label: "description.references",
        type: "dictionaryEntry",
        dictionaryId: "references"
    },
    {
        id: "description.description",
        label: "description.description",
        type: "string"
    },
    {
        id: "description.tags",
        label: "description.tags",
        type: "dictionaryEntry",
        dictionaryId: "tags"
    },


    //cardPreparationAuthor
    {
        id: "cardPreparation.cardAuthor",
        label: "cardPreparation.author",
        type: "string"
    },
    {
        id: "cardPreparation.cardAuthoredDate.from.year",
        label: "cardPreparation.cardAuthoredDate.from.year",
        type: "number"
    },
    // {
    //     id: "cardPreparation.approveDate",
    //     label: "cardPreparation.approveDate",
    //     type: "date"
    // },
    {
        id: "cardPreparation.approver",
        label: "cardPreparation.approver",
        type: "string"
    },


    {
        id: "copyright",
        label: "copyright",
        type: "string"
    },
    {
        id: "provenance",
        label: "provenance",
        type: "string"
    },
    {
        id: "storage",
        label: "storage",
        type: "string"
    },
    {
        id: "dimensions",
        label: "dimensions",
        type: "string"
    },
    {
        id: "ownership",
        label: "ownership",
        type: "dictionaryEntry",
        dictionaryId: "ownership"
    },
    {
        id: "visibleInNavigart",
        label: "visibleInNavigart",
        type: "visibleInNavigart"
    },
    {
        id: "requiresExport",
        label: "requiresExport",
        type: "requiresExport"
    },
    {
        id: "hasPreviews",
        label: "hasPreviews",
        type: "hasPreviews"
    },


];