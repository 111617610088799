// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
export const LOADING_START = "LOADING_START"
export const LOADING_STOP = "LOADING_STOP"

// Reducer
export default function loadingReducer(state = {isLoading: false}, action = {}) {
    switch (action.type) {
        case LOADING_START:
            return {
                ...state,
                isLoading: true
            }

        case LOADING_STOP:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}

export const loadingStart = () => ({
    type: LOADING_START
})


export const loadingStop = () => ({
    type: LOADING_STOP
})

