import React from "react";
import HeaderComponent from "./HeaderComponent";
import ButtonComponent from "./ButtonComponent";
import ViewComponent from "./ViewComponent";
import TableComponent from "./TableComponent";
import MessageComponent from "./MessageComponent";
import FormComponent from "./FormComponent";
import NavBar from "./navigart/NavBar";
import {Route} from "react-router-dom";
import AdministrationSubmenu from "./navigart/AdministrationSubmenuComponent";
import AjaxTableComponent from "./AjaxTableComponent";


export default function typeMapper(config) {

    if (!config) {
        return null;
    }

 switch (config.type) {
        case "header":
            return <HeaderComponent configId={config.id} key={config.id}/>;
        case "button":
            return <ButtonComponent configId={config.id} key={config.id} />;
        case "component":
            return buildViewComponent(config);
        case "table":
            return <TableComponent configId={config.id} key={config.id} />;
        case "ajax-table":
            return <AjaxTableComponent configId={config.id} key={config.id} />;
        case "message":
            return <MessageComponent configId={config.id} key={config.id} />;
        case "navbar":
            return <NavBar configId={config.id} key={config.id} />;
        case "subnavbar":
            return <AdministrationSubmenu configId={config.id} key={config.id} />;
        case "custom-component":
            return buildCustomComponent(config);
        case "form":
            return <FormComponent configId={config.id} key={config.id} />;

        default: return null;
    }
}

let buildViewComponent = (config) => {

    if (config.route) {
        return <Route path={config.route.path} key={config.id} render={routeProps => <ViewComponent {...routeProps} configId={config.id}/>} />
    }
    return <ViewComponent configId={config.id} key={config.id} />;
}

let buildCustomComponent = (config) => {

    if (config.route) {
        return <Route path={config.route.path} exact={config.route.exact} component={config.component} key={config.id}/>
    }

    return React.createElement(config.component, {});
}