import React from 'react'
import {connect} from "react-redux";
import UploadFile from "../UploadAsset";
import AssetPreview from "./AssetPreview";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import * as actions from "../../actions/assetActions";
import {Translation} from "react-i18next";
import AssetPreviewModal from "./AssetPreviewModal";

const mapStateToProps = (state, ownProps) => {
    return {
        previews: state.artworks.artwork.previews ? state.artworks.artwork.previews : [],
        artworkId: state.artworks.artwork.id,
        type: ownProps.type
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        reorderAssets: (result) => dispatch(actions.reorderAssets(result.source.index, result.destination.index, ownProps.type))
    };
};

class ArtworkPreviews extends React.Component {

    render() {

        let i = 0;

        return (
            <div className="children-list-component assets-list">
                <Translation>{t =>
                    <h6>{t("assetsHeader")}
                        &nbsp;<span className="badge badge-secondary">{this.props.previews.length}</span>
                    </h6>
                }</Translation>

                <ul className="list-group">
                    <DragDropContext onDragEnd={this.props.reorderAssets}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.props.previews.map(value => value.ref).map((asset, index) => asset &&
                                        <Draggable key={asset.id} draggableId={asset.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <li className="list-group-item" key={asset.id} >
                                                        <AssetPreview asset={asset}
                                                                      position={i++}
                                                                      type={this.props.type}
                                                                      artworkId={this.props.artworkId}/>
                                                    </li>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <li className="list-group-item">
                        <UploadFile type={this.props.type}/>
                    </li>
                </ul>
                <AssetPreviewModal/>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ArtworkPreviews)