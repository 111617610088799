export const buildUrl = (actionConfig, record) => {

    let url = actionConfig.url;

    if (actionConfig.urlBuilder) {
        for (let param of (Object.keys(actionConfig.urlBuilder))) {

            let value = record[actionConfig.urlBuilder[param]];

            url = url.replace(`{${param}}`, value);
        }
    }

    return url;
};