import asyncFetch from './async-fetch';
import {getBaseRequestConfig} from './baseRequestConfig';
import {BACKEND_URL} from "../config";

export async function fetchDictionaryOptions(dictionaryType) {

    const url = BACKEND_URL + 'dictionary/' + dictionaryType;

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function fetchDictionaries() {

    const url = BACKEND_URL + 'dictionary/';

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    const data = await response.json();

    return data.map((entry) => {
        return {
            label: entry.labels && entry.labels.pl ? entry.labels.pl : entry.id,
            value: entry.labels && entry.labels.pl ? entry.labels.pl : entry.id,
            dictionary: entry
        }
    });
}

export async function fetchDictionariesREST() {

    const url = BACKEND_URL + 'dictionary/';

    const requestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}

