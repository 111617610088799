import React from 'react'
import {BrowserRouter as Router, Route} from "react-router-dom";

import {connect} from "react-redux";

import MainComponent from "../MainComponent";
import {runAction} from "../../engine/actions/engine.actions";
import {initAppConfig} from "../../config/app/initApp.config";
import OverlayComponent from "../OverlayComponent";
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';


class App extends React.Component {

    componentDidMount() {
        this.props.runAction(initAppConfig);
    }

    render() {
        return (
            <Router>
                {/*<Header/>*/}
                <MainComponent/>
                {/*<Route path="/artwork/:inventoryNumber" component={ItemDetails}/>*/}
                {/*<Route path="/artworks" component={ArtworksListComponent}/>*/}
                {/*<Route path="/dictionaries" component={DictionaryEntriesList}/>*/}
                {/*<Route path="/persons" component={PersonsList}/>*/}
                {/*<Route path="/administration" component={Administration}/>*/}
                {/*<TempFooter />*/}
                <OverlayComponent />
                <ToastContainer newestOnTop={true} closeOnClick={false} draggable={false}/>
            </Router>
        )
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config) => dispatch(runAction(config, {}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App)

