import React from 'react'
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        isLoading: state.loading.isLoading
    };
};
const mapDispatchToProps = (dispatch) => {
    return {}
};

class OverlayComponent extends React.Component {



    render() {

        return this.props.isLoading ?  <div id="overlay" /> : <React.Fragment/>;

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayComponent)

