// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../config";
import {getBaseRequestConfig} from "../api/baseRequestConfig";
import asyncFetch from "../api/async-fetch";

export const SET_COLUMNS = "SET_COLUMNS"
export const SET_COLUMNS_START = "SET_COLUMNS_START"
export const GET_USER_COLUMNS = "GET_USER_COLUMNS"
export const SET_COLUMNS_DRAFT = "SET_COLUMNS_DRAFT"
export const SET_DEFAULT_CONFIG = "SET_DEFAULT_CONFIG"
export const TOGGLE_COLUMN = "TOGGLE_COLUMN"
export const OPEN_COLUMNS_EDITOR = "OPEN_COLUMNS_EDITOR"
export const CLOSE_COLUMNS_EDITOR = "CLOSE_COLUMNS_EDITOR"
export const RESTORE_DEFAULT_COLUMNS = "RESTORE_DEFAULT_COLUMNS"
export const SET_COLUMN_ON_POSITION = "SET_COLUMN_ON_POSITION"


let initialState = {
    config2Columns: {}, //tableId => columns list
    config2ColumnsDraft: {}, //tableId => columns list
    defaultConfigMap: {}, //tableId => config
    visibleConfig: {}, //tableId => bool
    orderedColumns: {}, //tableId => columns list
    opened: false
}
// Reducer
export default function tableConfigReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_COLUMNS:
            return setColumnsReducer(state, action.payload)
        case SET_COLUMNS_DRAFT:
            return setColumnsDraftReducer(state, action.payload)
        case SET_DEFAULT_CONFIG:
            return setDefaultConfigReducer(state, action.payload)
        case TOGGLE_COLUMN:
            return toggleColumnReducer(state, action.payload)
        case SET_COLUMN_ON_POSITION:
            return setColumnOnPositionReducer(state, action.payload)
        case OPEN_COLUMNS_EDITOR:
            return {...state, opened: true}
        case CLOSE_COLUMNS_EDITOR:
            return {...state, opened: false}
        default:
            return state
    }
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    for (let i = 0; i < result.length; i++) {
        result[i].position = i
    }

    return result;
};

const setColumnOnPositionReducer = (state, payload) => {
    let newState = {...state}

    let {tableConfigId, columns, columnId, index} = payload;

    let cols = newState.config2ColumnsDraft[tableConfigId]

    cols = reorder(cols, columnId, index)

    newState.config2ColumnsDraft[tableConfigId] = cols

    return newState;
}

const setDefaultConfigReducer = (state, payload) => {
    let newState = {...state}
    newState.defaultConfigMap[payload.id] = payload.config;
    return newState;
}

const setColumnsReducer = (state, payload) => {
    let newState = {...state}
    newState.config2Columns[payload.id] = payload.columns;
    return newState;
}

const setColumnsDraftReducer = (state, payload) => {
    let newState = {...state}
    newState.config2ColumnsDraft[payload.id] = payload.columns;
    return newState;
}

const toggleColumnReducer = (state, payload) => {
    let newState = {...state}
    let {id, column} = payload
    let columns = [...newState.config2ColumnsDraft[id]];

    columns.forEach(value => {
        if (value.id == column) {
            value.enabled = !value.enabled;
        }
    })

    newState.config2ColumnsDraft[id] = columns;
    return newState;
}

export const setColumnsDraft = (id, columns) => ({
    type: SET_COLUMNS_DRAFT,
    payload: {id, columns}
})

export const setColumns = (id, columns) => ({
    type: SET_COLUMNS,
    payload: {id, columns}
})

export const setDefaultConfig = (id, config) => ({
    type: SET_DEFAULT_CONFIG,
    payload: {id, config}
})

export const toggleColumn = (id, column) => ({
    type: TOGGLE_COLUMN,
    payload: {id, column}
})

export const setColumnsStart = (id, columns, sortedColumns) => ({
    type: SET_COLUMNS_START,
    payload: {id, columns}
})

export const getColumns = (id) => ({
    type: GET_USER_COLUMNS,
    payload: {id}
})


export const closeColumnsEditor = () => ({
    type: CLOSE_COLUMNS_EDITOR,
})

export const openColumnsEditor = () => ({
    type: OPEN_COLUMNS_EDITOR,
})

export const setDefaultColumns = (tableConfig) => ({
    type: RESTORE_DEFAULT_COLUMNS,
    payload: {tableConfig}
})


export const setColumnOnPosition = (tableConfigId, columns, columnId, index) => ({
    type: SET_COLUMN_ON_POSITION,
    payload: {tableConfigId, columns, columnId, index}
})

export function* saveColumns(action) {
    try {
        let {id, columns} = action.payload;

        yield put({
            type: SET_COLUMNS,
            payload: {id, columns: columns}
        });

        yield put({
            type: CLOSE_COLUMNS_EDITOR
        });

        yield call(setTableColumnsREST, id, columns);
        toast.success("Zapisano zmiany");

    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisu");
    } finally {

    }
}

export function* restoreDefaultColumns(action) {
    let {tableConfig} = action.payload;

    let id = tableConfig.id

    let i = 0
    let columns = tableConfig.columns.map(column => {
        return {
            id: column.id,
            enabled: column.default || column.required,
            position: i++
        }
    })

    yield put(setColumnsDraft(id, columns));
}

export function* getColumnsSaga(action) {
    try {
        let {id} = action.payload;

        let columns = yield call(getTableColumnsREST, id);

        yield put({
            type: SET_COLUMNS,
            payload: {id, columns}
        });

    } catch (e) {

    } finally {

    }
}


export async function setTableColumnsREST(tableId, columns) {

    const url = `${BACKEND_URL}table-configuration/` + tableId;

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(columns)};

    return await asyncFetch(url, requestConfig);
}


export async function getTableColumnsREST(tableId) {

    const url = `${BACKEND_URL}table-configuration/` + tableId;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}

export const selectVisibleColumnsDefined = (state, tableConfigId) => {
    return state.tableConfig && state.tableConfig.config2Columns[tableConfigId] != undefined
}

export const selectVisibleColumns = (state, tableConfigId, defaultTableConfig) => {
    return state.tableConfig && state.tableConfig.config2Columns[tableConfigId] ?
        state.tableConfig.config2Columns[tableConfigId].filter(column => column.enabled).map(column => column.id) : defaultTableConfig.columns.filter(column => column.default || column.required || !defaultTableConfig.manageColumns).map(column => column.id)
}

export const selectOrderedColumns = (state, tableConfigId, defaultTableConfig) => {
    let i = 0
    return state.tableConfig && state.tableConfig.config2Columns[tableConfigId] ?
        state.tableConfig.config2Columns[tableConfigId] : defaultTableConfig.columns.map(column => {
            return {
                id: column.id,
                position: i++,
                enabled: column.default || column.required
            }
        })
}

export const selectTableConfigurationOpened = (state, tableConfigId) => {
    return state.tableConfig && state.tableConfig.opened
}

export const selectSortedColumns = (state, tableConfigId) => {
    let columns = state.tableConfig.config2Columns[tableConfigId] ? state.tableConfig.config2Columns[tableConfigId] : []


    return columns;
}


export const selectColumnsDraft = (state, tableConfigId) => {
    let columns = state.tableConfig.config2ColumnsDraft[tableConfigId] ? state.tableConfig.config2ColumnsDraft[tableConfigId] : []


    return columns;
}