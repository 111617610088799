import MembersList from "./components/MembersListComponent";
import MemberDetails from "./components/MemberDetails";

export const membersListScreen = {
    id: "membersListScreen",
    type: "custom-component",
    component: MembersList,
    route: {
        path: "/members",
        exact: false
    }
};

export const membersCreateScreen = {
    id: "membersCreateScreen",
    type: "custom-component",
    component: MemberDetails,
    route: {
        path: "/member/create",
        exact: true
    }
}

export const membersEditScreen = {
    id: "membersEditScreen",
    type: "custom-component",
    component: MemberDetails,
    route: {
        path: "/member/edit/:id",
        exact: true
    }
};