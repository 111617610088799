export const ASSET_UPLOAD = "ASSET_UPLOAD";
export const ASSET_UPLOAD_REQUESTED = 'ASSET_UPLOAD_REQUESTED';
export const ASSET_UPLOAD_DONE = 'ASSET_UPLOAD_DONE';
export const ASSET_UPLOAD_FAILED = 'ASSET_UPLOAD_FAILED';

export const ASSET_REMOVE = "ASSET_REMOVE";
export const ASSET_REMOVE_REQUESTED = 'ASSET_REMOVE_REQUESTED';
export const ASSET_REMOVE_DONE = 'ASSET_REMOVE_DONE';
export const ASSET_REMOVE_FAILED = 'ASSET_REMOVE_FAILED';

export const ASSET_REORDER = "ASSET_REORDER";
export const ASSET_REORDER_DONE = "ASSET_REORDER_DONE";
export const ASSET_REORDER_FAILED = "ASSET_REORDER_FAILED";

export const ASSET_REFRESH_PREVIEW = "ASSET_REFRESH_PREVIEW";

export const ASSET_MODAL_PREVIEW = "ASSET_MODAL_PREVIEW";
export const ASSET_MODAL_PREVIEW_SET_THUMBNAIL_SIZE = "ASSET_MODAL_PREVIEW_SET_THUMBNAIL_SIZE";


export function uploadAsset(artworkId, file, referenceType) {
    return {
        type: ASSET_UPLOAD,
        payload: {artworkId, file, referenceType}
    }
}

export function removeAsset(assetId, artworkId, referenceType) {
    return {
        type: ASSET_REMOVE,
        payload: {assetId, artworkId, referenceType}
    }
}

export function reorderAssets(sourceIndex, destinationIndex, referenceType) {
    return {
        type: ASSET_REORDER,
        payload: {sourceIndex, destinationIndex, referenceType}
    }
}

export function openModalPreview(asset, thumbnailSize) {
    return {
        type: ASSET_MODAL_PREVIEW,
        payload: {asset, thumbnailSize}
    }
}

export function setPreviewThumbnailSize(thumbnailSize) {
    return {
        type: ASSET_MODAL_PREVIEW_SET_THUMBNAIL_SIZE,
        payload: {thumbnailSize}
    }
}

export function closeModalPreview() {
    return {
        type: ASSET_MODAL_PREVIEW,
    }
}


