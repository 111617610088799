import React from 'react'
import {connect} from "react-redux";
import typeMapper from "./TypeMapper";
import {runAction} from "../actions/engine.actions";
import {getComponentState} from "../selectors";
import {isVisible} from "../constraint/checkVisibility";

const mapStateToProps = (state, ownProps) => {


    let config = state.engine.configsMap[ownProps.configId];

    return {
        config: config,
        isVisible: isVisible(config, state),
        blocked: getComponentState(state, ownProps.configId).blocked,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        runAction: (config) => dispatch(runAction(config))
    }
};

class ViewComponent extends React.Component {

    componentDidMount() {
        if (this.props.config.onInitAction) {
            this.props.runAction(this.props.config.onInitAction);
        }
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }

        let children = null;
        if (this.props.config.children) {
            children = this.props.config.children.map(childConfig => typeMapper(childConfig));
        }

        let cssClasses = [];

        if (this.props.config.cssClass) {
            cssClasses.push(this.props.config.cssClass);
        }

        if (this.props.config.grid) {
            if (this.props.config.grid.role == "container") {
                // cssClasses.push("container");
                cssClasses.push("container-fluid");
            }

            else if (this.props.config.grid.role == "row") {
                cssClasses.push("row");
            }

            else if (this.props.config.grid.role == "col") {

                let className = "col-md";
                if (this.props.config.grid.size) {
                    className += "-"+this.props.config.grid.size;
                }
                else {
                    className = "col"
                }

                cssClasses.push(className);
            }
        }

        if (this.props.config.markArea) {
            // cssClasses.push("mark-area");
        }


        if (this.props.blocked) {
            cssClasses.push("loading");
        }

        if (this.props.config.logical) {
            return (
                <React.Fragment>
                    {children && children}
                </React.Fragment>
            );
        }
        else {
            return (
                <div className={cssClasses.join(" ")}>
                    {children && children}


                </div>
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewComponent)

