// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
import {call, put, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {BACKEND_URL} from "../../config";
import {getBaseRequestConfig} from "../../api/baseRequestConfig";
import asyncFetch from "../../api/async-fetch";


let emptyDictionaryEntry = {
    labels: {},
    dictionary: {}
};

let initialState = {
    list: [],
    dictionaryEntry: null,

}

export const DICTIONARY_ENTRY_LOAD = "DICTIONARY_ENTRY_LOAD"
export const DICTIONARY_ENTRY_SET = "DICTIONARY_ENTRY_SET"
export const DICTIONARY_ENTRY_SAVE = "DICTIONARY_ENTRY_SAVE"
export const DICTIONARY_ENTRY_SET_DICTIONARY = "DICTIONARY_ENTRY_SET_DICTIONARY"
export const DICTIONARY_ENTRY_SET_LABEL = "DICTIONARY_ENTRY_SET_LABEL"
export const DICTIONARY_ENTRY_SET_DATES_FROM = "DICTIONARY_ENTRY_SET_DATES_FROM"
export const DICTIONARY_ENTRY_SET_DATES_TO = "DICTIONARY_ENTRY_SET_DATES_TO"
export const DICTIONARY_ENTRY_SET_CODE = "DICTIONARY_ENTRY_SET_CODE"
export const DICTIONARY_ENTRY_SET_FINANCED_BY = "DICTIONARY_ENTRY_SET_FINANCED_BY"


export const setDictionary = (dictionary) => ({
    type: DICTIONARY_ENTRY_SET_DICTIONARY,
    payload: dictionary
})

export const getDictionaryEntry = (id) => ({
    type: DICTIONARY_ENTRY_LOAD,
    payload: {id}
})

export const setLabel = (lang, value) => ({
    type: DICTIONARY_ENTRY_SET_LABEL,
    payload: {lang, value}
})

export const saveDictionaryEntry = () => ({
    type: DICTIONARY_ENTRY_SAVE,
})

export const setDatesFrom = (date) => ({
    type: DICTIONARY_ENTRY_SET_DATES_FROM,
    payload: date
})

export const setDatesTo = (date) => ({
    type: DICTIONARY_ENTRY_SET_DATES_TO,
    payload: date
})

export const setCode = (code) => ({
    type: DICTIONARY_ENTRY_SET_CODE,
    payload: code
})

export const setFinancedBy = (code) => ({
    type: DICTIONARY_ENTRY_SET_FINANCED_BY,
    payload: code
})


// Reducer
export default function dictionaryEntriesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case DICTIONARY_ENTRY_SET:
            return {...state, dictionaryEntry: action.payload}
        case DICTIONARY_ENTRY_SET_DICTIONARY:
            return reduceSetLanguageVersion(state, action.payload)
        case DICTIONARY_ENTRY_SET_LABEL:
            return reduceSetLabel(state, action.payload)
        case DICTIONARY_ENTRY_SET_DATES_FROM:
            return reduceSetDatesFrom(state, action.payload)
        case DICTIONARY_ENTRY_SET_DATES_TO:
            return reduceSetDatesTo(state, action.payload)
        case DICTIONARY_ENTRY_SET_CODE:
            return reduceSetCode(state, action.payload)
        case DICTIONARY_ENTRY_SET_FINANCED_BY:
            return reduceSetFinancedBy(state, action.payload)
        default:
            return state
    }
}

function reduceSetLanguageVersion(state, payload) {
    let dictionaryEntry = {...state.dictionaryEntry, dictionary: payload}

    return {...state, dictionaryEntry: dictionaryEntry}
}

function reduceSetLabel(state, payload) {
    let dictionaryEntry = {...state.dictionaryEntry}
    dictionaryEntry.labels[payload.lang] = payload.value

    return {...state, dictionaryEntry: dictionaryEntry}
}

function reduceSetDatesFrom(state, payload) {

    let data = {...state.dictionaryEntry.data}
    let date = {...data.date, from: payload}

    data = {...data, date: date}

    let dictionaryEntry = {...state.dictionaryEntry, data: data}

    return {...state, dictionaryEntry: dictionaryEntry}
}


function reduceSetDatesTo(state, payload) {

    let data = {...state.dictionaryEntry.data}
    let date = {...data.date, to: payload}

    data = {...data, date: date}

    let dictionaryEntry = {...state.dictionaryEntry, data: data}

    return {...state, dictionaryEntry: dictionaryEntry}
}

function reduceSetCode(state, payload) {
    let data = {...state.dictionaryEntry.data, code: payload}

    let dictionaryEntry = {...state.dictionaryEntry, data: data}

    return {...state, dictionaryEntry: dictionaryEntry}
}


function reduceSetFinancedBy(state, payload) {
    let data = {...state.dictionaryEntry.data, financedBy: payload}

    let dictionaryEntry = {...state.dictionaryEntry, data: data}

    return {...state, dictionaryEntry: dictionaryEntry}
}


export function* getDictionaryEntrySaga(action) {
    try {

        let {id} = action.payload

        let dictionaryEntry = null;

        if (id) {
            dictionaryEntry = yield call(getDictionaryEntryREST, action.payload.id);
        } else {
            dictionaryEntry = {...emptyDictionaryEntry}

        }

        yield put({
            type: DICTIONARY_ENTRY_SET,
            payload: dictionaryEntry
        });
    } catch (e) {
        toast.error("Wystąpił błąd podczas ładowania aktualności");
    } finally {

    }
}

export function* saveDictionaryEntrySaga(action) {
    try {

        let dictionaryEntry = yield select(state => state.dictionaryEntries.dictionaryEntry)

        let redirect = !dictionaryEntry.id

        dictionaryEntry = yield call(saveDictionaryEntryREST, dictionaryEntry);

        yield put({
            type: DICTIONARY_ENTRY_SET,
            payload: dictionaryEntry
        });

        if (redirect) {
            window.location.href = "/dictionary-entry/edit/" + dictionaryEntry.id;
        }

        toast.success("Zapisano zmiany");
    } catch (e) {
        toast.error("Wystąpił błąd podczas zapisywania rekordu");
    } finally {

    }
}

export async function saveDictionaryEntryREST(dictionaryEntry) {

    const url = `${BACKEND_URL}dictionary-entries/save`

    const baseRequestConfig = getBaseRequestConfig();

    const requestConfig = {...baseRequestConfig, method: "POST", body: JSON.stringify(dictionaryEntry)};

    const response = await asyncFetch(url, requestConfig);

    return await response.json();
}


export async function getDictionaryEntryREST(id) {

    const url = `${BACKEND_URL}dictionary-entries/` + id;

    const baseRequestConfig = getBaseRequestConfig();

    const response = await asyncFetch(url, baseRequestConfig);

    return await response.json();
}
