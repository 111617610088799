export default function getObjectAttr(path, object) {
    let crumbs = path.split(".");

    let item = {...object};
    for (let idx in crumbs) {

        if (item) {
            item = item[crumbs[idx]];
        }
        else {
            break;
        }
    }

    return item;
}